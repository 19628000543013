import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useCan,
  useTranslate,
} from '@refinedev/core'
import { List, ShowButton, useTable } from '@refinedev/antd'
import { Button, Col, Form, Row, Space, Statistic, Table, Tag } from 'antd'
import { IShift } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { ShortId } from 'components/ShortId'
import { DatesInterval } from 'components/DatesInterval'
import ScopesFilter from 'components/ScopesFilter'
import { OperationAttachments } from 'pages/operations/OperationAttachments'
import { Link } from 'react-router-dom'
import ShiftStatus from 'components/ShiftStatus'

export const ShiftsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps, searchFormProps, tableQueryResult, filters } = useTable<IShift>({
    onSearch: (params) => {
      // @ts-ignore
      const { scope } = params
      const cardsFilters: CrudFilters = []

      cardsFilters.push({
        field: 'scope',
        operator: 'eq',
        value: scope,
      })

      return cardsFilters
    },

    pagination: {
      pageSize: 20,
    },
    filters: {
      initial: [
        {
          field: 'scope',
          operator: 'eq',
          value: 'active',
        },
      ],
    },
  })

  const { data: canViewSummary } = useCan({
    resource: 'shifts_summary',
    action: 'list',
  })

  return (
    <List
      headerButtons={
        canViewSummary?.can && (
          <Link to={'/shifts/summary'}>
            <Button>{t('shifts.titles.summary')}</Button>
          </Link>
        )
      }
    >
      <Form
        {...searchFormProps}
        layout="horizontal"
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          scope: getDefaultFilter('scope', filters, 'eq'),
        }}
      >
        {
          // @ts-ignore
          tableQueryResult.data?.scopes && (
            <Form.Item name="scope">
              <ScopesFilter
                // @ts-ignore
                scopes={tableQueryResult.data?.scopes}
                resource={'shifts'}
              />
            </Form.Item>
          )
        }
      </Form>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Col span={6}>
          <Statistic
            title="Сумма заказов"
            loading={tableQueryResult.isFetching}
            value={formattedCurrencyAmount(
              tableQueryResult.data?.stats?.operations_amount
            )}
            precision={2}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Количество заказов"
            loading={tableQueryResult.isFetching}
            value={tableQueryResult.data?.stats?.operations_count}
            precision={0}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Сумма выводов"
            loading={tableQueryResult.isFetching}
            value={formattedCurrencyAmount(tableQueryResult.data?.stats?.payouts_amount)}
            precision={2}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Количество выводов"
            loading={tableQueryResult.isFetching}
            value={tableQueryResult.data?.stats?.payouts_count}
            precision={0}
          />
        </Col>
      </Row>
      <Table
        {...tableProps}
        size="small"
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          pageSizeOptions: [],
          position: ['bottomLeft'],
          size: 'small',
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          ellipsis
          title={t('shifts.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column
          dataIndex="number"
          key="number"
          align="right"
          title={t('shifts.fields.number')}
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t('shifts.fields.status')}
          render={(value) => <ShiftStatus status={value} />}
        />
        <Table.Column
          dataIndex="payins_status"
          key="payins_status"
          title={t('shifts.fields.payins')}
          render={(value) => <ShiftStatus status={value} />}
        />
        <Table.Column
          dataIndex="payouts_status"
          key="payouts_status"
          title={t('shifts.fields.payouts')}
          render={(value) => <ShiftStatus status={value} />}
        />
        <Table.Column<IShift>
          dataIndex="interval"
          key="interval"
          title={t('shifts.fields.period')}
          render={(_, shift) => (
            <DatesInterval start={shift.started_at} end={shift.finished_at} />
          )}
        />
        <Table.Column<IShift>
          dataIndex={['partner', 'name']}
          key="partner_name"
          title={t('shifts.fields.partner')}
        />
        <Table.Column<IShift>
          align="right"
          dataIndex="approved_operations_amount"
          key="approved_operations_amount"
          title={t('shifts.fields.approved_operations_amount')}
          render={(value, record) =>
            `${formattedCurrencyAmount(value, 2, record.partner.currency)} (${
              record.approved_operations_count
            })`
          }
        />
        <Table.Column<IShift>
          align="right"
          dataIndex="approved_payouts_amount"
          key="approved_payouts_amount"
          title={t('shifts.fields.approved_payouts_amount')}
          render={(value, record) =>
            `${formattedCurrencyAmount(value, 2, record.partner.currency)} (${
              record.approved_payouts_count
            })`
          }
        />
        <Table.Column<IShift>
          align="right"
          dataIndex="problems_count"
          key="problems_count"
          title={t('shifts.fields.problems_count')}
          render={(value) => <Tag color={value === 0 ? 'green' : 'red'}>{value}</Tag>}
        />
        <Table.Column<IShift>
          align="right"
          dataIndex="waiting_for_receipt_count"
          key="waiting_for_receipt_count"
          title={t('shifts.fields.waiting_for_receipt_count')}
          render={(value) => <Tag color={value === 0 ? 'green' : 'yellow'}>{value}</Tag>}
        />
        <Table.Column<IShift>
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton resource="shifts" recordItemId={record.id} size="small" />
              {record.attachment_ids && (
                <OperationAttachments
                  attachments_ids={record.attachment_ids}
                  title={t('shifts.buttons.download')}
                />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
