import { AuthPage, ThemedTitleV2 } from '@refinedev/antd'
import {
  LoginFormTypes,
  useIsAuthenticated,
  useLogin,
  useLogout,
  useTranslate,
} from '@refinedev/core'
import { Typography, Card, theme, Form, Button, Input } from 'antd'
import { AppIcon } from 'components/app-icon'

const { useToken } = theme

export const Login = () => {
  const { token } = useToken()
  const { data } = useIsAuthenticated()
  const t = useTranslate()
  const [form] = Form.useForm()

  const { mutate: logout } = useLogout()

  const { mutate: login } = useLogin<LoginFormTypes>()

  if (data?.redirectTo === '/two_factor') {
    const CardTitle = (
      <Typography.Title
        level={3}
        style={{
          textAlign: 'center',
          marginBottom: 0,
          fontSize: '24px',
          lineHeight: '32px',
          fontWeight: 700,
          overflowWrap: 'break-word',
          hyphens: 'manual',
          textOverflow: 'unset',
          whiteSpace: 'pre-wrap',
          color: token.colorPrimaryTextHover,
        }}
      >
        {t('pages.login.two_factor')}
      </Typography.Title>
    )

    return (
      <AuthPage
        renderContent={(_, title) => (
          <>
            {title}
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0, padding: 0 }}
              bodyStyle={{ padding: 0, marginTop: '32px' }}
              style={{
                maxWidth: '400px',
                margin: 'auto',
                padding: '32px',
                boxShadow:
                  '0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03)',
                backgroundColor: token.colorBgElevated,
              }}
            >
              <Form layout="vertical" form={form} onFinish={(values) => login(values)}>
                <Form.Item
                  name="code"
                  label={t('pages.login.fields.code.label')}
                  rules={[{ required: true }, { len: 6 }]}
                >
                  <Input
                    size="large"
                    placeholder={t('pages.login.fields.code.placeholder')}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={false}
                    block
                  >
                    {t('pages.login.confirm')}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    size="large"
                    loading={false}
                    block
                    onClick={() => logout()}
                  >
                    {t('pages.login.logout')}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </>
        )}
        title={
          <ThemedTitleV2 collapsed={false} text="Spacepayments" icon={<AppIcon />} />
        }
      />
    )
  } else {
    return (
      <AuthPage
        type="login"
        rememberMe={false}
        forgotPasswordLink={false}
        registerLink={false}
        title={
          <ThemedTitleV2 collapsed={false} text="Spacepayments" icon={<AppIcon />} />
        }
      />
    )
  }
}
