import React from 'react'
import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'
import { List, useTable } from '@refinedev/antd'
import { IDispute } from 'interfaces'
import DisputesTable from './list_table'
import { Form, Input } from 'antd'
import ScopesFilter from 'components/ScopesFilter'

export const DisputesList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps, searchFormProps, tableQueryResult, filters } = useTable<IDispute>({
    onSearch: (params) => {
      // @ts-ignore
      const { scope, operation_id } = params
      const disputesFilters: CrudFilters = []

      disputesFilters.push({
        field: 'scope',
        operator: 'eq',
        value: scope,
      })

      disputesFilters.push({
        field: 'operation_id',
        operator: 'eq',
        value: operation_id,
      })

      return disputesFilters
    },

    pagination: {
      pageSize: 20,
    },
    filters: {
      initial: [
        {
          field: 'scope',
          operator: 'eq',
          value: 'created',
        },
      ],
    },
  })

  return (
    <List>
      <Form
        {...searchFormProps}
        layout="horizontal"
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          scope: getDefaultFilter('scope', filters, 'eq'),
          operation_id: getDefaultFilter('operation_id', filters, 'eq'),
        }}
      >
        <Form.Item name="operation_id">
          <Input placeholder={t('disputes.filter.operation_id.placeholder')} allowClear />
        </Form.Item>
        {
          // @ts-ignore
          tableQueryResult.data?.scopes && (
            <Form.Item name="scope">
              <ScopesFilter
                // @ts-ignore
                scopes={tableQueryResult.data?.scopes}
                resource={'disputes'}
              />
            </Form.Item>
          )
        }
      </Form>
      <DisputesTable tableProps={tableProps} />
    </List>
  )
}
