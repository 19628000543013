import { useTranslate, useCan } from '@refinedev/core'
import { useModalForm } from '@refinedev/antd'
import { Button, Modal } from 'antd'
import { IAgreement } from 'interfaces'
import { AgreementForm } from 'pages/agreements/form'
import { PlusSquareOutlined } from '@ant-design/icons'

const AddAgreementButton: React.FC<{
  initialValues: Record<string, any>
  style: React.CSSProperties
}> = ({
  initialValues,
  style
}) => {
  const t = useTranslate()

  const { data: canCreateAgreement } = useCan({
    resource: 'billing/agreements',
    action: 'create',
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
    form,
  } = useModalForm<IAgreement>({
    action: 'create',
    resource: 'billing/agreements',
    redirect: false,
  })

  return (
    <>
      {canCreateAgreement?.can &&
        <Button
          style={style}
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => createModalShow()}
        >
          {t('billing/agreements.buttons.create')}
        </Button>
      }      
      <Modal {...createModalProps}>
        <AgreementForm
          formProps={createFormProps}
          form={form}
          initialValues={initialValues}
        />
      </Modal>
    </>
  )
}

export default AddAgreementButton
