import {
  CrudFilters,
  IResourceComponentsProps,
  getDefaultFilter,
  useTranslate,
} from '@refinedev/core'
import { List, useSelect, useTable } from '@refinedev/antd'
import { Checkbox, Form, Input, Select, Table } from 'antd'
import { IOperationVerification, IPaymentRequisite, ISms } from 'interfaces'
import DateField from 'components/DateField'
import { formattedCardNumber, formattedPhone } from 'utils'
import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'

export const SmsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps, searchFormProps, filters } = useTable<ISms>({
    onSearch: (params) => {
      // @ts-ignore
      const { search, phones, missing_feed } = params
      const cardsFilters: CrudFilters = []

      cardsFilters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })

      cardsFilters.push({
        field: 'phones',
        operator: 'in',
        value: phones?.map((phone: string) => phone.replace(/^\s/, '+')),
      })

      cardsFilters.push({
        field: 'missing_feed',
        operator: 'eq',
        value: missing_feed,
      })

      return cardsFilters
    },

    pagination: {
      pageSize: 20,
    },
  })

  const { queryResult: paymentRequisitesQueryResult } = useSelect<IPaymentRequisite>({
    resource: 'payment_requisites',
    optionLabel: 'card_number',
    optionValue: 'phone',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  let options: any = {}

  paymentRequisitesQueryResult.data?.data.forEach((item) => {
    options[item.phone] ||= []
    if (!options[item.phone].includes(item.card_number)) {
      options[item.phone].push(item.card_number)
    }
  })

  const paymentRequisiteOptions = Object.keys(options).map((phone) => {
    return {
      label: `${options[phone]
        .map((card_number: any) => formattedCardNumber(card_number))
        .join('; ')} (${formattedPhone(phone)})`,
      value: phone,
    }
  })

  return (
    <List>
      <Form
        {...searchFormProps}
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          search: getDefaultFilter('search', filters),
          phones: getDefaultFilter('phones', filters, 'in')?.map((phone: string) =>
            phone.replace(/^\s/, '+')
          ),
        }}
      >
        <Form.Item name="search">
          <Input placeholder={t('sms.filter.placeholders.search')} allowClear />
        </Form.Item>
        <Form.Item name="phones">
          <Select
            style={{ minWidth: 200 }}
            allowClear
            showSearch
            placeholder={t('sms.filter.placeholders.phones')}
            mode="multiple"
            options={paymentRequisiteOptions}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="missing_feed"
          label={t('sms.filter.missing_feed.label')}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        rowKey="id"
        scroll={{ x: '100%' }}
        size="small"
        pagination={{
          ...tableProps.pagination,
          pageSize: 20,
          position: ['bottomLeft'],
          size: 'small',
        }}
      >
        <Table.Column
          dataIndex="body"
          fixed="left"
          key="body"
          title={t('sms.fields.body')}
          width={300}
        />
        <Table.Column
          width={100}
          fixed="left"
          align="right"
          ellipsis
          dataIndex="amount"
          key="amount"
          title={t('sms.fields.amount')}
        />
        <Table.Column
          width={100}
          fixed="left"
          align="right"
          ellipsis
          dataIndex="balance"
          key="balance"
          title={t('sms.fields.balance')}
        />
        <Table.Column
          width={100}
          align="right"
          ellipsis
          dataIndex="operation_type"
          key="operation_type"
          title={t('sms.fields.operation_type')}
        />
        <Table.Column
          width={100}
          ellipsis
          dataIndex="from"
          key="from"
          title={t('sms.fields.from')}
        />
        <Table.Column
          ellipsis
          width={130}
          dataIndex="phone"
          key="phone"
          title={t('sms.fields.phone')}
          render={(value) => formattedPhone(value)}
        />
        <Table.Column<ISms>
          ellipsis
          width={200}
          dataIndex="created_at"
          key="created_at"
          title={t('sms.fields.created_at')}
          render={(value) => <DateField value={value} />}
        />
        <Table.Column<ISms>
          ellipsis
          width={200}
          dataIndex="sent_at"
          key="sent_at"
          title={t('sms.fields.sent_at')}
          render={(value) => value && <DateField value={value} />}
        />
        <Table.Column
          width={50}
          dataIndex="attempt_count"
          key="attempt_count"
          title={t('sms.fields.attempt_count')}
        />
        <Table.Column
          width={50}
          dataIndex="app_version"
          key="app_version"
          title={t('sms.fields.app_version')}
        />
        <Table.Column<ISms>
          width={120}
          ellipsis
          dataIndex={['feed_item', 'kind']}
          key="feed_item.kind"
          title={t('sms.fields.feed_item.kind')}
        />
        <Table.Column<ISms>
          width={120}
          ellipsis
          dataIndex={['feed_item', 'status']}
          key="feed_item.status"
          title={t('sms.fields.feed_item.status')}
        />
        <Table.Column<ISms>
          width={120}
          ellipsis
          dataIndex={['feed_item', 'balance_chained']}
          key="feed_item.balance_chained"
          title={t('sms.fields.feed_item.balance_chained')}
          render={(value) => <BooleanTag value={value} />}
        />
        <Table.Column
          dataIndex="id"
          key="id"
          width={120}
          ellipsis
          title={t('sms.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column<ISms>
          width={120}
          ellipsis
          dataIndex="operation_verification"
          key="operation_verification"
          title={t('sms.fields.operation_verification')}
          render={(value: IOperationVerification | undefined) =>
            value && <ShortId value={value.operation_id} />
          }
        />
      </Table>
    </List>
  )
}
