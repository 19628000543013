import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
  GetManyResponse,
  useCan,
  CrudFilters,
  getDefaultFilter,
} from '@refinedev/core'

import { EditButton, List, ShowButton, useTable } from '@refinedev/antd'

import { Table, Space, Typography, Skeleton, Form, Input } from 'antd'
import { IMerchant, IPartner, IDealing, IAgreement, IPartnerMerchantsData } from 'interfaces'

import AddPartnerMerchantButton from 'components/AddPartnerMerchantButton'
import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'
import {
  formattedCorrectedPercent,
  formattedCurrencyAmount,
  formattedPercent,
} from 'utils'
import { SearchOutlined } from '@ant-design/icons'
import { GatewayType } from 'components/GatewayType'

const { Text } = Typography

const Merchant: React.FC<{
  id: string
  merchantsData: GetManyResponse<IMerchant> | undefined
  style: React.CSSProperties
}> = ({ id, merchantsData, style }) => {
  const merchant = merchantsData && merchantsData.data.find((item) => id === item.id)

  return (
    <Text code ellipsis style={style}>
      {merchant?.name}
    </Text>
  )
}

const Dealings: React.FC<{
  ids: string[]
  dealingsData: GetManyResponse<IDealing> | undefined
}> = ({ ids, dealingsData }) => {
  const merchants =
    dealingsData &&
    dealingsData.data
      .filter((item) => ids.includes(item.id))
      .map((item) => (
        <Text code key={item.id} style={{ display: 'block' }} ellipsis>
          {item.user.email}({item.role.code})
        </Text>
      ))

  return <>{merchants}</>
}

export const PartnersList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, filters } = useTable<IPartner>({
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { search } = params

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })

      return filters
    },
    pagination: {
      pageSize: 20,
    },
  })

  const { data: canListDealings } = useCan({
    resource: 'dealings',
    action: 'list',
  })

  const { data: canListMerchants } = useCan({
    resource: 'merchants',
    action: 'list',
  })

  const { data: canCreatePartner } = useCan({
    resource: 'partners',
    action: 'create',
  })

  const { data: canAddMerchant } = useCan({
    resource: 'partners',
    action: 'add_merchant',
  })

  const merchantsIds =
    tableProps?.dataSource?.flatMap((item: IPartner) =>
      item.income_merchant_ids?.concat(item.payout_merchant_ids)
    ) ?? []

  const { data: merchantsData, isLoading: merchantsLoading } = useMany<IMerchant>({
    resource: 'merchants',
    ids: merchantsIds,
    meta: {
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      enabled: !!(canListMerchants?.can && merchantsIds.length > 0),
    },
  })

  const dealingsIds =
    tableProps?.dataSource?.flatMap((item: IPartner) => item.dealings_ids) ?? []

  const { data: dealingsData, isLoading: dealingsLoading } = useMany<IDealing>({
    resource: 'dealings',
    ids: dealingsIds,
    queryOptions: {
      enabled: !!(canListDealings?.can && dealingsIds.length > 0),
    },
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List canCreate={canCreatePartner?.can}>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          search: getDefaultFilter('search', filters),
        }}
      >
        <Form.Item name="search">
          <Input
            placeholder={t('partners.filter.search.placeholder')}
            prefix={<SearchOutlined />}
            allowClear
          />
        </Form.Item>
      </Form>
      <Table
        scroll={{ x: '100%' }}
        {...tableProps}
        size="small"       
        rowKey="id"
        pagination={
          tableProps.pagination && (tableProps.pagination?.total ?? 0) > 20
            ? {
                ...tableProps.pagination,
                pageSize: 20,
                position: ['bottomLeft'],
                size: 'small',
              }
            : false
        }
      >
        <Table.Column<IPartner>         
          dataIndex="name"
          key="name"
          title={t('partners.fields.name')} 
        />
        {canListMerchants?.can && (
          <Table.Column<IPartner>
            dataIndex="income_merchants"
            key="income_merchants"
            title={t('partners.fields.merchants')}
            render={(value: IPartnerMerchantsData[], record: IPartner) => {
              if (merchantsIds.length && merchantsLoading) {
                return <Skeleton.Input active />
              }

              return (
                <Space wrap>
                  {value.map((merchant_data) => (
                    <Merchant
                      id={merchant_data.merchant_id}
                      merchantsData={merchantsData}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '.2em 0',
                        border: merchant_data.negative_profit ? '.1em solid red' : ''
                      }}
                    />                  
                  ))}
                  {canAddMerchant?.can && (
                    <AddPartnerMerchantButton id={record.id} kind="income" />
                  )}
                </Space>
              )
            }}
          />
        )}
        {canListMerchants?.can && (
          <Table.Column<IPartner>
            dataIndex="payout_merchants"
            key="payout_merchants"
            title={t('partners.fields.payout_merchants')}
            render={(value: IPartnerMerchantsData[], record: IPartner) => {
              if (merchantsIds.length && merchantsLoading) {
                return <Skeleton.Input active />
              }

              return (
                <Space wrap>
                  {value.map((merchant_data) => (
                    <Merchant
                      id={merchant_data.merchant_id}
                      merchantsData={merchantsData}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '.2em 0',
                        border: merchant_data.negative_profit ? '.1em solid red' : ''
                      }}
                    />
                  ))}
                  {canAddMerchant?.can && (
                    <AddPartnerMerchantButton id={record.id} kind="payout" />
                  )}
                </Space>
              )
            }}
          />
        )}
        <Table.Column<IPartner>
          ellipsis
          dataIndex="currency"
          key="currency"
          title={t('partners.fields.currency')}
        />
        <Table.Column<IPartner>
          dataIndex="max_approved_sum"
          key="max_approved_sum"
          title={t('partners.fields.max_approved_sum')}
          render={(value) => (value ? formattedCurrencyAmount(value) : 'Нет лимита')}
        />
        <Table.Column<IPartner>
          dataIndex="cash_limit_per_requisite"
          key="cash_limit_per_requisite"
          title={t('partners.fields.cash_limit_per_requisite')}
          render={(value) => (value ? formattedCurrencyAmount(value) : 'Нет лимита')}
        />
        <Table.Column<IPartner>
          dataIndex="deposit_balance"
          key="deposit_balance"
          title={t('partners.fields.deposit_balance')}
          render={(value) => (value ? formattedCurrencyAmount(value) : 'Нет депозита')}
        />
        <Table.Column<IPartner>
          dataIndex="agreements_income"
          key="agreements_income"
          title={t('partners.fields.agreements_income')}         
          render={(value: IAgreement[]) => { 
            return (
              <Space wrap>
                {value?.length
                  ? (value.map((item) => (
                    <Text
                      code
                      ellipsis
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Space>
                        {item?.gateway ? (
                          <Space>
                            <GatewayType type={item.gateway.payment_type} />
                            {item.gateway.currency}
                            {item.gateway.name}
                          </Space>
                        ) : <Text>Гейтвей не указан</Text>}
                        {formattedCorrectedPercent(item.percent)}
                      </Space>
                  </Text>
                  )))
                  : <Text>Нет соглашений</Text>}            
              </Space>
            )
          }} 
        />
        <Table.Column<IPartner>
          dataIndex="agreements_payout"
          key="agreements_payout"
          title={t('partners.fields.agreements_payout')}         
          render={(value: IAgreement[]) => { 
            return (
              <Space wrap>
                {value?.length
                  ? (value.map((item) => (
                    <Text
                      code
                      ellipsis
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Space>
                        {item?.gateway ? (
                          <Space>
                            <GatewayType type={item.gateway.payment_type} />
                            {item.gateway.currency}
                            {item.gateway.name}
                          </Space>
                        ) : <Text>Гейтвей не указан</Text>}
                        {formattedCorrectedPercent(item.percent)}
                      </Space>
                  </Text>
                  )))
                  : <Text>Нет соглашений</Text>}            
              </Space>
            )
          }}          
        />
        <Table.Column<IPartner>
          dataIndex="payouts_enabled"
          key="payouts_enabled"
          title={t('partners.fields.payouts_enabled')}
          render={(value) => <BooleanTag value={value} />}
        />
        <Table.Column<IPartner>
          ellipsis
          dataIndex="flexpay"
          key="flexpay"
          title={t('partners.fields.flexpay')}
          render={(value) => <BooleanTag value={value} />}
        />
        {canListDealings?.can && (
          <Table.Column<IPartner>
            dataIndex="dealings_ids"
            key="dealings_ids"
            title={t('partners.fields.dealings')}
            render={(value: string[]) => {
              if (dealingsIds.length && dealingsLoading) {
                return <Skeleton.Input active />
              }

              return (
                <Space wrap>
                  <Dealings ids={value} dealingsData={dealingsData} />
                </Space>
              )
            }}
          />
        )}
        <Table.Column<IPartner>
          ellipsis
          dataIndex="id"
          key="id"
          title={t('partners.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column<IPartner>
          title="Actions"
          fixed="right"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space wrap>                
                <ShowButton size="small" recordItemId={record.id} />
                <EditButton size="small" recordItemId={record.id} />
              </Space>
            )
          }}          
        />        
      </Table>
    </List>
  )
}
