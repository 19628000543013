import React from 'react'
import { Show, useTable } from "@refinedev/antd";

import { IReport } from 'interfaces'
import { useTranslate } from "@refinedev/core";
import ReportsTable from 'pages/reports/list_table'

export const ReportsList: React.FC<any> = ({ merchantId }) => {
  const t = useTranslate()
  const { tableProps } = useTable<IReport>({
    resource: 'reports',

    filters: {
      permanent: [
        {
          field: 'merchant_id',
          operator: 'eq',
          value: merchantId,
        },
      ]
    }
  })

  return (
    <Show
      title={`${t('merchants.titles.reports')} (${
        tableProps.pagination && tableProps.pagination.total
      })`}
      headerButtons={<></>}
      canEdit={false}
      goBack={false}
      breadcrumb={false}
      wrapperProps={{ style: { marginTop: 20 } }}
    >
      <ReportsTable tableProps={tableProps} />
    </Show>
  )
}
