import { AxiosInstance } from 'axios'
import { stringify } from 'query-string'
import dataProvider from '@refinedev/simple-rest'
import { DataProvider } from '@refinedev/core'

export const spaceDataProvider = (
  apiSubdomain: string | undefined,
  httpClient: AxiosInstance
): Omit<Required<DataProvider>, 'createMany' | 'updateMany' | 'deleteMany'> => {
  let apiUrl = 'http://localhost:3001'
  if (apiSubdomain !== undefined) {
    apiUrl = apiSubdomain
  }

  return {
    ...dataProvider(apiUrl, httpClient),
    getMany: async ({ resource, ids, meta }) => {
      const url = `${apiUrl}/${resource}/filter`

      let requestData = {
        ids,
        pagination: {
          limit: 100000,
          page: 1,
        },
      }

      meta?.filters?.forEach((filter: any) => {
        // @ts-ignore
        requestData[filter.field] = filter.value
      })

      const { data } = await httpClient.post(url, requestData)

      return {
        data: data.collection,
      }
    },
    getList: async ({ resource, pagination, filters, ...props }) => {
      const url = `${apiUrl}/${resource}/filter`

      const { current, pageSize } = pagination ?? {}

      if (!current) {
        return {
          data: [],
          total: 0,
        }
      }

      let requestData = {
        pagination: {
          limit: pageSize,
          page: current,
        },
      }

      filters?.forEach((filter) => {
        // @ts-ignore
        requestData[filter.field] = filter.value
      })

      const { data } = await httpClient.post(url, requestData)

      return {
        data: data.collection,
        total: data.pagination.items,
        scopes: data.scopes,
        stats: data.stats,
      }
    },
    custom: async ({ url, method, payload, headers, query, metaData }) => {
      let requestUrl = `${url}`

      if (headers) {
        httpClient.defaults.headers = {
          ...httpClient.defaults.headers,
          ...headers,
        }
      }

      if (query) {
        requestUrl = `${requestUrl}?${stringify(query)}`
      }

      let axiosResponse
      switch (method) {
        case 'put':
          axiosResponse = await httpClient.put(url, payload)
          break
        case 'post':
          axiosResponse = await httpClient.post(url, payload)
          break
        case 'patch':
          axiosResponse = await httpClient.patch(url, payload)
          break
        case 'delete':
          axiosResponse = await httpClient.delete(url, {
            data: payload,
          })
          break
        default:
          axiosResponse = await httpClient.get(requestUrl)
          break
      }
      const { data, headers: responseHeaders } = axiosResponse

      return Promise.resolve({ data, headers: responseHeaders })
    },
  }
}
