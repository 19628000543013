import React from 'react'
import { disputeStatus } from 'interfaces'
import { Tag } from 'antd'
import { useTranslate } from '@refinedev/core'

export const DisputeStatusMap = {
  created: 'disputes.statuses.created',
  approved: 'disputes.statuses.approved',
  rejected: 'disputes.statuses.rejected',
}

export const DisputeStatus: React.FC<{ status: disputeStatus }> = ({ status }) => {
  const t = useTranslate()

  const DisputesStatusColorMap = {
    created: 'blue',
    approved: 'green',
    rejected: 'red',
  }

  return (
    <Tag color={DisputesStatusColorMap[status]} style={{ fontSize: 10 }}>
      {t(DisputeStatusMap[status])}
    </Tag>
  )
}
