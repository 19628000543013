import React, { memo } from 'react'
import dayjs from 'dayjs'
import {
  Card,
  Col,
  Form,
  Row,
  Statistic,
  DatePicker,
  Spin,
  Empty,
  Select,
  FormProps,
  FormInstance,
} from 'antd'
import {
  CustomResponse,
  useApiUrl,
  useCan,
  useCustom,
  useList,
  useTranslate,
} from '@refinedev/core'
import { formattedCurrencyAmount } from 'utils'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { IChart, IDashboard, IMerchant, IPartner, ISummaryData } from 'interfaces'
import { Column, Pie } from '@ant-design/plots'
import { useForm, useSelect } from '@refinedev/antd'
import { useSearchParams } from 'react-router-dom'

import './styles.css'

const { RangePicker } = DatePicker

const Chart: React.FC<{ isLoading: boolean; data: any[] }> = ({ isLoading, data }) => {
  if (isLoading) {
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <Spin tip="Loading" size="large" />
      </div>
    )
  }

  if (!data.length) {
    return <Empty />
  }

  return (
    <Column
      tooltip={{
        formatter: (datum) => {
          return { value: formattedCurrencyAmount(datum.volume), name: 'Объем заказов' }
        },
      }}
      label={false}
      data={data}
      xField="timestamp"
      yField="volume"
      meta={{
        volume: {
          alias: 'Объем заказов',
        },
      }}
    />
  )
}

const StatsForm: React.FC<{
  form: FormInstance
  formProps: FormProps
  merchant_id: string
  partner_id: string
  period: any[]
}> = memo(({ form, formProps, merchant_id, partner_id, period }) => {
  const t = useTranslate()
  // const [searchParams, setSearchParams] = useSearchParams()

  // useEffect(() => {
  //   setSearchParams((prevValue) => {
  //     prevValue.delete('merchant_id')
  //     prevValue.delete('partner_id')
  //     prevValue.delete('start')
  //     prevValue.delete('end')
  //     const newParams: { [key: string]: string } = {}

  //     merchant_id && (newParams['merchant_id'] = merchant_id)
  //     partner_id && (newParams['partner_id'] = partner_id)
  //     period && period[0] && (newParams['start'] = period[0].format('YYYY-MM-DD'))
  //     period && period[1] && (newParams['end'] = period[1].format('YYYY-MM-DD'))

  //     return newParams
  //   })
  // }, [setSearchParams, merchant_id, partner_id, period])

  const { data: canViewPartners } = useCan({
    resource: 'partners',
    action: 'filter',
  })

  const { data: canViewMerchants } = useCan({
    resource: 'merchants',
    action: 'filter',
  })

  const { data: partnersData } = useList<IPartner>({
    resource: 'partners',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
    
    queryOptions: {
      enabled: !!canViewPartners?.can,
    },
  })

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
    queryOptions: {
      enabled: !!canViewMerchants?.can,
    },
  })

  return (
    <Form
      {...formProps}
      layout="inline"
      // @ts-ignore
      initialValues={{
        period: [
          dayjs(),
          dayjs(),
          // searchParams.get('start') ? dayjs(searchParams.get('start')) : dayjs(),
          // searchParams.get('end') ? dayjs(searchParams.get('end')) : dayjs(),
        ],
        // merchant_id: searchParams.get('merchant_id'),
        // partner_id: searchParams.get('partner_id'),
      }}
    >
      <Form.Item style={{ marginBottom: 0 }} name="period">
        <RangePicker
          presets={[
            {
              label: t('dashboard.form.period.presets.today.label'),
              value: [dayjs(), dayjs()],
            },
            {
              label: t('dashboard.form.period.presets.week.label'),
              value: [dayjs().add(-1, 'week'), dayjs()],
            },
            {
              label: t('dashboard.form.period.presets.month.label'),
              value: [dayjs().add(-1, 'month'), dayjs()],
            },
          ]}
          allowEmpty={[false, false]}
          locale={locale}
        />
      </Form.Item>
      {canViewMerchants?.can && (
        <Form.Item style={{ marginBottom: 0 }} name="merchant_id">
          <Select
            style={{ width: 200 }}
            allowClear
            showSearch
            placeholder={t('dashboard.form.merchant.placeholder')}
            {...merchantsSelectProps}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      )}
      {canViewPartners?.can && partnersData && (
        <Form.Item style={{ marginBottom: 0 }} name="partner_id">
          <Select
            style={{ width: 200 }}
            options={partnersData.data.map((partner) => ({
              label: partner.name,
              value: partner.id,
            }))}
            allowClear
            showSearch
            placeholder={t('dashboard.form.partner.placeholder')}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      )}
    </Form>
  )
})

const StatsChart: React.FC<{
  data: CustomResponse<IChart> | undefined
  isLoading: boolean
}> = memo(({ data, isLoading }) => {
  const chartData =
    !isLoading && data?.data
      ? data.data.data.map(({ timestamp, count, volume }) => ({
          timestamp,
          count,
          volume: parseFloat(volume),
        }))
      : []

  return (
    <Card style={{ marginTop: 32 }}>
      <Chart data={chartData} isLoading={isLoading} />
    </Card>
  )
})

const Summary: React.FC<{
  data: CustomResponse<ISummaryData> | undefined
  isLoading: boolean
  label: string
  color: string | undefined
}> = memo(({ data, isLoading, label, color }) => {
  return (
    <Row justify="space-between" style={{ marginTop: 32 }}>
      <Col span={7}>
        <Card>
          <Statistic
            className="stats"
            title={`Сумма ${label}`}
            loading={isLoading}
            valueStyle={color !== undefined ? { color } : {}}
            value={data?.data && formattedCurrencyAmount(data.data.volume)}
            precision={2}
          />
        </Card>
      </Col>
      <Col span={7}>
        <Card>
          <Statistic
            className="stats"
            title={`Количество ${label}`}
            loading={isLoading}
            valueStyle={color !== undefined ? { color } : {}}
            value={data?.data ? data.data.count : 0}
            precision={0}
          />
        </Card>
      </Col>
      <Col span={7}>
        <Card>
          <Statistic
            title={`Конверсия ${label}`}
            className="stats"
            loading={isLoading}
            valueStyle={color !== undefined ? { color } : {}}
            value={data?.data ? data.data.conversion * 100 : 0}
            precision={2}
            suffix="%"
          />
        </Card>
      </Col>
    </Row>
  )
})

export const DashboardPage: React.FC = () => {
  const apiUrl = useApiUrl()

  const { data: canViewDashboardColors } = useCan({
    resource: 'dashboard_colors',
    action: 'view',
  })

  const { form, formProps } = useForm({ warnWhenUnsavedChanges: false })
  const period = Form.useWatch('period', form)
  const merchant_id = Form.useWatch('merchant_id', form)
  const partner_id = Form.useWatch('partner_id', form)

  const { data, isFetching: isLoading } = useCustom<IChart>({
    url: `${apiUrl}/dashboard/chart`,
    method: 'get',
    queryOptions: {
      enabled: !!(period && period[0] && period[1]),
    },
    config: {
      query: {
        start: period && period[0]?.format('YYYY-MM-DD'),
        end: period && period[1]?.format('YYYY-MM-DD'),
        merchant_id,
        partner_id,
      },
    },
  })

  const { data: summaryIncomeData, isFetching: isSummaryIncomeLoading } =
    useCustom<ISummaryData>({
      url: `${apiUrl}/dashboard/summary`,
      method: 'get',
      queryOptions: {
        enabled: !!(period && period[0] && period[1]),
      },
      config: {
        query: {
          start: period && period[0]?.format('YYYY-MM-DD'),
          end: period && period[1]?.format('YYYY-MM-DD'),
          merchant_id,
          partner_id,
          kind: 'income',
          client_color: '',
        },
      },
    })

  const { data: summaryGreenIncomeData, isFetching: isSummaryGreenIncomeLoading } =
    useCustom<ISummaryData>({
      url: `${apiUrl}/dashboard/summary`,
      method: 'get',
      queryOptions: {
        enabled: !!(period && period[0] && period[1]) && canViewDashboardColors?.can,
      },
      config: {
        query: {
          start: period && period[0]?.format('YYYY-MM-DD'),
          end: period && period[1]?.format('YYYY-MM-DD'),
          merchant_id,
          partner_id,
          kind: 'income',
          client_color: 'green',
        },
      },
    })

  const { data: summaryRedIncomeData, isFetching: isSummaryRedIncomeLoading } =
    useCustom<ISummaryData>({
      url: `${apiUrl}/dashboard/summary`,
      method: 'get',
      queryOptions: {
        enabled: !!(period && period[0] && period[1]) && canViewDashboardColors?.can,
      },
      config: {
        query: {
          start: period && period[0]?.format('YYYY-MM-DD'),
          end: period && period[1]?.format('YYYY-MM-DD'),
          merchant_id,
          partner_id,
          kind: 'income',
          client_color: 'red',
        },
      },
    })

  const { data: summaryPayoutData, isFetching: isSummaryPayoutLoading } =
    useCustom<ISummaryData>({
      url: `${apiUrl}/dashboard/summary`,
      method: 'get',
      queryOptions: {
        enabled: !!(period && period[0] && period[1]),
      },
      config: {
        query: {
          start: period && period[0]?.format('YYYY-MM-DD'),
          end: period && period[1]?.format('YYYY-MM-DD'),
          merchant_id,
          partner_id,
          kind: 'payout',
          client_color: '',
        },
      },
    })

  return (
    <>
      <Card>
        <StatsForm
          form={form}
          formProps={formProps}
          period={period}
          merchant_id={merchant_id}
          partner_id={partner_id}
        />
      </Card>
      <Summary
        data={summaryIncomeData}
        isLoading={isSummaryIncomeLoading}
        label={''}
        color={undefined}
      />
      {canViewDashboardColors?.can && (
        <Summary
          data={summaryGreenIncomeData}
          isLoading={isSummaryGreenIncomeLoading}
          label="Зеленые"
          color="#3f8600"
        />
      )}
      {canViewDashboardColors?.can && (
        <Summary
          data={summaryRedIncomeData}
          isLoading={isSummaryRedIncomeLoading}
          label="Красные"
          color="#cf1322"
        />
      )}
      <Summary
        data={summaryPayoutData}
        isLoading={isSummaryPayoutLoading}
        label="пейауты"
        color={undefined}
      />
      <StatsChart data={data} isLoading={isLoading} />
    </>
  )
}
