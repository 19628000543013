import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Button,
  Modal,
  Typography,
  Alert,
  Card,
  Empty,
  Form,
  InputNumber,
  Select,
  Tag,
} from 'antd'
import { useTranslate } from '@refinedev/core'
import { IAccessToken } from 'interfaces'
import { useModalForm, useTable } from '@refinedev/antd'
import { ApiTokensTable } from 'pages/access_tokens/api_tokens_table'

export const AccessTokens: React.FC = () => {
  const t = useTranslate()
  const queryClient = useQueryClient()
  const [value, setValue] = useState('')

  const { tableProps } = useTable<IAccessToken>({
    syncWithLocation: false,
    pagination: {
      pageSize: 10000,
    },
    resource: 'access_tokens',
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: 'verified',
        },
        {
          field: 'kind',
          operator: 'eq',
          value: 'api',
        },
      ],
    },
    queryOptions: {
      queryKey: ['dev', 'access_tokens'],
    },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: showModal,
    close: closeModal,
  } = useModalForm<IAccessToken>({
    resource: 'access_tokens',
    action: 'create',
    redirect: false,
    autoSubmitClose: false,
    onMutationSuccess: (data) => {
      setValue(data.data.token)
      queryClient.invalidateQueries(['dev', 'access_tokens'])
    },
  })

  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }

    const color =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        value
      )
        ? ''
        : 'red'

    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    )
  }

  const createButton = (
    <>
      <Button
        onClick={() => {
          showModal()
        }}
        type="primary"
      >
        {t('api_tokens.actions.create')}
      </Button>
      <Modal
        {...createModalProps}
        title={t('api_tokens.create.title')}
        footer={null}
        onCancel={() => {
          closeModal()
          setTimeout(() => setValue(''), 500)
        }}
      >
        {value ? (
          <>
            <Alert message={t('api_tokens.create.warning')} type="warning" />
            <Typography.Paragraph copyable style={{ marginTop: 16 }}>
              {value}
            </Typography.Paragraph>
          </>
        ) : (
          <Form {...createFormProps} layout="vertical">
            <Form.Item
              name="lifespan"
              initialValue={90}
              label={t('api_tokens.form.lifespan.label')}
              help={t('api_tokens.form.lifespan.help')}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="ip_addresses_whitelist"
              label={t('api_tokens.form.ip_addresses_whitelist.label')}
              help={t('api_tokens.form.ip_addresses_whitelist.help')}
              style={{ marginTop: 48 }}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                options={[]}
                tokenSeparators={[',', ' ']}
                tagRender={tagRender}
                dropdownRender={() => <></>}
                dropdownStyle={{ display: 'none' }}
                removeIcon={<></>}
                suffixIcon={<></>}
              />
            </Form.Item>
            <Form.Item style={{ marginTop: 48 }}>
              <Button type="primary" size="large" htmlType="submit" loading={false} block>
                {t('api_tokens.form.submit')}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )

  return (
    <Card title={t('dev.access_tokens.title')} extra={createButton}>
      {tableProps.dataSource?.length ? (
        <ApiTokensTable tableProps={tableProps} />
      ) : (
        <Empty
          description={t('dev.access_tokens.empty')}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </Card>
  )
}
