import React, { useState } from 'react'
import { Timer } from 'components/timer'
import { GenericResponse, IOperation } from 'interfaces'
import { Typography } from "antd";
import './styles.css'

import { ActionsButtons } from './ActionsButtons'
import { BankCard } from './BankCard'
import { Sbp } from './Sbp'
import { useApiUrl, useCustomMutation, useInvalidate } from "@refinedev/core";

const { Text, Paragraph } = Typography

export const CreatedOperation: React.FC<IOperation> = ({
  id,
  amount,
  payment_requisite,
  actions,
  expires_at,
}) => {
  const apiUrl = useApiUrl()
  const invalidate = useInvalidate()
  const { mutate } = useCustomMutation<GenericResponse>()
  const [isLoading, setIsLoading] = useState(false)

  const invalidateOperation = () => {
    invalidate({
      resource: 'cashins',
      invalidates: ['detail'],
      id,
    })
  }

  const onRenew = () => {
    mutate(
      {
        url: `${apiUrl}/cashins/${id}/renew`,
        method: 'post',
        values: {},
      },
      {
        onError: (error, variables, context) => {
          setIsLoading(false)
        },
        onSuccess: (data, variables, context) => {
          setIsLoading(false)
          invalidateOperation()
        },
      }
    )
  }

  const canRenew = !!actions.find((item) => item.code === 'renew')

  let requisite

  if (payment_requisite.card_number) {
    requisite = (
      <>
        <Paragraph>
          <Text>На эту карту 👇</Text>
        </Paragraph>
        <BankCard payment_requisite={payment_requisite} />
      </>
    )
  } else if (payment_requisite.sbp) {
    requisite = (
      <>
        <Paragraph>
          <Text>По номеру телефона 👇</Text>
        </Paragraph>
        <Sbp payment_requisite={payment_requisite} />
      </>
    )
  }

  return (
    <>
      <Paragraph>
        <Text>Переведите </Text>
        <Text style={{ fontSize: 16 }}>{`${Number(amount)}₽`}</Text>
      </Paragraph>
      {requisite}
      <Paragraph style={{ marginTop: 10 }}>
        <Timer
          expiresAt={expires_at}
          onExpire={invalidateOperation}
          canRenew={canRenew}
          onRenew={onRenew}
          renewButtonIsLoading={isLoading}
        />
      </Paragraph>
      <ActionsButtons id={id} actions={actions} />
    </>
  )
}
