import React from 'react'
import { operationStatus } from 'interfaces'
import { Tag } from 'antd'
import { useTranslate } from '@refinedev/core'

export const OperationStatusMap = {
  created: 'operations.statuses.created',
  paid: 'operations.statuses.paid',
  expired: 'operations.statuses.expired',
  cancelled: 'operations.statuses.cancelled',
  rejected: 'operations.statuses.rejected',
  manually_approved: 'operations.statuses.manually_approved',
  auto_approved: 'operations.statuses.auto_approved',
  wasted: 'operations.statuses.wasted',
}

export const OperationStatus: React.FC<{ status: operationStatus }> = ({ status }) => {
  const t = useTranslate()

  const OperationStatusColorMap = {
    created: 'blue',
    paid: 'orange',
    expired: 'red',
    cancelled: 'red',
    rejected: 'red',
    manually_approved: 'green',
    auto_approved: 'green',
    wasted: 'red',
  }

  return (
    <Tag color={OperationStatusColorMap[status]} style={{ fontSize: 10 }}>
      {t(OperationStatusMap[status])}
    </Tag>
  )
}
