import { IResourceComponentsProps } from '@refinedev/core'
import { ActiveSessions } from './active_sessions'
import { CurrentSession } from './current_session'
import { TwoFactor } from './two_factor'

export const Security: React.FC<IResourceComponentsProps> = () => {
  return (
    <>
      <TwoFactor />
      <CurrentSession />
      <ActiveSessions />
    </>
  )
}
