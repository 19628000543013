import React from 'react'
import { feedItemProblem } from 'interfaces'
import { Tag } from "antd";
import { useTranslate } from "@refinedev/core";

export const TranslateMap = {
  operation_required: 'feed_items.problems.operation_required',
  other: 'feed_items.problems.other',
}

export const FeedItemProblems: React.FC<{ problems: feedItemProblem[] }> = ({
  problems,
}) => {
  const t = useTranslate()

  const ColorMap = {
    operation_required: 'red',
    other: 'red',
  }

  return (
    <>
      {problems.map((problem) => (
        <Tag color={ColorMap[problem]}>{t(TranslateMap[problem])}</Tag>
      ))}
    </>
  )
}
