import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'

import { IAgent } from 'interfaces'

export const AgentCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IAgent>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('agents.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
