import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useNotification } from '@refinedev/core'
import { IPaymentRequisite } from 'interfaces'
import { formattedCardNumber } from 'utils'
import { CopyOutlined } from '@ant-design/icons'
import { Typography, Card, Button } from 'antd'
import './styles.css'

const { Text, Paragraph, Title } = Typography

interface BankCardProps {
  payment_requisite: IPaymentRequisite
}

export const BankCard: React.FC<BankCardProps> = ({ payment_requisite }) => {
  const { open } = useNotification()
  const { gateway, card_number } = payment_requisite

  let bankCode

  switch (gateway.name) {
    case 'Тиньков':
      bankCode = 'tink'
      break
    case 'Сбербанк':
      bankCode = 'sber'
      break
  }
  return (
    <Card
      className={`card ${bankCode}`}
      title={gateway.name ? <Title level={4}>{gateway.name}</Title> : null}
    >
      <Paragraph style={{ marginTop: 20 }}>
        <Text style={{ fontSize: 20 }}>{formattedCardNumber(card_number, false)}</Text>
        <CopyToClipboard
          text={card_number}
          onCopy={() => {
            open && open({ message: 'Номер скопирован', type: 'success' })
          }}
        >
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            icon={<CopyOutlined />}
          >
            Скопировать номер
          </Button>
        </CopyToClipboard>
      </Paragraph>
    </Card>
  )
}
