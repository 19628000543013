import { Show, useTable } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { IUser } from 'interfaces'
import UsersTable from '../users/list_table'

export const UsersList: React.FC<any> = ({ merchantId }) => {
  const t = useTranslate()
  const { tableProps } = useTable<IUser>({
    resource: 'users',

    filters: {
      permanent: [
        {
          field: 'merchant_id',
          operator: 'eq',
          value: merchantId,
        },
      ]
    }
  })

  return (
    <Show
      title={t('merchants.titles.users')}
      headerButtons={() => <></>}
      canEdit={false}
      goBack={false}
      breadcrumb={false}
      wrapperProps={{ style: { marginTop: 20 } }}
    >
      <UsersTable tableProps={tableProps} rolesReadonly={true} />
    </Show>
  )
}
