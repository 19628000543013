import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'
import { Checkbox, Form, Input, Select } from 'antd'

import { IPartner } from 'interfaces'
import { currencyOptions } from 'utils'

export const PartnerCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IPartner>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" initialValues={{ currency: 'RUB' }}>
        <Form.Item
          label={t('partners.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label={t('partners.fields.currency')}
          name="currency"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={currencyOptions} />
        </Form.Item>
        <Form.Item
          name="flexpay"
          valuePropName="checked"
          label={t('partners.fields.flexpay')}
          help={t('partners.form.flexpay.help')}
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Create>
  )
}
