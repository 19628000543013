import React from 'react'
import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'
import { List, useSelect, useTable } from '@refinedev/antd'
import { IMerchant, IWebhook } from 'interfaces'
import WebhooksTable from './list_table'
import { Form, Input, Select } from 'antd'

export const WebhooksList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const { tableProps, searchFormProps, filters } = useTable<IWebhook>({
    onSearch: (params) => {
      // @ts-ignore
      const { operation_id, merchant_id, id, event } = params
      const disputesFilters: CrudFilters = []

      disputesFilters.push({
        field: 'operation_id',
        operator: 'eq',
        value: Array.isArray(operation_id) ? null : operation_id,
      })

      disputesFilters.push({
        field: 'id',
        operator: 'eq',
        value: Array.isArray(id) ? null : id,
      })

      disputesFilters.push({
        field: 'merchant_id',
        operator: 'eq',
        value: merchant_id,
      })

      disputesFilters.push({
        field: 'event',
        operator: 'eq',
        value: event,
      })

      return disputesFilters
    },
    pagination: {
      pageSize: 25,
    },
  })

  return (
    <List>
      <Form
        {...searchFormProps}
        layout="inline"
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          operation_id: getDefaultFilter('operation_id', filters, 'eq'),
          merchant_id: getDefaultFilter('merchant_id', filters, 'eq'),
          event: getDefaultFilter('event', filters, 'eq'),
          id: getDefaultFilter('id', filters, 'eq'),
        }}
      >
        <Form.Item name="merchant_id" style={{ width: 250, marginBottom: 20 }}>
          <Select
            allowClear
            showSearch
            placeholder={t('webhooks.filter.merchant.placeholder')}
            {...merchantsSelectProps}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="event" style={{ width: 250 }}>
          <Select
            allowClear
            showSearch
            placeholder={t('webhooks.filter.event.placeholder')}
            options={[
              {
                label: 'operations.auto_approve',
                value: 'operations.auto_approve',
              },
              {
                label: 'operations.manually_approve',
                value: 'operations.manually_approve',
              },
              {
                label: 'operations.reject',
                value: 'operations.reject',
              },
              {
                label: 'operations.expire',
                value: 'operations.expire',
              },
              {
                label: 'operations.cancel',
                value: 'operations.cancel',
              },
              {
                label: 'disputes.approve',
                value: 'disputes.approve',
              },
              {
                label: 'disputes.reject',
                value: 'disputes.reject',
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="id" style={{ width: 250 }}>
          <Input placeholder={t('webhooks.filter.id.placeholder')} allowClear />
        </Form.Item>
        <Form.Item name="operation_id" style={{ width: 250 }}>
          <Input placeholder={t('webhooks.filter.operation_id.placeholder')} allowClear />
        </Form.Item>
      </Form>
      <WebhooksTable tableProps={tableProps} />
    </List>
  )
}
