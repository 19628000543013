import { AuthBindings } from '@refinedev/core'

import { TOKEN_KEY, USER_KEY, BACKEND_URL, TWOFA_KEY } from './constants'
import axios, { AxiosInstance } from 'axios'

export const authProvider = (axiosInstance: AxiosInstance): AuthBindings => {
  let apiUrl = 'http://localhost:3001'

  if (BACKEND_URL) {
    apiUrl = BACKEND_URL
  }

  return {
    login: async ({ email = null, password = null, code = null }) => {
      if (code) {
        try {
          await axiosInstance.post(
            `${apiUrl}/sessions/verify`,
            { code }
            // {
            //   headers: {
            //     Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
            //   },
            // }
          )
          localStorage.setItem(TWOFA_KEY, 'passed')

          const userInfo = await axiosInstance.get(`${apiUrl}/users/current`)
          localStorage.setItem(USER_KEY, JSON.stringify(userInfo.data))

          return {
            success: true,
            redirectTo: '/',
          }
        } catch (error: any) {
          console.log('error.response?.data', error.response?.data)

          return {
            success: false,
            error: {
              message: 'Ошибка',
              name: error.response?.data?.errors?.join(', '),
            },
          }
        }
      } else {
        try {
          const { data } = await axios.post(`${apiUrl}/sessions`, {
            email,
            password,
            lifespan: 30,
          })
          localStorage.setItem(TOKEN_KEY, data.token)
          localStorage.setItem(
            TWOFA_KEY,
            data.two_factor_verification_required ? 'required' : 'not_required'
          )

          if (!data.two_factor_verification_required) {
            const userInfo = await axiosInstance.get(`${apiUrl}/users/current`)
            localStorage.setItem(USER_KEY, JSON.stringify(userInfo.data))
          }

          return {
            success: true,
            redirectTo: '/',
          }
        } catch (error: any) {
          return {
            success: false,
            error: {
              message: 'Ошибка',
              name: error.response?.data?.errors?.join(', '),
            },
          }
        }
      }
    },
    logout: async () => {
      try {
        await axiosInstance.delete(`${apiUrl}/sessions/logout`)
      } catch {}

      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem(USER_KEY)
      localStorage.removeItem(TWOFA_KEY)
      return {
        success: true,
        redirectTo: '/login',
      }
    },
    check: async () => {
      const token = localStorage.getItem(TOKEN_KEY)
      const two_fa = localStorage.getItem(TWOFA_KEY)
      if (token) {
        if (two_fa && two_fa === 'required') {
          return {
            authenticated: false,
            redirectTo: '/two_factor',
          }
        } else {
          return {
            authenticated: true,
          }
        }
      }

      return {
        authenticated: false,
        redirectTo: '/login',
      }
    },
    getPermissions: async () => {
      const userInfo = localStorage.getItem(USER_KEY)
      if (userInfo) {
        const parsedUser = JSON.parse(userInfo)
        return parsedUser.roles
      }
      return { success: false }
    },
    getIdentity: async () => {
      try {
        const token = localStorage.getItem(TOKEN_KEY)
        const two_fa = localStorage.getItem(TWOFA_KEY)
        if (token && (two_fa ? two_fa === 'passed' || two_fa === 'not_required' : true)) {
          let userInfo = localStorage.getItem(USER_KEY)
          if (userInfo) {
            const parsedUser = JSON.parse(userInfo)
            return parsedUser
          } else {
            let userInfo = await axiosInstance.get(`${apiUrl}/users/current`)
            localStorage.setItem(USER_KEY, JSON.stringify(userInfo.data))
            return userInfo.data
          }
        } else {
          return {}
        }
      } catch (error: any) {
        return { error }
      }
    },
    onError: async (error) => {
      if (error.statusCode === 401) {
        return {
          redirectTo: '/login',
          logout: true,
          error,
        }
      }
      return { error }
    },
  }
}
