import { useState } from 'react'

import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
  useCan,
} from '@refinedev/core'

import { useSelect, useTable } from '@refinedev/antd'
import { Button, Form, Input, Select } from 'antd'
import { IMerchant, IScoringClient } from 'interfaces'
import { CaretDownOutlined, CaretRightOutlined, SearchOutlined } from '@ant-design/icons'
import ScoringCliensTable from './list_table'
import ScopesFilter from 'components/ScopesFilter'

export const ScoringClientList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const [filtersExpanded, setFiltersExpanded] = useState(false)
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<IScoringClient>({
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { id, client_id, merchants_ids, scope } = params

      filters.push({
        field: 'id',
        operator: 'eq',
        value: id,
      })
      filters.push({
        field: 'client_id',
        operator: 'eq',
        value: client_id,
      })
      filters.push({
        field: 'merchants_ids',
        operator: 'in',
        value: merchants_ids,
      })
      filters.push({
        field: 'scope',
        operator: 'eq',
        value: scope,
      })

      return filters
    },

    pagination: {
      pageSize: 20,
    },

    filters: {
      initial: [
        {
          field: 'scope',
          operator: 'eq',
          value: 'green',
        },
      ],
    },
  })
  const { data } = usePermissions<string>({
    v3LegacyAuthProviderCompatible: true,
  })

  const { data: canViewMerchants } = useCan({
    resource: 'merchants',
    action: 'filter',
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
    queryOptions: {
      enabled: !!canViewMerchants?.can,
    },
  })

  return (
    <>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          id: getDefaultFilter('id', filters),
          merchants_ids: getDefaultFilter('merchants_ids', filters),
          client_id: getDefaultFilter('client_id', filters),
          scope: getDefaultFilter('scope', filters, 'eq'),
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('scoring/clients.filter.placeholders.id')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="client_id"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('scoring/clients.filter.placeholders.client_id')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Button
            icon={filtersExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
            onClick={() => {
              filtersExpanded ? setFiltersExpanded(false) : setFiltersExpanded(true)
            }}
          >
            {t('scoring/clients.titles.filters')}
          </Button>
        </div>
        <div
          style={{
            overflow: 'hidden',
            maxHeight: filtersExpanded ? 300 : 0,
            transition: 'max-height 1000ms',
          }}
        >
          <Form.Item style={{ marginBottom: 8 }}>
            {canViewMerchants?.can && (
              <Form.Item
                name="merchants_ids"
                style={{
                  marginBottom: 0,
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                }}
              >
                <Select
                  style={{ minWidth: 200 }}
                  allowClear
                  showSearch
                  placeholder={t('operations.filter.merchant.placeholder')}
                  mode="multiple"
                  {...merchantsSelectProps}
                  filterOption={(input, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
        </div>
        {
          // @ts-ignore
          tableQueryResult.data?.scopes && (
            <Form.Item name="scope">
              <ScopesFilter
                // @ts-ignore
                scopes={tableQueryResult.data?.scopes}
                resource={'scoring/clients'}
              />
            </Form.Item>
          )
        }
      </Form>
      <ScoringCliensTable tableProps={tableProps} />
    </>
  )
}
