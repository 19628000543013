import React from 'react'
import { Tag } from 'antd'
import { useTranslate } from '@refinedev/core'

const BooleanTag: React.FC<{ value: boolean | undefined }> = ({ value }) => {
  const t = useTranslate()

  return (
    <Tag color={value ? 'green' : 'red'}>
      {value ? t('components.boolean_tag.true') : t('components.boolean_tag.false')}
    </Tag>
  )
}

export default BooleanTag
