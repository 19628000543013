import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, useForm } from '@refinedev/antd'
import { Checkbox, Form, Input } from 'antd'

import { IPartner } from 'interfaces'

export const PartnerEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IPartner>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('partners.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('partners.fields.telegram_chat_id')} name="telegram_chat_id">
          <Input />
        </Form.Item>
        <Form.Item label={t('partners.fields.ecom_telegram_chat_id')} name="ecom_telegram_chat_id">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('partners.fields.notifications_chat_id')}
          name="notifications_chat_id"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('partners.fields.payouts_enabled')}
          name="payouts_enabled"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item name="max_approved_sum" label={t('partners.fields.max_approved_sum')}>
          <Input />
        </Form.Item>
        <Form.Item name="cash_limit_per_requisite" label={t('partners.fields.cash_limit_per_requisite')}>
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
