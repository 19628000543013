import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, useForm, useSelect } from '@refinedev/antd'
import { Checkbox, Form, Input, InputNumber, Select } from 'antd'

import { IPaymentCascade, IGateway, IMerchant } from 'interfaces'

export const PaymentCascadeEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IPaymentCascade>()

  const { selectProps: gatewaySelectProps } = useSelect<IGateway>({
    resource: 'gateways',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  const { selectProps: merchantSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  let paymentSystemOptions = []

  paymentSystemOptions.push({
    label: t('payment_cascades.payment_system.spacepayments'),
    value: 'spacepayments',
  })
  paymentSystemOptions.push({
    label: t('payment_cascades.payment_system.auris'),
    value: 'auris',
  })

  let kindOptions = []

  kindOptions.push({
    label: t('payment_cascades.kind.income'),
    value: 'income',
  })
  kindOptions.push({
    label: t('payment_cascades.kind.payout'),
    value: 'payout',
  })

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item 
          name="payment_system"
          rules={[
            {
              required: true,
            },
          ]}
          >
          <Select
            allowClear
            showSearch
            options={paymentSystemOptions}
            placeholder={t('payment_cascades.filter.payment_system.placeholder')}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="kind"
          rules={[
            {
              required: true,
            },
          ]}
          >
          <Select
            allowClear
            showSearch
            options={kindOptions}
            placeholder={t('payment_cascades.filter.kind.placeholder')}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={t('payment_cascades.form.traffic_percentage.label')}
          name="traffic_percentage"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="all_merchants"
          valuePropName="checked"
          label={t('payment_cascades.fields.all_merchants')}
          help={t('payment_cascades.form.all_merchants.help')}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="all_gateways"
          valuePropName="checked"
          label={t('payment_cascades.fields.all_gateways')}
          help={t('payment_cascades.form.all_gateways.help')}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="gateway_id"
          label={t('payment_cascades.fields.gateway')}
        >
          <Select {...gatewaySelectProps} />
        </Form.Item>
        <Form.Item
          name="merchant_id"
          label={t('payment_cascades.fields.merchant')}
        >
          <Select {...merchantSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  )
}
