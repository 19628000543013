import { Fragment } from 'react'
import { useForm } from '@refinedev/antd'
import { useQueryClient } from '@tanstack/react-query'
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Skeleton,
  Space,
  Typography,
} from 'antd'
import {
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useList,
  useTranslate,
} from '@refinedev/core'
import { GenericResponse, IFeed } from 'interfaces'
import { formattedCardNumber } from 'utils'

const { Text } = Typography

interface Test {}

export const FinishShift: React.FC<{ id: string; onFinish: () => void }> = ({
  id,
  onFinish,
}) => {
  const apiUrl = useApiUrl()
  const invalidate = useInvalidate()
  const queryClient = useQueryClient()
  const t = useTranslate()
  const { form, formProps } = useForm<Test>({ warnWhenUnsavedChanges: false })
  const comment = Form.useWatch('comment', form)
  const end_balances = Form.useWatch('end_balances', form)
  const start = Form.useWatch('start', form)

  const { mutate: updateResource } = useCustomMutation<GenericResponse>()
  const { data: feedsData, isLoading: feedsIsLoading } = useList<IFeed>({
    resource: 'feeds',

    filters: [
      {
        field: 'shift_id',
        operator: 'eq',
        value: id,
      },
    ],

    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })

  if (feedsIsLoading) {
    return <Skeleton active />
  }

  const finish = () => {
    updateResource(
      {
        url: `${apiUrl}/shifts/${id}/finish`,
        method: 'post',
        values: {
          comment,
          end_balances,
          start,
        },
        successNotification: (data, values) => {
          return {
            message: t('shifts.notifications.finished.message'),
            description: t('shifts.notifications.finished.description'),
            type: 'success',
          }
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          onFinish()
          queryClient.invalidateQueries(['shifts', 'current'])
          invalidate({
            resource: 'shifts',
            invalidates: ['list'],
          })
          invalidate({
            resource: 'reports',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  return (
    <Form
      {...formProps}
      layout="horizontal"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      initialValues={{ start: true }}
    >
      {feedsData?.data.length ? (
        <Space style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Text>Заполни остатки по всем реквизитам</Text>
        </Space>
      ) : (
        <></>
      )}
      <Form.List
        name={'end_balances'}
        initialValue={feedsData?.data.map((feed) => ({
          feed_id: feed.id,
          end_balance: feed.balance,
        }))}
      >
        {(fields) => (
          <div>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Fragment key={key}>
                  <Form.Item {...restField} name={[name, 'feed_id']} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'end_balance']}
                    label={
                      <code>
                        {formattedCardNumber(
                          feedsData!.data[key].payment_requisite.card_number
                        )}
                      </code>
                    }
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      type="number"
                      decimalSeparator="."
                      precision={2}
                      min={0}
                    />
                  </Form.Item>
                </Fragment>
              )
            })}
          </div>
        )}
      </Form.List>
      <Form.Item name="comment" label={t('shifts.form.comment.label')}>
        <Input.TextArea
          placeholder={t('shifts.form.comment.placeholder')}
          rows={4}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name="start"
        valuePropName="checked"
        label={t('shifts.form.start.label')}
        help={t('shifts.form.start.help')}
      >
        <Checkbox />
      </Form.Item>
      <Button type="primary" onClick={finish}>
        {t('shifts.buttons.finish')}
      </Button>
    </Form>
  )
}
