import { Typography } from "antd";
import DateField from 'components/DateField'
const { Text } = Typography

export const DatesInterval: React.FC<{ start: string | null; end: string | null }> = ({
  start,
  end,
}) => {
  return (
    <>
      <DateField value={start} />
      <Text style={{ verticalAlign: 'bottom' }}>&nbsp;—&nbsp;</Text>
      <DateField value={end} />
    </>
  )
}
