import { IResourceComponentsProps, useCan, useTranslate } from '@refinedev/core'

import { Space, Table, TableProps } from 'antd'
import { IDispute } from 'interfaces'

import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import { OperationAttachments } from 'pages/operations/OperationAttachments'
import { formattedAmount } from 'utils'
import { OperationStatus } from 'pages/cashin/OperationStatus'
import { ShowButton } from '@refinedev/antd'
import { DisputeStatus } from './DisputeStatus'

const DisputesTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IDispute>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  const { data: canViewPartners } = useCan({
    resource: 'partners',
    action: 'filter',
  })

  const { data: canViewMerchans } = useCan({
    resource: 'merchants',
    action: 'filter',
  })

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 10,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IDispute>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('disputes.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IDispute>
        dataIndex="created_at"
        key="created_at"
        title={t('disputes.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IDispute>
        dataIndex="status"
        key="status"
        title={t('disputes.fields.status')}
        render={(value) => <DisputeStatus status={value} />}
      />
      {canViewMerchans?.can && (
        <Table.Column<IDispute>
          dataIndex={['merchant', 'name']}
          key="merchant_name"
          title={t('disputes.fields.merchant_name')}
        />
      )}
      <Table.Column<IDispute>
        dataIndex="amount"
        key="amount"
        title={t('disputes.fields.amount')}
        render={formattedAmount}
      />
      {canViewPartners?.can && (
        <Table.Column<IDispute>
          dataIndex={['partner', 'name']}
          ellipsis
          key="partner_name"
          title={t('disputes.fields.partner_name')}
        />
      )}
      <Table.Column<IDispute>
        dataIndex={['operation', 'id']}
        ellipsis
        key="operation_id"
        title={t('disputes.fields.operation_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IDispute>
        dataIndex={['operation', 'status']}
        ellipsis
        key="operation_status"
        title={t('disputes.fields.operation_status')}
        render={(value) => <OperationStatus status={value} />}
      />
      <Table.Column<IDispute>
        dataIndex={'comments'}
        key="comment"
        title={t('disputes.fields.latest_comment')}
        render={(value) => value.length && value[0].body}
      />
      <Table.Column<IDispute>
        width={100}
        title={t('disputes.fields.attachment')}
        dataIndex="actions"
        key="actions"
        align="center"
        render={(_value, record) => (
          <OperationAttachments attachments_ids={record.attachment_ids} />
        )}
      />
      <Table.Column<IDispute>
        title="Actions"
        fixed="right"
        dataIndex="actions"
        render={(_, record) => (
          <Space>
            <ShowButton resource="disputes" size="small" recordItemId={record.id} />
          </Space>
        )}
      />
    </Table>
  )
}

export default DisputesTable
