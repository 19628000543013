import {
  Button,
  Form,
  FormProps,
  Input,
  InputNumber,
  Skeleton,
  Space,
  Typography,
} from 'antd'
import { useList, useTranslate } from '@refinedev/core'
import { IFeed, IPaymentRequisite, IShift } from 'interfaces'
import { formattedCardNumber } from 'utils'

const { Text } = Typography

export const NewShift: React.FC<{
  formProps: FormProps
  saveButtonProps: any
  partner_id: string
}> = ({ formProps, saveButtonProps, partner_id }) => {
  const t = useTranslate()

  const { data: previousShiftData, isLoading: previousShiftIsLoading } = useList<IShift>({
    resource: 'shifts',
    pagination: {
      current: 1,
      pageSize: 1,
    },
  })

  const { data: previousShiftFeedsData, isLoading: previousShiftFeedsIsLoading } =
    useList<IFeed>({
      resource: 'feeds',
      filters: [
        {
          field: 'shift_id',
          operator: 'eq',
          value: previousShiftData?.data[0]?.id,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1000,
      },
      queryOptions: {
        enabled: !!previousShiftData?.data[0]?.id,
      },
    })

  const { data: paymentRequisitesData, isLoading: paymentRequisitesIsLoading } =
    useList<IPaymentRequisite>({
      resource: 'payment_requisites',

      filters: [
        {
          field: 'status',
          operator: 'in',
          value: ['active', 'overflowed'],
        },
      ],

      pagination: {
        current: 1,
        pageSize: 10000,
      },
    })

  if (
    paymentRequisitesIsLoading ||
    previousShiftIsLoading ||
    (previousShiftData?.data[0]?.id && previousShiftFeedsIsLoading)
  )
    return <Skeleton active />

  let initialValue: any[] = []
  previousShiftFeedsData?.data.forEach((feed) => {
    const paymentRequisite = paymentRequisitesData?.data.find(
      (item) => item.id === feed.payment_requisite.id
    )
    paymentRequisite &&
      initialValue.push({
        payment_requisite_id: feed.payment_requisite.id,
        start_balance: parseFloat(feed.end_balance),
        card_number: paymentRequisite.card_number,
      })
  })

  paymentRequisitesData?.data.forEach((payment_requisite) => {
    initialValue.findIndex(
      (item) => item.payment_requisite_id === payment_requisite.id
    ) === -1 &&
      initialValue.push({
        payment_requisite_id: payment_requisite.id,
        start_balance: 0,
        card_number: payment_requisite.card_number,
      })
  })

  return (
    <Form
      layout="horizontal"
      {...formProps}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Space style={{ marginTop: '16px', marginBottom: '16px' }}>
        <Text>Заполни остатки по всем реквизитам</Text>
      </Space>
      <Form.Item name="partner_id" hidden initialValue={partner_id}>
        <Input />
      </Form.Item>
      <Form.List name={'start_balances'} initialValue={initialValue}>
        {(fields) => (
          <div>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <>
                  <Form.Item {...restField} name={[name, 'payment_requisite_id']} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'start_balance']}
                    label={
                      <code>
                        {initialValue[key].card_number &&
                          formattedCardNumber(initialValue[key].card_number)}
                      </code>
                    }
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      type="number"
                      decimalSeparator="."
                      precision={2}
                      min={0}
                    />
                  </Form.Item>
                </>
              )
            })}
          </div>
        )}
      </Form.List>
      <Button {...saveButtonProps}>{t('shifts.buttons.create')}</Button>
    </Form>
  )
}
