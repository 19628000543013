import React from 'react'
import { Typography } from 'antd'
import { formattedAmount } from 'utils'

const { Text } = Typography

const TextAmountColor: React.FC<{
  value: string | bigint | number | null,
  style?: React.CSSProperties
}> = ({ value, style = {} }) => {
  let color = ''

  if (Number(value) > 0) {
    color = 'green'
  } else if (Number(value) < 0) {
    color = 'red'
  }

  return (
    <Text style={Object.assign({ color: color }, style)}>
      {formattedAmount(value)}
    </Text>
  )
}

export default TextAmountColor
