import { IResourceComponentsProps, useTranslate, useCan, useMany } from '@refinedev/core'

import { Table, TableProps, Skeleton, Typography } from 'antd'
import { IMerchant, IPartner, ITeam, IReport, IShift } from 'interfaces'

import DateField from 'components/DateField'
import { formattedCurrencyAmount } from 'utils'
import { ShortId } from 'components/ShortId'
import { OperationAttachments } from 'pages/operations/OperationAttachments'

const ReportsTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IReport>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  const { data: canViewShifts } = useCan({
    resource: 'shifts',
    action: 'list',
  })

  const { data: canViewMerchants } = useCan({
    resource: 'merchants',
    action: 'filter',
  })

  const { data: canViewPartners } = useCan({
    resource: 'partners',
    action: 'filter',
  })

  const { data: canViewTeams } = useCan({
    resource: 'teams',
    action: 'filter',
  })

  const shiftsIds = tableProps?.dataSource?.map((item) => item.shift_id) ?? []
  const merchantsIds = tableProps?.dataSource?.map((item) => item.merchant_id) ?? []
  const partnersIds = tableProps?.dataSource?.map((item) => item.partner_id) ?? []
  const teamsIds = tableProps?.dataSource?.map((item) => item.team_id) ?? []

  const { data: shiftsData, isLoading: shiftsLoading } = useMany<IShift>({
    resource: 'shifts',
    ids: shiftsIds,
    meta: {
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      enabled: !!(canViewShifts?.can && shiftsIds.filter((id) => !!id).length > 0),
    },
  })

  const { data: merchantsData, isLoading: merchantsLoading } = useMany<IMerchant>({
    resource: 'merchants',
    ids: merchantsIds,
    meta: {
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      enabled: !!(canViewMerchants?.can && merchantsIds.filter((id) => !!id).length > 0),
    },
  })

  const { data: partnersData, isLoading: partnersLoading } = useMany<IPartner>({
    resource: 'partners',
    ids: partnersIds,
    meta: {
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      enabled: !!(canViewPartners?.can && partnersIds.filter((id) => !!id).length > 0),
    },
  })

  const { data: teamsData, isLoading: teamsLoading } = useMany<ITeam>({
    resource: 'teams',
    ids: teamsIds,
    queryOptions: {
      enabled: !!(canViewTeams?.can && teamsIds.filter((id) => !!id).length > 0),
    },
  })

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 10,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IReport>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('reports.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IReport>
        dataIndex="created_at"
        key="created_at"
        title={t('reports.fields.created_at')}
        render={(value, record) => (
          <div>
            <DateField value={value} />
            <span>{record.timezone}</span>
          </div>
        )}
      />
      <Table.Column<IReport>
        dataIndex="status"
        key="status"
        title={t('reports.fields.status')}
        render={(value) => t(`reports.statuses.${value}`)}
      />
      <Table.Column<IReport>
        dataIndex="subject"
        key="subject"
        title={t('reports.fields.subject')}
        render={(value) => t('reports.subjects.' + value)}
      />
      <Table.Column<IReport>
        dataIndex="period"
        key="period"
        title={t('reports.fields.period')}
        render={(_, record) => {
          if (record.shift_id) {
            if (shiftsIds.length && shiftsLoading) {
              return <Skeleton.Input active />
            }
            const shift = shiftsData?.data?.find((shift) => shift.id === record.shift_id)

            return (
              shift && (
                <>
                  <DateField value={shift.started_at} />
                  <span>–</span>
                  <br />
                  <DateField value={shift.finished_at} />
                </>
              )
            )
          } else {
            return (
              <>
                <DateField value={record.since} />
                <span>–</span>
                <br />
                <DateField value={record.to} />
              </>
            )
          }
        }}
      />
      {(canViewMerchants?.can || canViewShifts?.can || canViewPartners?.can || canViewTeams?.can) && (
        <Table.Column<IReport>
          dataIndex="content"
          key="content"
          title={t('reports.fields.content')}
          render={(_, record) => {
            if (record.shift_id) {
              if (shiftsIds.length && shiftsLoading) {
                return <Skeleton.Input active />
              } else {
                const shift = shiftsData?.data?.find(
                  (shift) => shift.id === record.shift_id
                )

                return shift && shift.partner.name
              }
            } else if (record.merchant_id) {
              if (merchantsIds.length && merchantsLoading && record.merchant_id) {
                return <Skeleton.Input active />
              }

              const merchant = merchantsData?.data?.find(
                (merchant) => merchant.id === record.merchant_id
              )

              return merchant && merchant.name
            } else if (record.partner_id) {
              if (partnersIds.length && partnersLoading && record.partner_id) {
                return <Skeleton.Input active />
              }

              const partner = partnersData?.data?.find(
                (partner) => partner.id === record.partner_id
              )

              return partner && partner.name
            } else if (record.team_id) {
              if (teamsIds.length && teamsLoading && record.team_id) {
                return <Skeleton.Input active />
              }

              const team = teamsData?.data?.find(
                (team) => team.id === record.team_id
              )

              return team && team.name
            } else {
              return ''
            }
          }}
        />
      )}
      <Table.Column<IReport>
        dataIndex="operations_amount"
        key="operations_amount"
        title={t('reports.fields.operations_amount')}
        render={(value, record) =>
          `${formattedCurrencyAmount(value)} (${record.operations_count})`
        }
      />
      <Table.Column<IReport>
        dataIndex="payout_operations_amount"
        key="payout_operations_amount"
        title={t('reports.fields.payout_operations_amount')}
        render={(value, record) =>
          `${formattedCurrencyAmount(value)} (${record.payout_operations_count})`
        }
      />
      <Table.Column<IReport>
        fixed="right"
        width={100}
        title={t('reports.fields.attachment')}
        dataIndex="actions"
        key="actions"
        align="center"
        render={(_value, record) => (
          <OperationAttachments
            attachments_ids={record.attachment_ids}
            title={t('reports.buttons.download')}
          />
        )}
      />
    </Table>
  )
}

export default ReportsTable
