import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { List, useTable } from '@refinedev/antd'
import { IAgent } from 'interfaces'
import AgentsTable from './list_table'

export const AgentsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IAgent>()

  return (
    <List>
      <AgentsTable tableProps={tableProps}></AgentsTable>
    </List>
  )
}
