import React, { useEffect } from 'react'
import { useInvalidate } from "@refinedev/core";
import { Typography, Row, Col } from "antd";
import { ClockCircleOutlined } from '@ant-design/icons'

import './styles.css'
import { IOperation } from 'interfaces'

const { Text } = Typography

const INTERVAL = 2000

export const ConfirmedOperation: React.FC<IOperation> = ({ id }) => {
  const invalidate = useInvalidate()

  useEffect(() => {
    let intervalId = setInterval(() => {
      invalidate({
        resource: 'cashins',
        invalidates: ['detail'],
        id,
      })
    }, INTERVAL)
    return () => {
      clearInterval(intervalId)
    }
  })

  return (
    <Row align="stretch" justify="space-around">
      <Col span={4} offset={2}>
        <ClockCircleOutlined style={{ fontSize: 24 }} />
      </Col>
      <Col span={18}>
        <Text>Идёт обработка платежа, может занять до 5 минут</Text>
      </Col>
    </Row>
  )
}
