import { Typography } from 'antd'
import './styles.css'

const { Text } = Typography

export const ShortId: React.FC<{ value: string; color?: string }> = ({
  value,
  color = null,
}) => {
  if (!value) {
    return <Text>–</Text>
  }

  return (
    <Text
      code
      copyable={{ text: value, tooltips: value }}
      className="shortid"
      style={{ color: color || undefined }}
    >
      {value.slice(-8)}
    </Text>
  )
}
