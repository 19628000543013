import React from 'react'
import { IResourceComponentsProps, useCan, useShow, useTranslate } from '@refinedev/core'
import { Show } from '@refinedev/antd'
import { Descriptions, Tabs, TabsProps, Space, Typography } from 'antd'

import { IPartner } from 'interfaces'
import { AgreementsList } from './agreements_list'
import BooleanTag from 'components/BooleanTag'
import {
  formattedCorrectedPercent,
  formattedCurrencyAmount,
  formattedPercent,
} from 'utils'

import { GatewayType } from 'components/GatewayType'

const { Text } = Typography

export const PartnerShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IPartner>()
  const { data, isLoading } = queryResult
  const record = data?.data

  let tabsItems: TabsProps['items'] = []
  tabsItems.push({
    key: '1',
    label: t('partners.titles.agreements'),
    children: record && !isLoading ? <AgreementsList parntnerId={record.id} /> : null,
  })

  const { data: canViewAgreements } = useCan({
    resource: 'billing/agreements',
    action: 'list',
  })

  return (
    <>
      <Show isLoading={isLoading} title={record?.name || ''} canEdit={true}>
        <Descriptions column={1}>
          <Descriptions.Item label={t('partners.fields.name')}>
            {record?.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('partners.fields.currency')}>
            {record?.currency}
          </Descriptions.Item>
          <Descriptions.Item label={t('partners.fields.telegram_chat_id')}>
            {record?.telegram_chat_id}
          </Descriptions.Item>
          <Descriptions.Item label={t('partners.fields.ecom_telegram_chat_id')}>
            {record?.ecom_telegram_chat_id}
          </Descriptions.Item>
          <Descriptions.Item label={t('partners.fields.notifications_chat_id')}>
            {record?.notifications_chat_id}
          </Descriptions.Item>
          <Descriptions.Item label={t('partners.fields.payouts_enabled')}>
            <BooleanTag value={record?.payouts_enabled} />
          </Descriptions.Item>
          <Descriptions.Item label={t('partners.fields.max_approved_sum')}>
            {record?.max_approved_sum
              ? formattedCurrencyAmount(record?.max_approved_sum)
              : 'Нет лимита'}
          </Descriptions.Item>
          <Descriptions.Item label={t('partners.fields.cash_limit_per_requisite')}>
            {record?.cash_limit_per_requisite
              ? formattedCurrencyAmount(record?.cash_limit_per_requisite)
              : 'Нет лимита'}
          </Descriptions.Item>
          <Descriptions.Item label={t('partners.fields.deposit_balance')}>
            {record?.deposit_balance
              ? formattedCurrencyAmount(record?.deposit_balance)
              : 'Нет депозита'}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1} layout='vertical'>           
          <Descriptions.Item label={t('partners.fields.agreements_income')}>
            <div>
              {record?.agreements_income?.length ? (
                record?.agreements_income.map((agreement) => (
                  <Text
                    code
                    ellipsis
                    style={{ display: 'flex', alignItems: 'center', margin: 4, }}
                  >
                    <Space>
                      {agreement?.gateway ? (
                        <Space>
                          <GatewayType type={agreement.gateway.payment_type} />
                          {agreement.gateway.currency}
                          {agreement.gateway.name}
                        </Space>
                      ) : <Text>Гейтвей не указан</Text>}
                      {formattedCorrectedPercent(agreement.percent)}
                    </Space>
                  </Text>
                ))) : <Text>Нет соглашений</Text>
              }
            </div>
          </Descriptions.Item>   
          <Descriptions.Item label={t('partners.fields.agreements_payout')}>
            <div>
              {record?.agreements_payout?.length ? (
                record?.agreements_payout.map((agreement) => (
                  <Text                  
                    code
                    ellipsis
                    style={{ display: 'flex', alignItems: 'center', margin: 4, }}
                  >
                    <Space>
                      {agreement?.gateway ? (
                        <Space>
                          <GatewayType type={agreement.gateway.payment_type} />
                          {agreement.gateway.currency}
                          {agreement.gateway.name}
                        </Space>
                      ) : <Text>Гейтвей не указан</Text>}
                      {formattedCorrectedPercent(agreement.percent)}
                    </Space>
                  </Text>
                ))) : <Text>Нет соглашений</Text>              
              }
            </div>
          </Descriptions.Item>   
        </Descriptions>
      </Show>
      {canViewAgreements?.can && <Tabs defaultActiveKey="1" items={tabsItems} />}
    </>
  )
}
