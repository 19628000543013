import {
  IResourceComponentsProps,
  useCan,
  usePermissions,
  useTranslate,
} from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select, DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'

import { IMerchant, IReport } from 'interfaces'

export const ReportCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { form, formProps, saveButtonProps } = useForm<IReport>({
    resource: 'reports',
    action: 'create',
    redirect: 'list',
  })

  const subject = Form.useWatch('subject', form)

  const { data: canCreateReport } = useCan({
    resource: 'reports',
    action: 'create',
  })

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  let options = []

  if (canCreateReport?.can) {
    options.push({
      label: t('reports.subjects.merchant'),
      value: 'merchant',
    })
    options.push({
      label: t('reports.subjects.finance_alfa'),
      value: 'finance_alfa',
    })

    options.push({
      label: t('reports.subjects.finance_beta'),
      value: 'finance_beta',
    })
    options.push({
      label: t('reports.subjects.finance_range'),
      value: 'finance_range',
    })
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item name="subject">
          <Select
            allowClear
            showSearch
            options={options}
            placeholder={t('reports.filter.subject.placeholder')}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        {(subject === 'merchant' || subject === 'finance_alfa') && (
          <Form.Item name="merchant_id">
            <Select
              {...merchantsSelectProps}
              allowClear
              showSearch
              placeholder={t('reports.filter.merchant.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}
        {(subject === 'finance_range') && (
          <Form.Item
            label={t('reports.filter.range.label')}
            name="range"
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker.RangePicker locale={locale} />
          </Form.Item>
        )}
      </Form>
    </Create>
  )
}
