import React from 'react'
import { clientColor } from 'interfaces'
import { Tag } from "antd";

const ClientColor: React.FC<{color: clientColor, value: string}> = ({color, value}) => {
  const ClientColorMap = {
    green: 'green',
    red: 'red',
  }

  return(
    <Tag
      color={ ClientColorMap[color] }
      style={{ fontSize: 10 }}
    >
      { value }
    </Tag>
  )
}

export default ClientColor
