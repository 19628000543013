import {
  IResourceComponentsProps,
  useTranslate,
  useCan,
  CanReturnType,
} from '@refinedev/core'

import { Table, Typography, TableProps, Space } from 'antd'
import { IOperation, IPaymentRequisite } from 'interfaces'
import { formattedAmount, formattedCardNumber, formattedAccountNumber, formattedPhone } from 'utils'
import { OperationStatus } from 'pages/cashin/OperationStatus'
import { OperationActions } from './OperationActions'

import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import CardColorAndNumber from 'components/CardColorAndNumber'
import ClientColor from 'components/ClientColor'
import dayjs from 'dayjs'
import { OperationView } from './OperationView'
import { OperationMargin } from './OperationMargin'
import { GatewayType } from 'components/GatewayType'

const { Text } = Typography

const OperationsTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IOperation>
    canViewMerchants: CanReturnType | undefined
  }
> = ({ tableProps, canViewMerchants }) => {
  const t = useTranslate()

  const { data: canApproveOperation } = useCan({
    resource: 'operations',
    action: 'approve',
  })
  const { data: canViewPartners } = useCan({
    resource: 'partners',
    action: 'filter',
  })
  const { data: canViewOperation } = useCan({
    resource: 'operations',
    action: 'view',
  })
  const { data: canUpdatePaymentRequisite } = useCan({
    resource: 'operations',
    action: 'update_payment_requisite',
  })
  const { data: canViewFee } = useCan({
    resource: 'operations',
    action: 'view_fee',
  })
  const { data: canViewScoringClients } = useCan({
    resource: 'scoring/clients',
    action: 'view',
  })
  const { data: canViewScoringClientId } = useCan({
    resource: 'scoring/clients',
    action: 'view_id',
  })
  const { data: canViewClientId } = useCan({
    resource: 'operations',
    action: 'view_client_id',
  })
  const { data: canViewMargin } = useCan({
    resource: 'operations',
    action: 'margin',
  })

  const MoreInfo: React.FC<{ operation: IOperation }> = ({ operation }) => {
    return (      
      <Table
        bordered={ true }
        scroll={{ x: '100%' }}
        size="small"
        dataSource={[operation]}
        pagination={false}
      >
        {canViewClientId?.can && (
          <Table.Column<IOperation>
            dataIndex="client_id"
            key="client_id"
            title={t('operations.fields.client_id')}
            render={(value, record: IOperation) => {
              return canViewScoringClients?.can ? (
                <ClientColor color={record?.client_color} value={value} />
              ) : (
                value
              )
            }}
          />
        )}
        {canViewScoringClientId?.can && (
          <Table.Column<IOperation>
            dataIndex="scoring_client_id"
            key="scoring_client_id"
            title={t('operations.fields.scoring_client_id')}
            render={(value, record) => {
              return canViewScoringClients?.can ? (
                <ShortId value={value} color={record?.client_color} />
              ) : (
                <ShortId value={value} />
              )
            }}
          />
        )}
        <Table.Column<IOperation>
          dataIndex="approval_time"
          key="approval_time"
          title={t('operations.fields.approval_time')}
          render={(_value, record) =>
            record.approved_at
              ? dayjs(record.approved_at).diff(dayjs(record.created_at), 'minutes')
              : ''
          }
        />
        {canApproveOperation?.can && (
          <Table.Column<IOperation>
            dataIndex="comment"
            key="comment"
            title={t('operations.fields.comment')}
          />
        )}
      </Table>
    )
  }
  
  return (
    <Table
      scroll={{ x: '100%' }}
      {...tableProps}
      size="small"
      rowKey="id"
      expandable={{
        expandedRowRender: (operation) => (
          <MoreInfo operation={operation} />
        )
      }}
      pagination={{
        ...tableProps.pagination,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column
        ellipsis={true}
        dataIndex="id"
        key="id"
        title={t('operations.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column
        width={10}
        dataIndex="status"
        key="status"
        title={t('operations.fields.status')}
        render={(value) => <OperationStatus status={value} />}
      />
      <Table.Column
        width={50}
        dataIndex="idempotency_key"
        key="idempotency_key"
        title={t('operations.fields.idempotency_key')}
      />
      <Table.Column
        ellipsis
        dataIndex={['gateway', 'currency']}
        key="currency"
        title={t('operations.fields.currency')}
      />
      <Table.Column
        width={50}
        align="right"
        dataIndex="amount"
        key="amount"
        title={t('operations.fields.amount')}
        render={formattedAmount}
      />
      <Table.Column
        width={50}
        align="right"
        dataIndex="approved_amount"
        key="approved_amount"
        title={t('operations.fields.approved_amount')}
        render={(value: string, record: IOperation) => (
          <Text mark={value ? Number(value) !== Number(record?.amount) : false}>
            {formattedAmount(value)}
          </Text>
        )}
      />
      {canViewFee?.can && (
        <Table.Column<IOperation>
          width={50}
          align="right"
          dataIndex="fee"
          key="fee"
          title={t('operations.fields.fee')}
          render={(value: string, record: IOperation) => (
            <Text
              style={{ 
                color: Number(value) <= Number(record?.margin?.cost) ? 'red' : '',
                fontWeight: Number(value) <= Number(record?.margin?.cost) ? 'bold' : ''
              }}
            >
              {formattedAmount(value)}
            </Text>
          )}
        />
      )}
      {canViewMargin?.can && (
        <>
          <Table.Column<IOperation>
            width={50}
            align="right"
            dataIndex={['margin', 'cost']}
            key="margin"
            title={t('operations.fields.cost')}
            render={(value: string) => (
              <Text
                style={{
                  color: Number(value) <= 0 ? 'red' : ''                  
                }}
              >
                {formattedAmount(value)}
              </Text>
            )}
          />
          <Table.Column<IOperation>
            dataIndex="margin"
            key="margin"
            render={(_value, record) => record.approved_amount !== null && <OperationMargin id={record.id} />}
          />
        </>
      )}
      {canViewMerchants?.can && (
        <Table.Column
          ellipsis
          dataIndex={['merchant', 'name']}
          key="merchant"
          title={t('operations.fields.merchant')}
        />
      )}      
      <Table.Column<IOperation>
        ellipsis
        dataIndex={'payment_requisite'}
        key="payment_requisite"
        title={t('operations.fields.card')}
        render={(value: IPaymentRequisite) => {
          if (value) {
            var text = ''
            if (value.sbp) {
              text = formattedPhone(value.sbp.phone)
            } else if (value.account) {
              text = formattedAccountNumber(value.account)
            } else {
              text = formattedCardNumber(value.card_number)
            }
            
            if (canUpdatePaymentRequisite?.can) {
              return <CardColorAndNumber number={text} color={value.color} />
            } else {
              return text
            }
          } else {
            return ''
          }
        }}
      />
      <Table.Column
        ellipsis
        dataIndex={['gateway', 'payment_type']}
        key="payment_type"
        title={t('operations.fields.type')}
        render={(value) => (<GatewayType type={value} />)}
      />
      <Table.Column
        ellipsis
        dataIndex={['gateway', 'name']}
        key="gateway_name"
        title={t('operations.fields.gateway')}
      />
      {canViewPartners?.can && (
        <Table.Column<IOperation>
          ellipsis
          dataIndex={['partner', 'name']}
          key="partner"
          title={t('operations.fields.partner')}
        />
      )}
      {canViewPartners?.can && (
        <Table.Column<IOperation>
          dataIndex={['payment_cascade', 'payment_system']}
          key="payment_cascade"
          title={t('operations.fields.payment_system')}
        />
      )}
      <Table.Column
        dataIndex="created_at"
        ellipsis={true}
        width={10}
        key="created_at"
        title={t('operations.fields.created_at')}
        render={(value) => (
          <DateField
            value={value}
            style={{ fontSize: 12 }}
          />
        )}
      />
      <Table.Column<IOperation>
        dataIndex="approved_at"
        ellipsis={true}
        width={10}
        key="approved_at"
        title={t('operations.fields.approved_at')}
        render={(value) => (
          <DateField
            value={value}
            style={{ fontSize: 12 }}
          />
        )}
      />      
      {(canApproveOperation?.can || canUpdatePaymentRequisite?.can) && (
        <Table.Column<IOperation>
          fixed="right"
          width={100}
          title={t('operations.fields.actions')}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_value, record) =>
            record.status !== 'wasted' && <OperationActions id={record.id} />
          }
        />
      )}
      {canViewOperation?.can && (
        <Table.Column<IOperation>
          width={50}
          fixed="right"
          dataIndex="view"
          key="view"
          render={(_value, record) => <OperationView id={record.id} />}
        />
      )}
    </Table>
  )
}

export default OperationsTable
