import { Create, useForm } from '@refinedev/antd'
import { useTranslate } from '@refinedev/core'
import { DatePicker, Form, Input, InputNumber, Radio } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'utils/dayjs'

import './styles.css'

interface IFeedItemForm {
  body: string
  amount: number
  balance: number
  operation_time: string
  operation_type: string
  feed_id: string
}

export const NewItem: React.FC<{
  feed_id: string
  onSuccess: () => void
  position: number | undefined
}> = ({ feed_id, onSuccess, position }) => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IFeedItemForm>({
    action: 'create',
    resource: 'feed_items',
    redirect: false,
    onMutationSuccess: onSuccess,
  })

  return (
    <Create
      wrapperProps={{ className: 'sexy' }}
      saveButtonProps={saveButtonProps}
      title={false}
      headerButtons={false}
      breadcrumb={false}
      goBack={false}
    >
      <Form
        layout="vertical"
        {...formProps}
        initialValues={{ operation_type: 'income', feed_id, position }}
        validateMessages={{
          required: t('feed_items.form.required_message'),
        }}
      >
        <Form.Item name="feed_id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="position" hidden>
          <Input />
        </Form.Item>
        <Form.Item name={'operation_type'}>
          <Radio.Group>
            <Radio value="income">
              {t('feed_items.form.operation_type.income.label')}
            </Radio>
            <Radio value="outcome">
              {t('feed_items.form.operation_type.outcome.label')}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="body"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea placeholder={t('feed_items.form.body.placeholder')} />
        </Form.Item>
        <Form.Item
          name="amount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            placeholder={t('feed_items.form.amount.placeholder')}
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
          />
        </Form.Item>
        <Form.Item
          name="balance"
          help="Укажи баланс из смс. Если смс не было, то оставь пустым"
        >
          <InputNumber
            placeholder={t('feed_items.form.balance.placeholder')}
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
          />
        </Form.Item>
        <Form.Item
          style={{ marginTop: 32 }}
          name="operation_time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
            placeholder={t('feed_items.form.operation_time.placeholder')}
          />
        </Form.Item>
      </Form>
    </Create>
  )
}
