import {
  CrudFilters,
  IResourceComponentsProps,
  useTranslate
} from '@refinedev/core'
import { List, TextField, TagField, useTable } from '@refinedev/antd'
import { Table, Form, Segmented } from 'antd'
import { IGateway } from 'interfaces'
import { ShortId } from 'components/ShortId'
import { GatewayType } from 'components/GatewayType'

export const GatewaysList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps } = useTable<IGateway>({
      onSearch: (params) => {
        // @ts-ignore
        const { payment_type } = params
        const gatewaysFilters: CrudFilters = []

        gatewaysFilters.push({
          field: 'payment_type',
          operator: 'eq',
          value: payment_type? payment_type : null,
        })

        return gatewaysFilters
      },

      pagination: {
        pageSize: 20,
      },
  })
  
  return (
    <List>
      <Form
        {...searchFormProps}
        layout="horizontal"
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
      >
        <div
          style={{
            overflow: 'auto'
          }}
        >
          <Form.Item name="payment_type">
            <Segmented
              options={[
                { label: 'ALL', value: '' },
                { label: 'CARD', value: 'card' },
                { label: 'SBP', value: 'sbp' },
                { label: 'ECOM', value: 'ecom' },
                { label: 'ACCOUNT', value: 'account' },
                { label: 'PAYOUT', value: 'payout' },
                { label: 'OUTCOME', value: 'outcome' },
                { label: 'REFILL', value: 'refill' },      
              ]}
            />
          </Form.Item>
        </div>
      </Form>
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          pageSize: 20,
          position: ['bottomLeft'],
          size: 'small',
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          width={150}
          ellipsis
          title={t('gateways.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column
          dataIndex="payment_type"
          key="payment_type"
          width={100}
          ellipsis
          title={t('gateways.fields.payment_type')}
          render={(value) => <GatewayType type={value} />}
        />
        <Table.Column
          dataIndex="currency"
          key="currency"
          width={100}
          title={t('gateways.fields.currency')}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          ellipsis
          title={t('gateways.fields.name')}
          render={(value) => <TextField value={value} />}
        />        
        <Table.Column
          dataIndex="status"
          key="status"
          title={t('gateways.fields.status')}
          render={(value) => <TagField value={value} />}
        />
      </Table>
    </List>
  )
}
