import React from 'react'
import { useOne } from '@refinedev/core'
import { useParams } from 'react-router-dom'
import { Typography, Card, Col, Row } from 'antd'
import { Layout, Space, Spin } from 'antd'

import { IOperation } from 'interfaces'
import { CreatedOperation } from './CreatedOperation'
import { CancelledOperation } from './CancelledOperation'
import { ConfirmedOperation } from './ConfirmedOperation'
import { ExecutedOperation } from './ExecutedOperation'

import './styles.css'

const { Text, Paragraph } = Typography
const { Content } = Layout

export const CashinPage: React.FC = () => {
  const params = useParams()

  const { data, isLoading } = useOne<IOperation>({
    resource: 'cashins',
    id: params.id || '',
  })

  let content

  if (!data || isLoading) {
    content = (
      <Space className="loading-space">
        <Spin tip="Loading" size="large" />
      </Space>
    )
  } else {
    const { id, status } = data.data

    let operationContent

    switch (status) {
      case 'created':
        operationContent = <CreatedOperation {...data.data} />
        break
      case 'cancelled':
      case 'rejected':
      case 'expired':
        operationContent = <CancelledOperation {...data.data} />
        break
      case 'paid':
        operationContent = <ConfirmedOperation {...data.data} />
        break
      case 'manually_approved':
      case 'auto_approved':
        operationContent = <ExecutedOperation {...data.data} />
        break
    }

    const title = (
      <Paragraph>
        <Text>Оплата заказа</Text>
        <Text style={{ display: 'block', fontSize: 8 }}>{id}</Text>
      </Paragraph>
    )

    content = (
      <Row align="stretch" justify="space-around">
        <Col style={{ marginTop: 40 }}>
          <Card style={{ minWidth: 400 }} title={title}>
            {operationContent}
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    <Layout className="layout">
      <Content className="content">{content}</Content>
    </Layout>
  )
}
