import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useCan,
  useTranslate,
} from '@refinedev/core'
import { List, useTable } from '@refinedev/antd'
import UsersTable from './list_table'
import { IUser } from 'interfaces'
import { Form, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps, searchFormProps, filters } = useTable<IUser>({
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { search } = params

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })

      return filters
    },

    pagination: {
      pageSize: 20,
    },
  })

  const { data: canCreateUser } = useCan({
    resource: 'users',
    action: 'create',
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List canCreate={canCreateUser?.can}>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          search: getDefaultFilter('search', filters),
        }}
      >
        <Form.Item name="search">
          <Input
            placeholder={t('users.filter.search.placeholder')}
            prefix={<SearchOutlined />}
            allowClear
          />
        </Form.Item>
      </Form>
      <UsersTable tableProps={tableProps} />
    </List>
  )
}
