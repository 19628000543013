import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Checkbox, Form, Input, InputNumber, Select } from 'antd'

import { ITeamDeposit, ITeam } from 'interfaces'

const { TextArea } = Input

export const TeamDepositCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<ITeamDeposit>()

  const { selectProps: teamSelectProps } = useSelect<ITeam>({
    resource: 'teams',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  let options = []

  options.push({
    label: t('team_deposits.kind.income'),
    value: 'income',
  })
  options.push({
    label: t('team_deposits.kind.outcome'),
    value: 'outcome',
  })

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" initialValues={{ currency: 'RUB' }}>
        <Form.Item
          name="team_id"
          label={t('team_deposits.fields.team')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...teamSelectProps} />
        </Form.Item>
        <Form.Item
          label={t('team_deposits.form.amount.label')}
          name="amount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="kind"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            options={options}
            placeholder={t('team_deposits.filter.kind.placeholder')}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={t('team_deposits.fields.comment')}
          name="comment"
        >
          <TextArea rows={4} allowClear />
        </Form.Item>
      </Form>
    </Create>
  )
}
