import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { useTable } from '@refinedev/antd'
import { Card } from 'antd'
import { IAccessToken } from 'interfaces'
import { AccessTokensTable } from '../access_tokens/list_table'

export const ActiveSessions: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps } = useTable<IAccessToken>({
    syncWithLocation: false,
    pagination: {
      pageSize: 10000,
    },
    resource: 'access_tokens',
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'eq',
          value: 'verified',
        },
        {
          field: 'kind',
          operator: 'eq',
          value: 'session',
        },
        {
          field: 'except_current',
          operator: 'eq',
          value: true,
        },
      ],
    },
  })

  return (
    <Card style={{ marginTop: 12 }} title={t('profile.titles.sessions')}>
      <AccessTokensTable tableProps={tableProps} showActions={true} />
    </Card>
  )
}
