import { useState } from 'react'

import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Modal, Input, Typography, Spin, Divider, Card } from 'antd'
import { useCreate, useInvalidate, useShow, useTranslate } from '@refinedev/core'

import DateField from 'components/DateField'
import { IFeedItem, IOperation } from 'interfaces'
import { OperationStatus } from 'pages/cashin/OperationStatus'
import { formattedCardNumber, formattedCurrencyAmount } from 'utils'

const { TextArea } = Input
const { Text } = Typography

interface MutateOperationResponse {
  success: boolean
}

const OperationForm: React.FC<{
  record: IOperation
  feed_item: IFeedItem
  onCreate: ({ comment }: { comment: string }) => void
  isCreating: boolean
}> = ({ record, onCreate, feed_item, isCreating }) => {
  const t = useTranslate()
  const [comment, setComment] = useState('')

  return (
    <>
      <Card>
        <Text>
          Операция по карте{' '}
          {formattedCardNumber(feed_item.feed.payment_requisite.card_number)} на сумму{' '}
          {formattedCurrencyAmount(feed_item.amount)}
        </Text>
        <DateField value={feed_item.sms.sent_at} />
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.045)',
            padding: '8px',
            borderRadius: '8px',
            marginTop: '16px',
          }}
        >
          <Text>{feed_item.sms.body}</Text>
        </div>
      </Card>
      <Divider orientation="center">X</Divider>
      <Card>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Text>
            Заказ{' '}
            <Text copyable={{ text: record.id, tooltips: `Copy ${record.id}` }}>
              {record.id.slice(-8)}
            </Text>{' '}
            на сумму {formattedCurrencyAmount(record.amount)}
          </Text>
          <OperationStatus status={record.status} />
        </div>
        <div style={{ marginTop: 10 }}>
          <Text>
            Заказ был создан: <DateField value={record.created_at} />
          </Text>
        </div>
        {record.client_masked_card_number && (
          <div style={{ marginTop: 10 }}>
            <Text>Оплата совершена с карты: {record.client_masked_card_number}</Text>
          </div>
        )}
      </Card>
      <ul style={{ marginTop: '16px', marginBottom: '16px' }}>
        {record.available_actions.includes('manually_approve') ? (
          <li>После этого действия статус заказа изменится на "Подтвержден руками"</li>
        ) : (
          <li>После этого действия статус заказа не изменится</li>
        )}
        {feed_item.amount !== record.amount && (
          <li>
            После этого действия, сумма заказа поменяется на сумму из СМС{' '}
            {formattedCurrencyAmount(feed_item.amount)}
          </li>
        )}
        {feed_item.feed.payment_requisite.card_number !==
          record.payment_requisite.card_number && (
          <li>
            После этого действия, реквизит в заказе сменится на реквизит из СМС{' '}
            {formattedCardNumber(record.payment_requisite.card_number)}&nbsp;→{' '}
            {formattedCardNumber(feed_item.feed.payment_requisite.card_number)}
          </li>
        )}
      </ul>
      <Form layout="vertical">
        <Form.Item>
          <TextArea
            placeholder={t('operations.form.comment.placeholder')}
            rows={4}
            allowClear
            defaultValue={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>

        <Button
          loading={isCreating}
          htmlType="submit"
          onClick={() => onCreate({ comment })}
          type="primary"
          icon={<CheckCircleOutlined />}
        >
          {t('operation_verifications.titles.create')}
        </Button>
      </Form>
    </>
  )
}

export const OperationActions: React.FC<{ id: string; feed_item: IFeedItem }> = ({
  id,
  feed_item,
}) => {
  const t = useTranslate()
  const { queryResult, setShowId } = useShow<IOperation>({ resource: 'operations' })
  const { data, isLoading } = queryResult
  const record = data?.data

  const { mutate } = useCreate<MutateOperationResponse>()

  const invalidate = useInvalidate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  const showModal = () => {
    setShowId(id)
    invalidate({
      resource: 'operations',
      invalidates: ['detail'],
      id,
    })
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onCreate = ({ comment }: { comment: string }) => {
    setIsCreating(true)
    mutate(
      {
        resource: 'operation_verifications',
        values: {
          sms_id: feed_item.sms.id,
          operation_id: id,
          comment,
        },
      },
      {
        onSuccess: (data, variables, context) => {
          setIsCreating(false)
          invalidate({
            resource: 'feed_items',
            invalidates: ['detail'],
            id: feed_item.id,
          })
          invalidate({
            resource: 'operations',
            invalidates: ['list'],
          })
          closeModal()
        },
        onError: (error, variables, context) => {
          setIsCreating(false)
        },
      }
    )
  }

  return (
    <>
      <Button size="middle" onClick={showModal}>
        {t('feed_items.titles.approve_operation')}
      </Button>
      <Modal
        open={isModalOpen}
        title={t('operation_verifications.titles.match')}
        onCancel={closeModal}
        footer={null}
      >
        {isLoading && (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Spin tip="Loading" size="large" />
          </div>
        )}
        {isModalOpen && !isLoading && record && (
          <OperationForm
            feed_item={feed_item}
            onCreate={onCreate}
            record={record}
            isCreating={isCreating}
          />
        )}
      </Modal>
    </>
  )
}
