import React from 'react'
import { cardColor } from 'interfaces'
import { Tag } from "antd";

export const CardColorMap = {
  green: "cards.colors.green",
  red: "cards.colors.red",
}

const CardColorAndNumber: React.FC<{color: cardColor, number: string}> = ({color, number}) => {
  const CardColorMap = {
    green: 'green',
    red: 'red',
  }

  return(
    <Tag
      color={ CardColorMap[color] }
      style={{ fontSize: 10 }}
    >
      { number }
    </Tag>
  )
}

export default CardColorAndNumber
