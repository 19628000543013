import React from 'react'
import { IResourceComponentsProps, useCan, useShow, useTranslate } from '@refinedev/core'
import { EditButton, Show, TextField } from '@refinedev/antd'
import { Col, Descriptions, Row, Space, Statistic, Tabs, TabsProps } from 'antd'

import { IScoringClient } from 'interfaces'
import { CardsList } from './CardsList'
import { OperationsList } from './OperationsList'
import { formattedCurrencyAmount } from 'utils'

import CreateOutcomeOperationButton from 'components/CreateOutcomeOperationButton'

export const ScoringClientShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IScoringClient>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { data: canListCards } = useCan({
    resource: 'payment_requisites',
    action: 'list',
  })

  const { data: canListOperations } = useCan({
    resource: 'operations',
    action: 'list',
  })

  const headerButtons = () => {
    if (!record) {
      return
    }
    return (
      <>
        <EditButton
          resource="merchants"
          recordItemId={record.id}
          title={t('buttons.edit')}
        />
      </>
    )
  }

  let tabsItems: TabsProps['items'] = []
  canListCards?.can &&
    tabsItems.push({
      key: '1',
      label: t('scoring/clients.titles.cards'),
      children: record && !isLoading ? <CardsList merchantId={record.merchant_id} scoringClientId={record.id} /> : null,
    })
  canListOperations?.can &&
    tabsItems.push({
      key: '2',
      label: t('scoring/clients.titles.operations'),
      children: record && !isLoading ? <OperationsList merchantId={record.merchant_id} scoringClientId={record.id} /> : null,
    })

  return (
    <>
      <Show
        isLoading={isLoading}
        title={record?.id || ''}
        headerButtons={headerButtons}
      >
        <Row>
          <Col span={12}>
            <Descriptions column={1}>
              <Descriptions.Item label={t('scoring/clients.fields.client_id')}>
                {record?.client_id}
              </Descriptions.Item>
              <Descriptions.Item label={t('scoring/clients.fields.merchant')}>
                {record?.merchant?.name}
              </Descriptions.Item>
              <Descriptions.Item label={t('scoring/clients.fields.currency')}>
                {record?.currency}
              </Descriptions.Item>
              <Descriptions.Item label={t('scoring/clients.fields.color')}>
                {record?.color}
              </Descriptions.Item>
              <Descriptions.Item label={t('scoring/clients.fields.sum_approved_amount')}>
                <TextField value={formattedCurrencyAmount(record?.sum_approved_amount, 0, record?.currency || 'RUB')} />
              </Descriptions.Item>
              <Descriptions.Item label={t('scoring/clients.fields.sum_approved_count')}>
                {record?.sum_approved_count}
              </Descriptions.Item>
              <Descriptions.Item label={t('scoring/clients.fields.sum_all_count')}>
                {record?.sum_all_count}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Show>
      <Tabs defaultActiveKey="1" items={tabsItems} />
    </>
  )
}
