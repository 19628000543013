import React from 'react'
import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'
import { List, useSelect, useTable } from '@refinedev/antd'
import { IAgreement, IMerchant, IPartner, IAgent, IGateway } from 'interfaces'
import AgreementsTable from './list_table'
import { Form, Select } from 'antd'

export const AgreementsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, filters } = useTable<IAgreement>({
    resource: 'billing/agreements',
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const {
        merchant_id,
        partner_id,
        agent_id,
        gateway_id
      } = params

      filters.push({
        field: 'merchant_id',
        operator: 'eq',
        value: merchant_id,
      })

      filters.push({
        field: 'partner_id',
        operator: 'eq',
        value: partner_id,
      })

      filters.push({
        field: 'agent_id',
        operator: 'eq',
        value: agent_id,
      })

      filters.push({
        field: 'gateway_id',
        operator: 'eq',
        value: gateway_id,
      })

      return filters
    },
  })

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const { selectProps: partnersSelectProps } = useSelect<IPartner>({
    resource: 'partners',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  const { selectProps: agentsSelectProps } = useSelect<IAgent>({
    resource: 'agents',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  const { selectProps: gatewaysSelectProps } = useSelect<IGateway>({
    resource: 'gateways',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    <List>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          merchant_id: getDefaultFilter('merchant_id', filters),
          partner_id: getDefaultFilter('partner_id', filters),
          agent_id: getDefaultFilter('agent_id', filters),
          gateway_id: getDefaultFilter('gateway_id', filters),
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="merchant_id"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)'
            }}
          >
            <Select
              {...merchantsSelectProps}
              allowClear
              showSearch
              placeholder={t('agreements.filter.merchant.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            name="partner_id"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)'            
            }}
          >
            <Select
              {...partnersSelectProps}
              allowClear
              showSearch
              placeholder={t('agreements.filter.partner.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="agent_id"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)'
            }}
          >
            <Select
              {...agentsSelectProps}
              allowClear
              showSearch
              placeholder={t('agreements.filter.agent.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>     
          <Form.Item
            name="gateway_id"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)'
            }}
          >
            <Select
              {...gatewaysSelectProps}
              allowClear
              showSearch
              placeholder={t('agreements.filter.gateway.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>        
        </div>        
      </Form>
      <AgreementsTable tableProps={tableProps}></AgreementsTable>
    </List>
  )
}
