import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import { Show } from '@refinedev/antd'
import { Space, Typography } from 'antd'
import { IFeedItem } from 'interfaces'
import { formattedCardNumber, formattedCurrencyAmount } from 'utils'

import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { CreditCardOutlined, FieldTimeOutlined, HomeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import DateField from 'components/DateField'
import { MatchedOperations } from './matched_operations'
import { FeedItemProblems } from 'components/FeedItemProblems'

const { Text } = Typography

export const FeedItemShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IFeedItem>()
  const { data, isLoading } = queryResult

  const feed_item = data?.data
  const feed = feed_item?.feed

  const breadcrumbs = [
    {
      label: 'Смена',
      href: `/shifts/show/${feed?.shift.id}`,
      icon: <FieldTimeOutlined />,
    },
    {
      label: feed?.payment_requisite?.card_number
        ? formattedCardNumber(feed?.payment_requisite?.card_number)
        : '',
      href: `/feeds/show/${feed?.id}`,
      icon: <CreditCardOutlined />,
    },
  ]

  const breadcrumb = (
    <AntdBreadcrumb>
      <AntdBreadcrumb.Item>
        <Link to="/">
          <HomeOutlined />
        </Link>
      </AntdBreadcrumb.Item>
      {breadcrumbs.map(({ label, icon, href }) => {
        return (
          <AntdBreadcrumb.Item key={label}>
            {icon}
            {href ? <Link to={href}>{label}</Link> : <span>{label}</span>}
          </AntdBreadcrumb.Item>
        )
      })}
    </AntdBreadcrumb>
  )

  return (
    <>
      <Show
        breadcrumb={breadcrumb}
        isLoading={isLoading}
        // contentProps={{ style: { display: 'none' } }}
        //@ts-ignore
        headerButtons={feed_item && <FeedItemProblems problems={feed_item.problems} />}
        title={
          feed && (
            <Space direction="horizontal">
              <Text>
                Поиск заказа на сумму:&nbsp;
                {formattedCurrencyAmount(
                  feed_item.amount,
                  2,
                  feed.shift.partner.currency
                )}
                , &nbsp;
              </Text>
              <DateField
                value={feed_item.sms.sent_at || feed_item.sms.created_at}
                style={{ verticalAlign: 'middle' }}
              />
            </Space>
          )
        }
      >
        {feed_item && <Text>{feed_item.sms.body}</Text>}
      </Show>
      {feed_item && (
        <div style={{ marginTop: '8px' }}>
          <MatchedOperations feed_item={feed_item} />
        </div>
      )}
    </>
  )
}
