import { useTranslate } from '@refinedev/core'
import { Button, Empty, Spin } from 'antd'

export const FeedEmpty: React.FC<{ loading: boolean; onButtonClick: () => void }> = ({
  loading,
  onButtonClick,
}) => {
  const t = useTranslate()

  return loading ? (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <Spin size="small" />
    </div>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{ height: 60 }}
      description={<span>{t('feeds.titles.empty')}</span>}
    >
      <Button type="primary" onClick={onButtonClick}>
        {t('feeds.titles.add_item')}
      </Button>
    </Empty>
  )
}
