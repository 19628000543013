import { IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'

import { AgreementForm } from './form'

import { IAgreement } from 'interfaces'

export const AgreementCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<IAgreement>({
    resource: 'billing/agreements',
    action: 'create',
    redirect: 'list',
  })

  return (
    <Create saveButtonProps={saveButtonProps}>
      <AgreementForm form={form} formProps={formProps} initialValues={{}} />
    </Create>
  )
}
