import { IResourceComponentsProps, useTranslate } from '@refinedev/core'

import { Table, TableProps, Tag } from 'antd'
import { IAccessToken } from 'interfaces'
import { DeleteButton } from '@refinedev/antd'
import DateField from 'components/DateField'

export const ApiTokensTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IAccessToken>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table {...tableProps} rowKey="id" pagination={false}>
      <Table.Column<IAccessToken>
        dataIndex="token"
        ellipsis
        key="token"
        title={t('api_tokens.fields.token')}
        render={(value) => `…${value}`}
      />
      <Table.Column<IAccessToken>
        dataIndex="created_at"
        ellipsis
        key="created_at"
        title={t('api_tokens.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IAccessToken>
        dataIndex="ip_addresses_whitelist"
        ellipsis
        key="ip_addresses_whitelist"
        title={t('api_tokens.fields.ip_addresses_whitelist')}
        render={(_, record) =>
          record.ip_addresses_whitelist &&
          record.ip_addresses_whitelist.map((item) => <Tag>{item}</Tag>)
        }
      />
      <Table.Column<IAccessToken>
        dataIndex="expires_at"
        ellipsis
        key="expires_at"
        title={t('api_tokens.fields.expires_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IAccessToken>
        dataIndex="actions"
        ellipsis
        key="actions"
        render={(_, record) => (
          <DeleteButton size="middle" recordItemId={record.id} resource="access_tokens">
            {t('api_tokens.actions.revoke')}
          </DeleteButton>
        )}
      />
    </Table>
  )
}
