import React from 'react'
import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useCan,
  usePermissions,
  useTranslate,
} from '@refinedev/core'
import { List, useSelect, useTable } from '@refinedev/antd'
import { IMerchant, IPartner, ITeam, IReport } from 'interfaces'
import ReportsTable from './list_table'
import { Form, Select } from 'antd'

export const ReportsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, filters } = useTable<IReport>({
    resource: 'reports',
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { merchant_id, partner_id, team_id, subject } = params

      filters.push({
        field: 'merchant_id',
        operator: 'eq',
        value: merchant_id,
      })

      filters.push({
        field: 'partner_id',
        operator: 'eq',
        value: partner_id,
      })

      filters.push({
        field: 'team_id',
        operator: 'eq',
        value: team_id,
      })

      filters.push({
        field: 'subject',
        operator: 'eq',
        value: subject,
      })

      return filters
    },
  })
  const t = useTranslate()

  const { data: canFilterBySubject } = useCan({
    resource: 'reports',
    action: 'filterBySubject',
  })

  const { data: canFilterByMerchant } = useCan({
    resource: 'merchants',
    action: 'list',
  })

  const { data: canFilterByPartner } = useCan({
    resource: 'partners',
    action: 'list',
  })

  const { data: canFilterByTeam } = useCan({
    resource: 'teams',
    action: 'list',
  })

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
    queryOptions: {
      enabled: !!canFilterByMerchant?.can,
    },
  })

  const { selectProps: partnersSelectProps } = useSelect<IPartner>({
    resource: 'partners',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
    queryOptions: {
      enabled: !!canFilterByPartner?.can,
    },
  })

  const { selectProps: teamsSelectProps } = useSelect<ITeam>({
    resource: 'teams',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 10000,
    },
    queryOptions: {
      enabled: !!canFilterByTeam?.can,
    },
  })

  const { data } = usePermissions<string>({
    v3LegacyAuthProviderCompatible: true,
  })

  return (
    <List>
      <Form
        {...searchFormProps}
        layout="horizontal"
        size={'middle'}
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          subject: getDefaultFilter('subject', filters),
          merchant_id: getDefaultFilter('merchant_id', filters),
        }}
      >
        <Form.Item style={{ marginBottom: 8 }}>
          {canFilterBySubject?.can && (
            <Form.Item
              name="subject"
              style={{
                marginBottom: 8,
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                marginRight: 16,
              }}
            >
              <Select
                allowClear
                showSearch
                options={[
                  {
                    label: t('reports.subjects.shift'),
                    value: 'shift',
                  },
                  {
                    label: t('reports.subjects.merchant'),
                    value: 'merchant',
                  },
                  {
                    label: t('reports.subjects.partner'),
                    value: 'partner',
                  },
                  {
                    label: t('reports.subjects.team_summary'),
                    value: 'team_summary',
                  },
                  {
                    label: t('reports.subjects.finance_alfa'),
                    value: 'finance_alfa',
                  },
                  {
                    label: t('reports.subjects.finance_beta'),
                    value: 'finance_beta',
                  },
                  {
                    label: t('reports.subjects.finance_range'),
                    value: 'finance_range',
                  },
                ]}
                placeholder={t('reports.filter.subject.placeholder')}
                filterOption={(input, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
          {canFilterByMerchant?.can && (
            <Form.Item
              name="merchant_id"
              style={{
                marginBottom: 8,
                display: 'inline-block',
                width: 'calc(50% - 8px)',
              }}
            >
              <Select
                {...merchantsSelectProps}
                allowClear
                showSearch
                placeholder={t('reports.filter.merchant.placeholder')}
                filterOption={(input, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
          {canFilterByPartner?.can && (
            <Form.Item
              name="partner_id"
              style={{
                marginBottom: 8,
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                marginRight: 16,
              }}
            >
              <Select
                {...partnersSelectProps}
                allowClear
                showSearch
                placeholder={t('reports.filter.partner.placeholder')}
                filterOption={(input, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
          {canFilterByTeam?.can && (
            <Form.Item
              name="team_id"
              style={{
                marginBottom: 8,
                display: 'inline-block',
                width: 'calc(50% - 8px)',
              }}
            >
              <Select
                {...teamsSelectProps}
                allowClear
                showSearch
                placeholder={t('reports.filter.team.placeholder')}
                filterOption={(input, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
        </Form.Item>
      </Form>
      <ReportsTable tableProps={tableProps}></ReportsTable>
    </List>
  )
}
