import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

require('dayjs/locale/ru')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('ru')

const timezonedDayjs = (...args: any[]) => {
  return dayjs(...args).tz()
}

const timezonedUnix = (value: number) => {
  return dayjs.unix(value).tz()
}

timezonedDayjs.unix = timezonedUnix

export default timezonedDayjs
