import React from 'react'
import { IResourceComponentsProps, useApiUrl, useCustom, useList } from '@refinedev/core'
import { List } from '@refinedev/antd'
import { Table, Typography } from 'antd'
import { IShiftsSummaryDashboard, IAgreement } from 'interfaces'

import { formattedCurrencyAmount } from 'utils'

import './styles.css'

export const ShiftsSummary: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl()

  const { data: agreementsData } = useList<IAgreement>({
    resource: 'billing/agreements',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
    filters: [
      {
        field: 'actual',
        operator: 'eq',
        value: true,
      },
      {
        field: 'types',
        operator: 'eq',
        value: ['Billing::MerchantAgreement', 'Billing::PartnerAgreement'],
      },
    ],
  })

  const agreements = agreementsData?.data

  const { data } = useCustom<IShiftsSummaryDashboard>({
    url: `${apiUrl}/shifts/summary`,
    method: 'get',
    queryOptions: {
      enabled: !!agreements?.length,
    },
  })

  const dataSource = data?.data

  let columns: any[] = []
  columns.push({
    title: 'Партнёр / Мерчант',
    dataIndex: 'partner',
    key: 'partner',
    fixed: 'left',
    width: 150,
  })
  columns.push({
    title: 'Всего',
    dataIndex: 'total',
    key: 'total',
    align: 'right',
    fixed: 'left',
    width: 120,
    render: (value: string) => (
      <Typography.Text ellipsis>{formattedCurrencyAmount(value, 0)}</Typography.Text>
    ),
  })
  columns.push({
    title: '% команды',
    dataIndex: 'commission',
    key: 'commission',
    align: 'right',
    fixed: 'left',
    width: 120,
    render: (value: string, record: any) => (
      <Typography.Text ellipsis>{formattedCurrencyAmount(value, 0)}</Typography.Text>
    ),
  })
  columns.push({
    title: 'Команда должна',
    dataIndex: 'partner_netto',
    key: 'partner_netto',
    align: 'right',
    fixed: 'left',
    width: 120,
    render: (value: string, record: any) => (
      <Typography.Text ellipsis>{formattedCurrencyAmount(value, 0)}</Typography.Text>
    ),
  })

  const merchants = dataSource?.merchants || []
  // const merchants = dataSource?.merchants[0] ? [dataSource?.merchants[0]] : []

  merchants.forEach((merchant) => {
    const agreementPercentString = agreements?.find(
      (agreement) =>
        agreement.type === 'merchant' && agreement.merchant.id === merchant.id
    )?.percent
    const agreementPercent = agreementPercentString ? Number(agreementPercentString) : 0

    columns.push({
      title: `${merchant.name} ${agreementPercent}%`,
      dataIndex: merchant.id,
      key: merchant.id,
      align: 'right',
      width: 200,
      render: (value: any) => {
        let result = formattedCurrencyAmount(value?.amount, 0)
        if (value?.commission) {
          result = `${result} / ${formattedCurrencyAmount(value?.commission, 0)}`
        }

        return <Typography.Text ellipsis>{result}</Typography.Text>
      },
    })
  })

  let items: any[] = []

  dataSource?.summary.forEach((item, i) => {
    let obj: any = { key: i, total: 0, commission: 0 }

    obj['partner'] = item.partner.name
    Object.keys(item.merchants).forEach((merchantId) => {
      const agreementPercentString = agreements?.find(
        (agreement) =>
          agreement.type === 'partner' &&
          agreement.partner.id === item.partner.id &&
          agreement.merchant.id === merchantId
      )?.percent
      const agreementPercent = agreementPercentString
        ? Number(agreementPercentString)
        : 1.9

      // @ts-ignore
      const merchantData = item.merchants[merchantId]
      obj[merchantId] = {
        amount: Number(merchantData?.income?.amount) || 0,
        commission:
          ((Number(merchantData?.income?.amount) || 0) / 100) * agreementPercent,
      }
      obj['total'] = obj['total'] + obj[merchantId]['amount']
      obj['commission'] = obj['commission'] + obj[merchantId]['commission']
    })
    obj['partner_netto'] = obj['total'] - obj['commission']
    items.push(obj)
  })

  const turnover = items.reduce((accumulator, item) => accumulator + item.total, 0)
  const partnersCommission = items.reduce(
    (accumulator, item) => accumulator + item.commission,
    0
  )
  const partnersNetto = items.reduce(
    (accumulator, item) => accumulator + item.partner_netto,
    0
  )

  //////

  // Total
  let partnerItem: any = {
    partner: <strong>Вознаграждение команд</strong>,
    total: undefined,
    commission: partnersCommission,
    partner_netto: partnersNetto,
  }

  merchants.forEach((merchant) => {
    partnerItem[merchant.id] = {
      amount: items.reduce(
        (accumulator, item) => accumulator + (item[merchant.id]?.['commission'] || 0),
        0
      ),
    }
  })

  // Turnover
  let turnoverItem: any = {
    partner: <strong>Весь оборот</strong>,
    total: turnover,
    commission: undefined,
  }

  merchants.forEach((merchant) => {
    turnoverItem[merchant.id] = {
      amount: items.reduce((accumulator, item) => {
        return accumulator + (item[merchant.id]?.['amount'] || 0)
      }, 0),
    }
  })

  // Revenue
  let revenueItem: any = {
    partner: <strong>Выручка, % от оборота</strong>,
    total: 0,
  }

  merchants.forEach((merchant) => {
    const agreementPercentString = agreements?.find(
      (agreement) =>
        agreement.type === 'merchant' && agreement.merchant.id === merchant.id
    )?.percent
    const agreementPercent = agreementPercentString ? Number(agreementPercentString) : 0
    const amount = (turnoverItem[merchant.id]['amount'] / 100) * agreementPercent

    revenueItem['total'] = revenueItem['total'] + amount
    revenueItem[merchant.id] = {
      amount: amount,
    }
  })

  // Netto merchant
  let nettoMerchant: any = {
    partner: <strong>Должны мерчантам</strong>,
    total: turnoverItem['total'] - revenueItem['total'],
    commission: undefined,
  }

  merchants.forEach((merchant) => {
    nettoMerchant[merchant.id] = {
      amount: turnoverItem[merchant.id]['amount'] - revenueItem[merchant.id]['amount'],
    }
  })

  items.unshift(partnerItem)
  items.unshift(nettoMerchant)
  items.unshift(revenueItem)
  items.unshift(turnoverItem)

  return (
    <List title={'Саммари по активным сменам'} breadcrumb={false}>
      <Table
        rowClassName={(record, index) => `summary-${index}`}
        pagination={false}
        loading={false}
        style={{ marginTop: 16 }}
        scroll={{ x: '100%' }}
        columns={columns}
        dataSource={items}
        size="small"
      />
    </List>
  )
}
