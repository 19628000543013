import { useGetIdentity, useTranslate } from '@refinedev/core'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormProps,
  Modal,
  ModalProps,
  Radio,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
  Typography,
} from 'antd'
import { ICurrentUser, IMerchant } from 'interfaces'
import { timeZoneOptions } from 'utils'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { OperationStatusMap } from 'pages/cashin/OperationStatus'
import { useSelect } from '@refinedev/antd'
import { Link } from 'react-router-dom'

const ExportForm: React.FC<{ createFormProps: FormProps<{}> }> = ({
  createFormProps,
}) => {
  const t = useTranslate()

  const { data: user } = useGetIdentity<ICurrentUser>()

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const options: SelectProps['options'] = Object.keys(OperationStatusMap).map((key) => ({
    value: key,
    // @ts-ignore
    label: t(OperationStatusMap[key]),
  }))

  const initialMerchantId =
    merchantsSelectProps.options && merchantsSelectProps.options[0]?.value

  const initialValues = {
    statuses: ['manually_approved', 'auto_approved'],
    timezone: user?.timezone,
    created_by: 'all',
    merchant_id: initialMerchantId,
    range_column: 'created_at',
    amount_was_changed: false,
  }

  return user && initialMerchantId ? (
    <Form {...createFormProps} layout="vertical" initialValues={initialValues}>
      <Form.Item
        label={t('operations.export_form.merchant.placeholder')}
        name="merchant_id"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder={t('operations.export_form.merchant.placeholder')}
          allowClear
          showSearch
          options={merchantsSelectProps.options}
          filterOption={(input, option: any) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name={'range_column'}
        label={t('operations.export_form.range_column.label')}
      >
        <Radio.Group defaultValue={'created_at'}>
          <Space direction="horizontal">
            <Radio value="created_at" defaultChecked>
              {t('operations.export_form.created_at.label')}
            </Radio>
            <Radio value="approved_at">
              {t('operations.export_form.approved_at.label')}
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item
            label={t('operations.export_form.range.label')}
            name="range"
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker.RangePicker allowEmpty={[true, true]} locale={locale} />
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item
            name="timezone"
            label={t('operations.export_form.timezone.label')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              //@ts-ignore
              options={timeZoneOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={t('operations.export_form.statuses.label')} name="statuses">
        <Select
          mode="multiple"
          placeholder={t('operations.filter.status.placeholder')}
          options={options}
        />
      </Form.Item>
      <Row>
        <Col span={10}>
          <Form.Item name="created_by" label={t('operations.filter.created_by.label')}>
            <Select
              options={[
                { value: 'all', label: 'Все' },
                { value: 'api', label: 'Только по АПИ' },
                { value: 'manual', label: 'Только оператором' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={13} offset={1}>
          <Form.Item valuePropName="checked" name="amount_was_changed" label={' '}>
            <Checkbox>{t('operations.filter.amount_was_changed.label')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" size="large" htmlType="submit" loading={false} block>
          {t('operations.titles.export')}
        </Button>
      </Form.Item>
    </Form>
  ) : (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <Spin tip="Loading" size="large" />
    </div>
  )
}

const ExportModal: React.FC<{
  ready: boolean
  createModalProps: ModalProps
  createFormProps: FormProps<{}>
  onCancel: () => void
}> = ({ ready, createModalProps, createFormProps, onCancel }) => {
  const t = useTranslate()

  return (
    <Modal
      {...createModalProps}
      title={t('operations.titles.export')}
      width={600}
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      {createModalProps.open ? (
        ready ? (
          <Typography.Text>
            {t('operations.titles.export_ready')}{' '}
            <Link to={'/exports'}>{t('exports.exports')}</Link>
          </Typography.Text>
        ) : (
          <ExportForm createFormProps={createFormProps} />
        )
      ) : (
        <></>
      )}
    </Modal>
  )
}

export default ExportModal
