import React from 'react'
import { IResourceComponentsProps, useCan, useShow, useTranslate } from '@refinedev/core'
import { Show } from '@refinedev/antd'
import { Descriptions, Tabs, TabsProps } from 'antd'

import { IPaymentCascade } from 'interfaces'
import BooleanTag from 'components/BooleanTag'
import {
  formattedCorrectedPercent,
  formattedCurrencyAmount,
  formattedPercent,
} from 'utils'

export const PaymentCascadeShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IPaymentCascade>()
  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <>
      <Show isLoading={isLoading} title={record?.payment_system || ''} canEdit={true}>
        <Descriptions column={1}>
          <Descriptions.Item label={t('payment_cascades.fields.payment_system')}>
            {record?.payment_system}
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.kind')}>
            {record?.kind}
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.all_merchants')}>
            <BooleanTag value={record?.all_merchants} />
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.all_gateways')}>
            <BooleanTag value={record?.all_gateways} />
          </Descriptions.Item>
          <Descriptions.Item label={t('payment_cascades.fields.traffic_percentage')}>
            {record?.traffic_percentage}
          </Descriptions.Item>
        </Descriptions>
      </Show>
    </>
  )
}
