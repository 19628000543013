import React, { useEffect } from 'react'

import { IResourceComponentsProps, useShow, useInvalidate } from '@refinedev/core'

import { Show, EditButton } from '@refinedev/antd'
import { Tag } from 'antd'
import { IFeed } from 'interfaces'
import { formattedCardNumber, formattedCurrencyAmount, formattedAccountNumber, formattedPhone } from 'utils'
import { ItemsList } from './items_list'

import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { FieldTimeOutlined, HomeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

export const FeedShow: React.FC<IResourceComponentsProps> = () => {
  const invalidate = useInvalidate()

  const { queryResult } = useShow<IFeed>()
  const { data, isLoading } = queryResult

  const feed = data?.data

  const breadcrumbs = [
    {
      label: `Смена №${feed?.shift.number} (${feed?.shift.partner.name})`,
      href: `/shifts/show/${feed?.shift.id}`,
      icon: <FieldTimeOutlined />,
    },
  ]

  useEffect(() => {
    if (!feed) {
      return
    }
    const timer = setInterval(() => {
      invalidate({
        resource: 'feeds',
        invalidates: ['detail'],
        id: feed?.id,
      })
    }, 5000)
    return () => clearInterval(timer)
  }, [feed, invalidate])

  const breadcrumb = (
    <AntdBreadcrumb>
      <AntdBreadcrumb.Item>
        <Link to="/">
          <HomeOutlined />
        </Link>
      </AntdBreadcrumb.Item>
      {breadcrumbs.map(({ label, icon, href }) => {
        return (
          <AntdBreadcrumb.Item key={label}>
            {icon}
            {href ? <Link to={href}>{label}</Link> : <span>{label}</span>}
          </AntdBreadcrumb.Item>
        )
      })}
    </AntdBreadcrumb>
  )

  return (
    <>
      <Show
        //@ts-ignore
        breadcrumb={breadcrumb}
        isLoading={isLoading}
        contentProps={{ style: { display: 'none' } }}
        headerButtons={
          feed?.has_problems ? (
            <>
              <Tag color="red" style={{ fontSize: 14 }}>
                {`Есть проблемы (${feed.problems_count})`}
              </Tag>
              <EditButton size="small" />
            </>
          ) : (
            <>
              <EditButton size="small" />
            </>
          )
        }
        goBack={false}
        title={
          feed &&
          `${formattedCardNumber(feed.payment_requisite.card_number)} (${formattedPhone(
            feed.payment_requisite.phone)}) ${formattedAccountNumber(feed.payment_requisite.account)
              }, баланс ${formattedCurrencyAmount(
            feed.balance,
            2,
            feed.shift.partner.currency
          )}`
        }
      />
      {feed && <ItemsList feed={feed} />}
    </>
  )
}
