import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useNotification } from "@refinedev/core";
import { IPaymentRequisite } from 'interfaces'
import { formattedPhone } from 'utils'
import { CopyOutlined } from '@ant-design/icons'
import { Typography, Card, Button } from "antd";
import './styles.css'

const { Text, Paragraph, Title } = Typography

interface SbpProps {
  payment_requisite: IPaymentRequisite
}

export const Sbp: React.FC<SbpProps> = ({ payment_requisite }) => {
  const { open } = useNotification()
  const { sbp } = payment_requisite

  let bankCode

  switch (sbp.bank_name) {
    case 'Тиньков':
      bankCode = 'tink'
      break
    case 'Сбербанк':
      bankCode = 'sber'
      break
  }
  return (
    <Card className={`card ${bankCode}`} title={<Title level={5}>{sbp.bank_name}</Title>}>
      <Paragraph>
        <Text style={{ fontSize: 20 }}>{formattedPhone(sbp.phone)}</Text>
        <br />
        <Text style={{ fontSize: 20 }}>{sbp.holder_name}</Text>
      </Paragraph>
      <CopyToClipboard
        text={sbp.phone}
        onCopy={() => {
          open && open({ message: 'Номер скопирован', type: 'success' })
        }}
      >
        <Button
          style={{ paddingLeft: 0, paddingRight: 0 }}
          type="link"
          icon={<CopyOutlined />}
        >
          Скопировать номер
        </Button>
      </CopyToClipboard>
    </Card>
  )
}
