import { memo, useEffect, useState } from 'react'
import { EllipsisOutlined, MenuOutlined, ReloadOutlined } from '@ant-design/icons'
import { ShowButton, useTable, List } from '@refinedev/antd'

import {
  Button,
  ConfigProvider,
  Descriptions,
  Dropdown,
  MenuProps,
  Space,
  Table,
  Typography,
  theme,
  Form,
} from 'antd'

import {
  CrudFilters,
  getDefaultFilter,
  useApiUrl,
  useCustomMutation,
  useDelete,
  useInvalidate,
  useTranslate,
} from '@refinedev/core'

import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import DateField from 'components/DateField'

import { IFeed, IFeedItem, IFeedItemOperation } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { OperationStatus } from 'pages/cashin/OperationStatus'
import { createUseStyles } from 'react-jss'
import React from 'react'
import { NewItemModal } from './new_item_modal'
import { FeedEmpty } from './feed_empty'
import ScopesFilter from 'components/ScopesFilter'

const { Text } = Typography

interface MutateResponse {
  success: boolean
}

const Actions: React.FC<{
  record: IFeedItem
  onAddItemClick: (position: number) => void
}> = ({ record, onAddItemClick }) => {
  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<MutateResponse>()
  const { mutate: destroyMutate } = useDelete()
  const invalidate = useInvalidate()
  const t = useTranslate()

  const onFireEvent = (code: string) => {
    customMutate(
      {
        url: `${apiUrl}/feed_items/${record.id}/fire_event`,
        method: 'post',
        values: {
          code,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'feed_items',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const onDestroy = () => {
    destroyMutate(
      {
        resource: 'feed_items',
        id: record.id,
      },
      {
        onSuccess: () => {},
      }
    )
  }

  const onAddFeedItem = (position: number) => {
    onAddItemClick(position)
  }

  const items: MenuProps['items'] = record.actions.map((action) => ({
    label: t(`feed_items.actions.${action.code}`),
    key: action.code,
    danger: action.code === 'destroy',
  }))

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'add_feed_item_higher':
        onAddFeedItem(record.position)
        break
      case 'add_feed_item_lower':
        onAddFeedItem(record.position - 1)
        break
      case 'destroy':
        onDestroy()
        break
      default:
        onFireEvent(e.key)
        break
    }
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  return (
    <Dropdown menu={menuProps} trigger={['click']} disabled={!items.length}>
      <Button icon={<EllipsisOutlined />} size="small" />
    </Dropdown>
  )
}

const DragTable: React.FC<{
  feed: IFeed
  setIsModalOpen: any
  setPosition: any
}> = memo(({ feed, setIsModalOpen, setPosition }) => {
  const { tableProps, tableQueryResult, searchFormProps, filters } = useTable<IFeedItem>({
    resource: 'feed_items',
    syncWithLocation: true,

    pagination: {
      pageSize: 25,
    },

    filters: {
      permanent: [
        {
          field: 'feed_id',
          operator: 'eq',
          value: feed.id,
        },
      ],
    },

    onSearch: (params) => {
      // @ts-ignore
      const { scope } = params
      const cardsFilters: CrudFilters = []

      cardsFilters.push({
        field: 'scope',
        operator: 'eq',
        value: scope,
      })

      return cardsFilters
    },
  })

  const isLoading = tableQueryResult.isLoading
  const remoteData = tableQueryResult.data?.data
  const total = tableQueryResult.data?.total
  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<MutateResponse>()
  const t = useTranslate()
  const { token } = theme.useToken()
  const [data, setData] = useState<IFeedItem[]>([])
  const invalidate = useInvalidate()

  const finalStatus = (status: string): boolean =>
    ['matched', 'no_order_needed'].includes(status)

  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string
  }

  useEffect(() => {
    //@ts-ignore
    remoteData && setData(remoteData)
  }, [remoteData])

  const useStyles = createUseStyles({
    greenText: {
      color: token.colorSuccessText,
    },
    redText: {
      color: token.colorErrorText,
    },
    green: {
      backgroundColor: token.colorWarningBg,
      '&:hover td': {
        backgroundColor: `${token.colorWarningBg} !important`,
      },
    },
  })

  const styles = useStyles()

  const onAddItemClick = (position: number) => {
    setPosition(position)
    setIsModalOpen(true)
  }

  const onEmptyAdd = () => {
    setPosition(undefined)
    setIsModalOpen(true)
  }

  const updatePosition = (id: string, position: number) => {
    customMutate(
      {
        url: `${apiUrl}/feed_items/${id}`,
        method: 'patch',
        values: {
          position: position,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'feed_items',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over) return
    if (active.id !== over.id) {
      setData((previous: IFeedItem[]) => {
        const activeIndex = data.findIndex((item) => item.id === active.id)
        const overIndex = data.findIndex((item) => item.id === over?.id)
        return arrayMove(previous, activeIndex, overIndex)
      })
      const activeItem = data.find((item) => item.id === active.id)
      const overItem = data.find((item) => item.id === over.id)
      updatePosition(activeItem!.id, overItem!.position)
    }
  }

  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    }
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === 'sort') {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: 'none', cursor: 'move' }}
                  {...listeners}
                />
              ),
            })
          }
          return child
        })}
      </tr>
    )
  }

  return (
    <List title={<></>} breadcrumb={false}>
      <Form
        {...searchFormProps}
        layout="horizontal"
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          scope: getDefaultFilter('scope', filters, 'eq'),
        }}
      >
        {
          // @ts-ignore
          tableQueryResult.data?.scopes && (
            <Form.Item name="scope">
              <ScopesFilter
                // @ts-ignore
                scopes={tableQueryResult.data?.scopes}
                resource={'feed_items'}
              />
            </Form.Item>
          )
        }
      </Form>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          items={data.map((item: any) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <ConfigProvider
            renderEmpty={() => (
              <FeedEmpty onButtonClick={onEmptyAdd} loading={isLoading} />
            )}
          >
            <Table
              {...tableProps}
              loading={isLoading}
              components={
                data.length
                  ? {
                      body: {
                        row: Row,
                      },
                    }
                  : {}
              }
              dataSource={data}
              pagination={{
                ...tableProps.pagination,
                position: ['bottomCenter'],
                size: 'small',
              }}
              rowKey="id"
              size="middle"
              rowClassName={(record, index) =>
                record.problems.length || !finalStatus(record.status) ? styles.green : ''
              }
              footer={() => (
                <Space direction="vertical">
                  {/* {total && total > data.length && (
                    <Button style={{ width: '100%' }}>
                      Load more
                    </Button>
                  )} */}
                  <Descriptions column={1}>
                    <Descriptions.Item label={t('feeds.fields.start_balance')}>
                      {feed &&
                        formattedCurrencyAmount(
                          feed.start_balance,
                          2,
                          feed.shift.partner.currency
                        )}
                    </Descriptions.Item>
                  </Descriptions>
                </Space>
              )}
              title={() => (
                <Space>
                  <Descriptions column={1}>
                    {feed.end_balance ? (
                      <Descriptions.Item label={t('feeds.fields.end_balance')}>
                        {feed &&
                          formattedCurrencyAmount(
                            feed.end_balance,
                            2,
                            feed.shift.partner.currency
                          )}
                      </Descriptions.Item>
                    ) : (
                      <Descriptions.Item label={t('feeds.fields.balance')}>
                        {feed &&
                          formattedCurrencyAmount(
                            feed.balance,
                            2,
                            feed.shift.partner.currency
                          )}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={() => {
                      invalidate({
                        resource: 'feed_items',
                        invalidates: ['list'],
                      })
                    }}
                  >
                    Reload
                  </Button>
                </Space>
              )}
            >
              <Table.Column<IFeedItem> dataIndex="sort" key="sort" />
              <Table.Column<IFeedItem>
                dataIndex="status"
                key="status"
                render={(value) => t(`feed_items.statuses.${value}`)}
              />
              <Table.Column<IFeedItem>
                dataIndex="amount"
                align="right"
                key="amount"
                width={120}
                title={t('feed_items.fields.amount')}
                render={(value, record) => (
                  <Text
                    ellipsis
                    className={record.sign > 0 ? styles.greenText : ''}
                    copyable={{ text: value }}
                  >
                    {formattedCurrencyAmount(value, 2, feed.shift.partner.currency)}
                  </Text>
                )}
              />
              <Table.Column<IFeedItem>
                dataIndex="balance"
                width={120}
                align="right"
                key="balance"
                title={t('feed_items.fields.balance')}
                render={(value, record) => (
                  <Text className={record.balance_chained ? '' : styles.redText} ellipsis>
                    {formattedCurrencyAmount(value, 2, feed.shift.partner.currency)}
                  </Text>
                )}
              />
              <Table.Column<IFeedItem>
                width={200}
                dataIndex={'operation_time'}
                key="send_at"
                title={t('feed_items.fields.operation_time')}
                render={(_, record) => (
                  <DateField
                    value={record.operation_time || record.sms?.sent_at}
                    ellipsis
                  />
                )}
              />
              <Table.Column<IFeedItem>
                dataIndex={'body'}
                key="body"
                title={t('feed_items.fields.body')}
                render={(_, record) => <Text>{record.body || record.sms?.body}</Text>}
              />
              <Table.Column<IFeedItem>
                width={200}
                dataIndex={'operation'}
                key="operation"
                title={t('feed_items.fields.operation')}
                render={(value: IFeedItemOperation, record) =>
                  value.id ? (
                    <Space>
                      <OperationStatus status={value.status} />
                      <Text
                        copyable={{
                          text: value.id,
                          tooltips: [t('feed_items.tooltips.copy_operation_id')],
                        }}
                      ></Text>
                    </Space>
                  ) : ['waiting_for_receipt', 'not_matched'].includes(record.status) ? (
                    <ShowButton
                      resource="feed_items"
                      type="link"
                      size="small"
                      recordItemId={record.id}
                    >
                      {t('feed_items.titles.match_operation')}
                    </ShowButton>
                  ) : (
                    t('feed_items.titles.no_operation_required')
                  )
                }
              />
              <Table.Column<IFeedItem>
                width={30}
                dataIndex={'actions'}
                key="actions"
                render={(_, record) => (
                  <Actions record={record} onAddItemClick={onAddItemClick} />
                )}
              />
            </Table>
          </ConfigProvider>
        </SortableContext>
      </DndContext>
    </List>
  )
})

export const ItemsList: React.FC<{ feed: IFeed }> = ({ feed }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [position, setPosition] = useState<number | undefined>(undefined)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onSuccess = () => {
    closeModal()
  }

  return (
    <>
      <NewItemModal
        open={isModalOpen}
        onCancel={closeModal}
        feed_id={feed.id}
        onSuccess={onSuccess}
        position={position}
      />
      <DragTable feed={feed} setIsModalOpen={setIsModalOpen} setPosition={setPosition} />
    </>
  )
}
