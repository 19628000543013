import { IResourceComponentsProps, useTranslate } from '@refinedev/core'

import { Table, TableProps } from 'antd'
import { IExport } from 'interfaces'

import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import { OperationAttachments } from 'pages/operations/OperationAttachments'

const ExportsTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IExport>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 10,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IExport>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('exports.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IExport>
        dataIndex="created_at"
        key="created_at"
        title={t('exports.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IExport>
        dataIndex={['user', 'email']}
        key="user"
        title={t('exports.fields.user')}
      />
      <Table.Column<IExport>
        dataIndex="status"
        key="status"
        title={t('exports.fields.status')}
        render={(value) => t(`exports.statuses.${value}`)}
      />
      <Table.Column<IExport>
        dataIndex="title"
        key="title"
        title={t('exports.fields.title')}
      />
      <Table.Column<IExport>
        fixed="right"
        width={100}
        title={t('exports.fields.attachment')}
        dataIndex="actions"
        key="actions"
        align="center"
        render={(_value, record) => (
          <OperationAttachments attachments_ids={record.attachment_ids} />
        )}
      />
    </Table>
  )
}

export default ExportsTable
