import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'

import { List, useTable, useSelect } from '@refinedev/antd'
import { Form, Select, Input } from 'antd'
import { ICard, IPartner } from 'interfaces'
import CardsTable from './list_table'
import ScopesFilter from 'components/ScopesFilter'

export const CardList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<ICard>({
    onSearch: (params) => {
      // @ts-ignore
      const { partners_ids, scope, search } = params
      const cardsFilters: CrudFilters = []

      cardsFilters.push({
        field: 'partners_ids',
        operator: 'in',
        value: partners_ids?.length > 0 ? partners_ids : undefined,
      })

      cardsFilters.push({
        field: 'scope',
        operator: 'eq',
        value: scope,
      })

      cardsFilters.push({
        field: 'search',
        operator: 'eq',
        value: search,
      })

      return cardsFilters
    },

    pagination: {
      pageSize: 20,
    },

    filters: {
      initial: [
        {
          field: 'scope',
          operator: 'eq',
          value: 'active',
        },
      ],
    },
  })

  const { selectProps: partnersSelectProps } = useSelect<IPartner>({
    resource: 'partners',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    <List
      title={`${t('cards.titles.list')} (${
        tableProps.pagination && tableProps.pagination.total
      })`}
    >
      <Form
        {...searchFormProps}
        layout="horizontal"
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          partners_ids: getDefaultFilter('partners_ids', filters, 'in'),
          scope: getDefaultFilter('scope', filters, 'eq'),
          search: getDefaultFilter('search', filters, 'eq'),
        }}
      >
        <Form.Item style={{ marginBottom: 8 }}>
          <Form.Item
            name="search"
            style={{
              marginBottom: 0,
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('payment_requisites.filter.placeholders.search')}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="partners_ids"
            style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 8px)' }}
          >
            <Select
              style={{ minWidth: 200 }}
              {...partnersSelectProps}
              onSearch={() => {}}
              allowClear
              placeholder={t('cards.filter.placeholders.partner')}
              mode="multiple"
              filterOption={(input, option: any) => {
                return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }}
            />
          </Form.Item>
        </Form.Item>
        {
          // @ts-ignore
          tableQueryResult.data?.scopes && (
            <Form.Item name="scope">
              <ScopesFilter
                // @ts-ignore
                scopes={tableQueryResult.data?.scopes}
                resource={'cards'}
              />
            </Form.Item>
          )
        }
      </Form>
      <CardsTable tableProps={tableProps} />
    </List>
  )
}
