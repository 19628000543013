import { SearchOutlined } from '@ant-design/icons'
import { useTable } from '@refinedev/antd'

import { Checkbox, Col, Form, Input, InputNumber, Row, Table, Typography } from 'antd'

import { CrudFilters, getDefaultFilter, useTranslate } from '@refinedev/core'
import { IFeedItem, IOperation } from 'interfaces'
import { OperationStatus } from 'pages/cashin/OperationStatus'
import DateField from 'components/DateField'
import { formattedCardNumber, formattedCurrencyAmount } from 'utils'
import { OperationActions } from './operation_actions'

const { Text } = Typography

export const MatchedOperations: React.FC<{ feed_item: IFeedItem }> = ({ feed_item }) => {
  const t = useTranslate()
  const feed = feed_item.feed

  const { tableProps, filters, searchFormProps } = useTable<IOperation>({
    syncWithLocation: false,
    resource: 'operations',

    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const {
        filter_by_requisite,
        filter_by_created_at,
        amount_gte,
        amount_lte,
        id,
        idempotency_key,
        scoring_client_id,
      } = params

      filters.push({
        field: 'id',
        operator: 'eq',
        value: id,
      })

      filters.push({
        field: 'idempotency_key',
        operator: 'eq',
        value: idempotency_key,
      })

      filters.push({
        field: 'scoring_client_id',
        operator: 'eq',
        value: scoring_client_id,
      })

      filters.push({
        field: 'amount_gte',
        operator: 'eq',
        value: amount_gte,
      })

      filters.push({
        field: 'amount_lte',
        operator: 'eq',
        value: amount_lte,
      })

      filters.push({
        field: 'created_at_lte',
        operator: 'eq',
        value: filter_by_created_at ? feed_item.sms.created_at : null,
      })

      filters.push({
        field: 'payment_requisite_id',
        operator: 'eq',
        value: filter_by_requisite ? feed.payment_requisite.id : null,
      })

      return filters
    },

    filters: {
      initial: [
        {
          field: 'amount_gte',
          operator: 'eq',
          value: feed_item.amount,
        },
        {
          field: 'amount_lte',
          operator: 'eq',
          value: feed_item.amount,
        },
        {
          field: 'created_at_lte',
          operator: 'eq',
          value: feed_item.sms.created_at,
        },
        {
          field: 'payment_requisite_id',
          operator: 'eq',
          value: feed.payment_requisite.id,
        },
      ],

      permanent: [
        {
          field: 'statuses',
          operator: 'eq',
          value: [
            'created',
            'paid',
            'expired',
            'manually_approved',
            'cancelled',
            'rejected',
          ],
        },
        {
          field: 'feed_item_id',
          operator: 'eq',
          value: feed_item.id,
        },
        {
          field: 'kind',
          operator: 'eq',
          value: feed_item.sign > 0 ? 'income' : 'payout',
        },
      ],
    },
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          id: getDefaultFilter('id', filters),
          idempotency_key: getDefaultFilter('idempotency_key', filters),
          scoring_client_id: getDefaultFilter('scoring_client_id', filters),
          amount_lte: feed_item.amount,
          amount_gte: feed_item.amount,
          filter_by_created_at: true,
          filter_by_requisite: true,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('operations.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="idempotency_key"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('operations.filter.idempotency_key.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="scoring_client_id"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('operations.filter.scoring_client_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </div>
        <Row>
          <Col span={7}>
            <Form.Item
              name={'amount_gte'}
              label={t('operations.filter.amount_gte.label')}
            >
              <InputNumber
                style={{ width: '50%' }}
                placeholder={t('operations.filter.amount_gte.placeholder')}
              />
            </Form.Item>
            <Form.Item name={'filter_by_created_at'} valuePropName="checked">
              <Checkbox>Создан раньше СМС</Checkbox>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name={'amount_lte'}
              label={t('operations.filter.amount_lte.label')}
            >
              <InputNumber
                style={{ display: 'inline-block', width: '50%' }}
                placeholder={t('operations.filter.amount_lte.placeholder')}
              />
            </Form.Item>
            <Form.Item name={'filter_by_requisite'} valuePropName="checked">
              <Checkbox>Только по реквизиту из СМС</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table scroll={{ x: '100%' }} {...tableProps} rowKey="id">
        <Table.Column<IOperation>
          dataIndex="id"
          fixed={'left'}
          key="id"
          width={100}
          ellipsis
          render={(value) => (
            <Text copyable={{ text: value, tooltips: `Copy ${value}` }}>
              {value.slice(-8)}
            </Text>
          )}
        />
        <Table.Column<IOperation>
          dataIndex="idempotency_key"
          width={100}
          key="idempotency_key"
          title={t('operations.fields.idempotency_key')}
        />
        <Table.Column<IOperation>
          dataIndex="scoring_client_id"
          key="scoring_client_id"
          title={t('operations.fields.scoring_client_id')}
        />
        <Table.Column<IOperation>
          dataIndex="created_at"
          key="created_at"
          title={t('operations.fields.created_at')}
          render={(value) => <DateField value={value} />}
        />
        <Table.Column<IOperation>
          align="right"
          dataIndex="amount"
          key="amount"
          title={t('operations.fields.amount')}
          render={(value) => formattedCurrencyAmount(value)}
        />
        <Table.Column<IOperation>
          align="right"
          dataIndex="approved_amount"
          key="approved_amount"
          title={t('operations.fields.approved_amount')}
          render={(value) => value && formattedCurrencyAmount(value)}
        />
        <Table.Column<IOperation>
          ellipsis
          dataIndex={['card', 'number']}
          key="card_number"
          title={t('operations.fields.card')}
          render={(value) => formattedCardNumber(value)}
        />
        <Table.Column
          ellipsis
          dataIndex={['gateway', 'name']}
          key="gateway"
          title={t('operations.fields.gateway')}
        />
        <Table.Column<IOperation>
          dataIndex="status"
          key="status"
          title={t('operations.fields.status')}
          render={(value) => <OperationStatus status={value} />}
        />
        <Table.Column<IOperation>
          dataIndex="actions"
          width={100}
          fixed={'right'}
          key="actions"
          title={t('operations.fields.actions')}
          render={(_, record) => (
            <OperationActions id={record.id} feed_item={feed_item} />
          )}
        />
      </Table>
    </>
  )
}
