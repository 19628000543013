import React from 'react'
import { Card, Form, Select, FormProps, Table, Typography, Tag, Radio } from 'antd'
import { CrudFilters, getDefaultFilter, useTranslate } from '@refinedev/core'
import { arrayAverage, formattedCurrencyAmount } from 'utils'
import { IMerchantDashboardItem, IMerchantDashboardPayoutItem } from 'interfaces'
import { useTable } from '@refinedev/antd'

const FilterForm: React.FC<{
  searchFormProps: FormProps
  filters: CrudFilters
}> = ({ searchFormProps, filters }) => {
  const t = useTranslate()

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <Form
      {...searchFormProps}
      onValuesChange={onValueChange}
      layout="inline"
      // @ts-ignore
      initialValues={{
        operations_kind: getDefaultFilter('operations_kind', filters, 'eq'),
        period: getDefaultFilter('period', filters, 'eq'),
      }}
    >
      <Form.Item name={'operations_kind'}>
        <Radio.Group buttonStyle="solid" size="middle" value={'incomes'}>
          <Radio.Button value="incomes">
            {t('partners_dashboard.form.operations_kind.incomes.label')}
          </Radio.Button>
          <Radio.Button value="payouts">
            {t('partners_dashboard.form.operations_kind.payouts.label')}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        style={{ width: 200 }}
        name="period"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder={t('partners_dashboard.form.period.placeholder')}
          options={[
            {
              label: t('partners_dashboard.form.period.last_15_minutes.label'),
              value: 'last_15_minutes',
            },
            {
              label: t('partners_dashboard.form.period.last_hour.label'),
              value: 'last_hour',
            },
            {
              label: t('partners_dashboard.form.period.last_day.label'),
              value: 'last_day',
            },
          ]}
        />
      </Form.Item>
    </Form>
  )
}

export const MerchantsDashboardPage: React.FC = () => {
  const t = useTranslate()
  const { searchFormProps, filters, tableProps } = useTable<IMerchantDashboardItem>({
    syncWithLocation: false,
    resource: 'dashboard/merchants',
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { period, operations_kind } = params

      filters.push({
        field: 'operations_kind',
        operator: 'eq',
        value: operations_kind,
      })

      filters.push({
        field: 'period',
        operator: 'eq',
        value: period,
      })

      return filters
    },
    pagination: {
      pageSize: 50,
    },
    sorters: {
      mode: 'off',
    },
    filters: {
      initial: [
        {
          field: 'period',
          operator: 'eq',
          value: 'last_15_minutes',
        },
        {
          field: 'operations_kind',
          operator: 'eq',
          value: 'incomes',
        },
      ],
    },
  })

  const currentOperationsKind = getDefaultFilter('operations_kind', filters, 'eq')

  let table

  if (currentOperationsKind === 'incomes') {
    table = (
      <Table
        {...tableProps}
        tableLayout="fixed"
        scroll={{ x: '100%', y: '100%' }}
        size="small"
        rowKey="id"
        summary={(pagedata) => {
          return (
            <Table.Summary fixed="top">
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  –
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="right">
                  –
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  –
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  –
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) => accumulator + item.operations.created_count,
                      0
                    )}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) => accumulator + item.operations.approved_count,
                      0
                    )}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) => accumulator + item.operations.created_count,
                      0
                    ) === 0
                      ? '0%'
                      : `${(
                          (pagedata.reduce(
                            (accumulator, item) =>
                              accumulator + item.operations.approved_count,
                            0
                          ) /
                            pagedata.reduce(
                              (accumulator, item) =>
                                accumulator + item.operations.created_count,
                              0
                            )) *
                          100
                        ).toFixed(2)}%`}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) =>
                        accumulator + item.operations.manually_approved,
                      0
                    )}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) => accumulator + item.operations.created_count,
                      0
                    ) === 0
                      ? '0%'
                      : `${(
                          (pagedata.reduce(
                            (accumulator, item) =>
                              accumulator + item.operations.manually_approved,
                            0
                          ) /
                            pagedata.reduce(
                              (accumulator, item) =>
                                accumulator + item.operations.created_count,
                              0
                            )) *
                          100
                        ).toFixed(2)}%`}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) => accumulator + item.operations.holds_count,
                      0
                    )}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) => accumulator + item.operations.count,
                      0
                    ) === 0
                      ? '0%'
                      : `${(
                          (pagedata.reduce(
                            (accumulator, item) =>
                              accumulator + item.operations.holds_count,
                            0
                          ) /
                            pagedata.reduce(
                              (accumulator, item) => accumulator + item.operations.count,
                              0
                            )) *
                          100
                        ).toFixed(2)}%`}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) => accumulator + item.disputes?.count,
                      0
                    )}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={14} align="right">
                  <strong>
                    {pagedata.reduce(
                      (accumulator, item) => accumulator + item.operations.created_count,
                      0
                    ) === 0
                      ? '0%'
                      : `${(
                          (pagedata.reduce(
                            (accumulator, item) => accumulator + item.disputes?.count,
                            0
                          ) /
                            pagedata.reduce(
                              (accumulator, item) =>
                                accumulator + item.operations.created_count,
                              0
                            )) *
                          100
                        ).toFixed(2)}%`}
                  </strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )
        }}
        pagination={{
          ...tableProps.pagination,
          pageSizeOptions: [],
          position: ['bottomLeft'],
          size: 'small',
        }}
      >
        <Table.Column<IMerchantDashboardItem>
          dataIndex="name"
          width={200}
          fixed="left"
          key="name"
          title={t('merchants_dashboard.fields.name')}
        />
        <Table.Column<IMerchantDashboardItem>
          width={100}
          align="right"
          dataIndex={['payment_requisites', 'exclusive_requisites_count']}
          key="payment_requisites_exclusive_requisites_count"
          title={t('merchants_dashboard.fields.exclusive_requisites_count')}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return (
              a.payment_requisites.exclusive_requisites_count -
              b.payment_requisites.exclusive_requisites_count
            )
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          width={100}
          align="right"
          dataIndex={['payment_requisites', 'shared_requisites_count']}
          key="payment_requisites_shared_requisites_count"
          title={t('merchants_dashboard.fields.shared_requisites_count')}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return (
              a.payment_requisites.shared_requisites_count -
              b.payment_requisites.shared_requisites_count
            )
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          width={100}
          align="right"
          dataIndex={['payment_requisites', 'shared_requisites_percent']}
          key="payment_requisites_shared_requisites_percent"
          title={t('merchants_dashboard.fields.shared_requisites_percent')}
          render={(value, record) => (
            <Typography.Text ellipsis>{`${Number(
              record.payment_requisites.shared_requisites_percent
            )}%`}</Typography.Text>
          )}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return (
              Number(a.payment_requisites.shared_requisites_percent) -
              Number(b.payment_requisites.shared_requisites_percent)
            )
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          width={100}
          align="right"
          dataIndex={['payment_requisites', 'active_requisites_count']}
          key="payment_requisites_active_requisites_count"
          title={t('merchants_dashboard.fields.active_requisites_count')}
          render={(value, record) =>
            value === 0 ? (
              <Typography.Text style={{ color: 'red' }}>{value}</Typography.Text>
            ) : (
              value
            )
          }
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return (
              a.payment_requisites.active_requisites_count -
              b.payment_requisites.active_requisites_count
            )
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['operations', 'created_count']}
          key="operations_count"
          title={t('merchants_dashboard.fields.operations_count')}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return a.operations.created_count - b.operations.created_count
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['operations', 'approved_count']}
          key="operations_approved_count"
          title={t('merchants_dashboard.fields.approved_count')}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return a.operations.approved_count - b.operations.approved_count
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['operations', 'approved_percent']}
          key="operations_approved_percent"
          title={t('merchants_dashboard.fields.approved_percent')}
          render={(value, record) => `${Number(record.operations.approved_percent)}%`}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return (
              Number(a.operations.approved_percent) -
              Number(b.operations.approved_percent)
            )
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['operations', 'manually_approved']}
          key="operations_manually_approved_count"
          title={t('merchants_dashboard.fields.manually_approved')}
          render={(value, record) => (
            <Typography.Text
              ellipsis
            >{`${record.operations.manually_approved}`}</Typography.Text>
          )}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return a.operations.manually_approved - b.operations.manually_approved
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['operations', 'manually_percent']}
          key="operations_manually_approved_percent"
          title={t('merchants_dashboard.fields.manually_percent')}
          render={(value, record) => (
            <Typography.Text ellipsis>{`${Number(
              record.operations.manually_percent
            )}%`}</Typography.Text>
          )}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return (
              Number(a.operations.manually_percent) -
              Number(b.operations.manually_percent)
            )
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['operations', 'holds_count']}
          key="operations_holds_count"
          title={t('merchants_dashboard.fields.holds_count')}
          render={(value, record) => (
            <Typography.Text
              ellipsis
            >{`${record.operations.holds_count}`}</Typography.Text>
          )}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return a.operations.holds_count - b.operations.holds_count
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['operations', 'holds_percent']}
          key="operations_holds_percent"
          title={t('merchants_dashboard.fields.holds_percent')}
          render={(value, record) => (
            <Typography.Text ellipsis>{`${Number(
              record.operations.holds_percent
            )}%`}</Typography.Text>
          )}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return Number(a.operations.holds_percent) - Number(b.operations.holds_percent)
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['disputes', 'count']}
          key="disputes_count"
          title={t('merchants_dashboard.fields.disputes_count')}
          render={(value) => <Typography.Text ellipsis>{value}</Typography.Text>}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return Number(a.disputes?.count) - Number(b.disputes?.count)
          }}
        />
        <Table.Column<IMerchantDashboardItem>
          align="right"
          width={100}
          dataIndex={['disputes', 'percent']}
          key="disputes_percent"
          title={t('merchants_dashboard.fields.disputes_percent')}
          render={(value) => (
            <Typography.Text ellipsis>{`${Number(value)}%`}</Typography.Text>
          )}
          sorter={(a: IMerchantDashboardItem, b: IMerchantDashboardItem) => {
            return Number(a.disputes?.percent) - Number(b.disputes?.percent)
          }}
        />
      </Table>
    )
  }

  if (currentOperationsKind === 'payouts') {
    table = <></>
    // table = (
    //   <Table
    //     {...tableProps}
    //     tableLayout="fixed"
    //     scroll={{ x: '100%', y: '100%' }}
    //     size="small"
    //     rowKey="id"
    //     // @ts-ignore
    //     summary={(pagedata: readonly IPartnerDashboardPayoutItem[]) => {
    //       return (
    //         <Table.Summary fixed="top">
    //           <Table.Summary.Row>
    //             <Table.Summary.Cell index={0}>
    //               <strong>Total</strong>
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={1}></Table.Summary.Cell>
    //             <Table.Summary.Cell index={2} align="right">
    //               <strong>
    //                 {pagedata.reduce(
    //                   (accumulator, item) =>
    //                     accumulator + item.payment_requisites.active_count,
    //                   0
    //                 )}
    //               </strong>
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={3} align="right">
    //               <strong>
    //                 {pagedata.reduce(
    //                   (accumulator, item) => accumulator + item.operations.count,
    //                   0
    //                 )}
    //               </strong>
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={3} align="right">
    //               <strong>
    //                 {pagedata.reduce(
    //                   (accumulator, item) =>
    //                     accumulator + item.operations.processed_count,
    //                   0
    //                 )}
    //               </strong>
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={4} align="right">
    //               <strong>
    //                 {pagedata.reduce(
    //                   (accumulator, item) => accumulator + item.operations.approved_count,
    //                   0
    //                 )}
    //               </strong>
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={5} align="right">
    //               <strong>
    //                 {pagedata.reduce(
    //                   (accumulator, item) => accumulator + item.operations.count,
    //                   0
    //                 ) === 0
    //                   ? '0%'
    //                   : `${(
    //                       (pagedata.reduce(
    //                         (accumulator, item) =>
    //                           accumulator + item.operations.approved_count,
    //                         0
    //                       ) /
    //                         pagedata.reduce(
    //                           (accumulator, item) => accumulator + item.operations.count,
    //                           0
    //                         )) *
    //                       100
    //                     ).toFixed(2)}%`}
    //               </strong>
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={6} align="right">
    //               <strong>
    //                 {Math.round(
    //                   arrayAverage(
    //                     pagedata
    //                       .filter((item) => item.operations.approve_time > 0)
    //                       .map((item) => item.operations.approve_time)
    //                   ) / 60
    //                 )}
    //               </strong>
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={7} align="right">
    //               <strong>
    //                 {formattedCurrencyAmount(
    //                   pagedata.reduce(
    //                     (accumulator, item) =>
    //                       accumulator + Number(item.operations.approved_sum),
    //                     0
    //                   ),
    //                   0
    //                 )}
    //               </strong>
    //             </Table.Summary.Cell>
    //           </Table.Summary.Row>
    //         </Table.Summary>
    //       )
    //     }}
    //     pagination={{
    //       ...tableProps.pagination,
    //       pageSizeOptions: [],
    //       position: ['bottomLeft'],
    //       size: 'small',
    //     }}
    //   >
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       dataIndex="name"
    //       width={200}
    //       fixed="left"
    //       key="name"
    //       title={t('partners_dashboard.fields.name')}
    //     />
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       width={100}
    //       dataIndex={['shift', 'status']}
    //       key="shift_status"
    //       title={t('partners_dashboard.fields.shift_status')}
    //       render={(value) =>
    //         value !== undefined ? (
    //           <Tag color={value === 'started' ? 'green' : 'red'}>
    //             {t('shifts.statuses.' + value)}
    //           </Tag>
    //         ) : (
    //           <></>
    //         )
    //       }
    //     />
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       align="right"
    //       width={100}
    //       dataIndex={['payment_requisites', 'active_count']}
    //       key="payment_requisites_active_count"
    //       title={t('partners_dashboard.fields.payment_requisites_active_count')}
    //       sorter={(a: IPartnerDashboardPayoutItem, b: IPartnerDashboardPayoutItem) => {
    //         return a.payment_requisites.active_count - b.payment_requisites.active_count
    //       }}
    //     />
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       align="right"
    //       width={100}
    //       dataIndex={['operations', 'count']}
    //       key="operations_count"
    //       title={t('partners_dashboard.fields.operations_count')}
    //       sorter={(a: IPartnerDashboardPayoutItem, b: IPartnerDashboardPayoutItem) => {
    //         return a.operations.count - b.operations.count
    //       }}
    //     />
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       align="right"
    //       width={100}
    //       dataIndex={['operations', 'processed_count']}
    //       key="operations_processed_count"
    //       title={t('partners_dashboard.fields.operations_processed_count')}
    //       sorter={(a: IPartnerDashboardPayoutItem, b: IPartnerDashboardPayoutItem) => {
    //         return a.operations.processed_count - b.operations.processed_count
    //       }}
    //     />
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       align="right"
    //       width={100}
    //       dataIndex={['operations', 'approved_count']}
    //       key="operations_approved_count"
    //       title={t('partners_dashboard.fields.operations_approved_count')}
    //       sorter={(a: IPartnerDashboardPayoutItem, b: IPartnerDashboardPayoutItem) => {
    //         return a.operations.approved_count - b.operations.approved_count
    //       }}
    //     />
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       align="right"
    //       width={100}
    //       dataIndex={['operations', 'approved_percent']}
    //       key="operations_approved_percent"
    //       title={t('partners_dashboard.fields.operations_approved_percent')}
    //       render={(value, record) => `${Number(record.operations.approved_percent)}%`}
    //       sorter={(a: IPartnerDashboardPayoutItem, b: IPartnerDashboardPayoutItem) => {
    //         return (
    //           Number(a.operations.approved_percent) -
    //           Number(b.operations.approved_percent)
    //         )
    //       }}
    //     />
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       align="right"
    //       width={100}
    //       dataIndex={['operations', 'approve_time']}
    //       key="operations_approve_time"
    //       title={t('partners_dashboard.fields.operations_approve_time')}
    //       render={(value, record) =>
    //         Math.round(Number(record.operations.approve_time) / 60)
    //       }
    //       sorter={(a: IPartnerDashboardPayoutItem, b: IPartnerDashboardPayoutItem) =>
    //         a.operations.approve_time - b.operations.approve_time
    //       }
    //     />
    //     <Table.Column<IPartnerDashboardPayoutItem>
    //       align="right"
    //       width={120}
    //       dataIndex={['operations', 'approved_sum']}
    //       key="operations_approved_sum"
    //       title={t('partners_dashboard.fields.operations_approved_sum')}
    //       render={(value) => formattedCurrencyAmount(value, 0)}
    //       sorter={(a: IPartnerDashboardPayoutItem, b: IPartnerDashboardPayoutItem) =>
    //         Number(a.operations.approved_sum) - Number(b.operations.approved_sum)
    //       }
    //     />
    //   </Table>
    // )
  }

  return (
    <>
      <Card style={{ marginBottom: 16 }}>
        <FilterForm searchFormProps={searchFormProps} filters={filters} />
      </Card>
      {table}
    </>
  )
}
