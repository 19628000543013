import {
  IResourceComponentsProps,
  useApiUrl,
  useCan,
  useCustomMutation,
  useInvalidate,
  useShow,
  useTranslate,
} from '@refinedev/core'
import { Show } from '@refinedev/antd'
import { Button, Descriptions, Space } from 'antd'

import { GenericResponse, IUser } from 'interfaces'
import BooleanTag from 'components/BooleanTag'

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IUser>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const apiUrl = useApiUrl()
  const invalidate = useInvalidate()
  const { mutate } = useCustomMutation<GenericResponse>()

  const { data: canResetTwoFactor } = useCan({
    resource: 'users',
    action: 'reset_two_factor',
  })

  const onClick = () => {
    mutate(
      {
        url: `${apiUrl}/users/${record?.id}/reset_two_factor`,
        method: 'post',
        values: {},
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'users',
            invalidates: ['detail'],
            id: record?.id,
          })
        },
      }
    )
  }

  return (
    <Show resource="users" isLoading={isLoading} title={record?.email || ''}>
      <Descriptions column={1}>
        <Descriptions.Item label={t('users.fields.email')}>
          {record?.email}
        </Descriptions.Item>
        <Descriptions.Item label={t('users.fields.two_factor_verification_enabled')}>
          <Space>
            <BooleanTag value={record?.two_factor_verification_enabled} />
            {record?.two_factor_verification_enabled && canResetTwoFactor?.can && (
              <Button size="small" onClick={onClick}>
                {t('users.actions.reset_two_factor')}
              </Button>
            )}
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Show>
  )
}
