import React, { useEffect, useState } from 'react'
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useShow,
  useTranslate,
} from '@refinedev/core'
import { Show } from '@refinedev/antd'

import { IDispute, IDisputeComment, IOperation } from 'interfaces'
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Space,
  Spin,
  Timeline,
  Typography,
} from 'antd'
import { OperationControls } from 'pages/operations/OperationControls'
import TextArea from 'antd/es/input/TextArea'
import { DisputeStatus } from './DisputeStatus'

const Comments: React.FC<{ comments: IDisputeComment[] }> = ({ comments }) => {
  const timelineItems = comments.map((comment) => {
    return (
      <Timeline.Item key={comment.created_at}>
        <Typography.Text>
          {comment.author}: {comment.body}
        </Typography.Text>
      </Timeline.Item>
    )
  })

  return <Timeline>{timelineItems}</Timeline>
}

const Attachments: React.FC<{ attachment_ids: string[] }> = ({ attachment_ids }) => {
  const apiUrl = useApiUrl()

  return (
    <iframe
      style={{
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        border: 0,
      }}
      height="100%"
      width="100%"
      title={attachment_ids[0]}
      src={`${apiUrl}/attachments/${attachment_ids[0]}`}
    ></iframe>
  )
}

const DisputeContent: React.FC<{ record: IDispute }> = ({ record }) => {
  const { queryResult, setShowId } = useShow<IOperation>({ resource: 'operations' })
  const { data, isLoading } = queryResult
  const operation = data?.data

  useEffect(() => {
    setShowId(record.operation.id)
  }, [record, setShowId])

  if (!isLoading && operation) {
    return (
      <Row>
        <Col span={9}>
          <Card
            title={'Чек от мерчанта'}
            style={{ height: '100%' }}
            bodyStyle={{ height: 'calc(100% - 48px)' }}
          >
            <Attachments attachment_ids={record.attachment_ids} />
          </Card>
        </Col>
        <Col span={14} offset={1}>
          <Card title={'Комментарии к диспуту'} style={{ marginBottom: 4 }}>
            <Comments comments={record.comments} />
          </Card>
          <Card
            title={
              <Space>
                <Typography.Text>Заказ:</Typography.Text>
                <Typography.Text copyable>{operation.id}</Typography.Text>
              </Space>
            }
          >
            <OperationControls record={operation} />
          </Card>
        </Col>
      </Row>
    )
  } else {
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <Spin tip="Loading" size="large" />
      </div>
    )
  }
}

interface MutateOperationResponse {
  success: boolean
}

const CloseButton: React.FC<{ record: IDispute }> = ({ record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isApproving, setIsApproving] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const [comment, setComment] = useState('')

  const t = useTranslate()

  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<MutateOperationResponse>()
  const invalidate = useInvalidate()

  const onClose = ({ comment }: { comment: string }) => {
    setIsApproving(true)
    customMutate(
      {
        url: `${apiUrl}/disputes/${record.id}/close`,
        method: 'post',
        values: {
          comment,
        },
      },
      {
        onError: (error, variables, context) => {
          setIsApproving(false)
        },
        onSuccess: (data, variables, context) => {
          setIsApproving(false)
          closeModal()
          invalidate({
            resource: 'disputes',
            invalidates: ['detail'],
            id: record.id,
          })
          invalidate({
            resource: 'disputes',
            invalidates: ['list'],
          })
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  return (
    <>
      <Button onClick={showModal}>{t('disputes.actions.approve')}</Button>
      <Modal
        open={isModalOpen}
        title={t('disputes.actions.approve')}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        {isModalOpen && (
          <>
            <Form layout="vertical">
              <Form.Item label={t('disputes.fields.comment')}>
                <TextArea
                  rows={4}
                  allowClear
                  defaultValue={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Form.Item>
              <Button
                loading={isApproving}
                htmlType="submit"
                onClick={() => onClose({ comment })}
                type="primary"
              >
                {t('disputes.actions.approve')}
              </Button>
            </Form>
          </>
        )}
      </Modal>
    </>
  )
}

const RejectButton: React.FC<{ record: IDispute }> = ({ record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRejecting, setIsRejecting] = useState(false)
  const [comment, setComment] = useState('')

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const t = useTranslate()

  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<MutateOperationResponse>()
  const invalidate = useInvalidate()

  const onReject = ({ comment }: { comment: string }) => {
    setIsRejecting(true)
    customMutate(
      {
        url: `${apiUrl}/disputes/${record.id}/reject`,
        method: 'post',
        values: {
          comment,
        },
      },
      {
        onError: (error, variables, context) => {
          setIsRejecting(false)
        },
        onSuccess: (data, variables, context) => {
          setIsRejecting(false)
          closeModal()
          invalidate({
            resource: 'disputes',
            invalidates: ['detail'],
            id: record.id,
          })
          invalidate({
            resource: 'disputes',
            invalidates: ['list'],
          })
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  return (
    <>
      <Button onClick={showModal}>{t('disputes.actions.reject')}</Button>
      <Modal
        open={isModalOpen}
        title={t('disputes.actions.reject')}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        {isModalOpen && (
          <>
            <Form layout="vertical">
              <Form.Item label={t('disputes.fields.comment')}>
                <TextArea
                  rows={4}
                  allowClear
                  defaultValue={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Form.Item>
              <Button
                loading={isRejecting}
                htmlType="submit"
                onClick={() => onReject({ comment })}
                type="primary"
              >
                {t('disputes.actions.reject')}
              </Button>
            </Form>
          </>
        )}
      </Modal>
    </>
  )
}

export const DisputeShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IDispute>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const title = record ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Typography.Title level={4} style={{ marginBottom: 0, marginRight: 20 }}>
        Диспут от {record.merchant?.name} на сумму {record.amount}
      </Typography.Title>
      <DisputeStatus status={record.status} />
    </div>
  ) : (
    ''
  )

  return (
    <Show
      isLoading={isLoading}
      title={title}
      headerButtons={
        record && record.status === 'created' ? (
          <>
            <CloseButton record={record} />
            <RejectButton record={record} />
          </>
        ) : (
          <></>
        )
      }
    >
      {!isLoading && record ? <DisputeContent record={record} /> : <></>}
    </Show>
  )
}
