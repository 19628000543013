import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { EditButton, TextField } from '@refinedev/antd'
import { Table, Space, Typography, TableProps, Tag } from 'antd'
import { ICard, IGateway } from 'interfaces'
import DateField from 'components/DateField'
import CardStatus from 'components/CardStatus'
import CardColor from 'components/CardColor'
import { ShortId } from 'components/ShortId'
import { formattedCardNumber, formattedPhone } from 'utils'
import { formattedCurrencyAmount } from 'utils'
import { GatewayType } from 'components/GatewayType'

const { Text } = Typography

const CardsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<ICard> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
              ...tableProps.pagination,
              pageSize: 10,
              position: ['bottomLeft'],
              size: 'small',
            }
          : false
      }
    >
      <Table.Column<ICard>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('cards.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<ICard>
        dataIndex="card_number"
        ellipsis={true}
        key="card_number"
        title={t('cards.fields.card_number')}
        render={(value) => formattedCardNumber(value)}
      />
      <Table.Column<ICard>
        dataIndex="cardholder_name"
        ellipsis={true}
        key="cardholder_name"
        title={t('cards.fields.cardholder_name')}
      />
      <Table.Column<ICard>
        dataIndex={['partner', 'name']}
        key="partner_name"
        title={t('cards.fields.partner')}
      />
      <Table.Column<ICard>
        ellipsis
        dataIndex="phone"
        key="phone"
        title={t('cards.fields.phone')}
        render={(value) => formattedPhone(value)}
      />
      <Table.Column<ICard>
        dataIndex="bank_for_sbp"
        key="bank_for_sbp"
        title={t('cards.fields.bank_for_sbp')}
      />
      <Table.Column<ICard>
        dataIndex="sbp_holder_name"
        key="sbp_holder_name"
        title={t('cards.fields.sbp_holder_name')}
      />
      <Table.Column<ICard>
        dataIndex="account"
        key="account"
        title={t('cards.fields.account')}
      />
      <Table.Column<ICard>
        dataIndex="gateways"
        key="gateways"
        title={t('cards.fields.gateways')}
        render={(value: IGateway[]) => (value.map((gateway) => (
          <Tag
            style={{
              padding: 4,
              margin: 4,
              fontSize: 14,
          }}
          >
            <Space>
              <GatewayType type={gateway.payment_type} />
              {gateway.currency}
              {gateway.name}
            </Space>
          </Tag>
        ))
        )}
      />
      <Table.Column<ICard>
        dataIndex="status"
        key="status"
        title={t('cards.fields.status')}
        render={(value) => <CardStatus status={value} />}
      />
      <Table.Column<ICard>
        dataIndex="color"
        key="color"
        title={t('cards.fields.color')}
        render={(value) => <CardColor color={value} />}
      />
      <Table.Column<ICard>
        dataIndex="sum_approved_amount"
        key="sum_approved_amount"
        title={t('cards.fields.sum_approved_amount')}
        render={(value, record) => (
          <TextField
            value={formattedCurrencyAmount(value, 0, record.currency || 'RUB')}
          />
        )}
      />
      <Table.Column<ICard>
        dataIndex="cash_limit_balance"
        key="cash_limit_balance"
        title={t('cards.fields.cash_limit_balance')}
        render={(value, record) => (
          <TextField
            value={(value ? formattedCurrencyAmount(value) : 'Нет лимита')}
          />
        )}
      />
      <Table.Column<ICard>
        dataIndex="sum_approved_amount_daily"
        key="sum_approved_amount_daily"
        title={t('cards.fields.sum_approved_amount_daily')}
        render={(value, record) => (
          <TextField
            value={formattedCurrencyAmount(value, 0, record.currency || 'RUB')}
          />
        )}
      />
      <Table.Column<ICard>
        dataIndex="last_synced_at"
        key="last_synced_at"
        title={t('cards.fields.last_synced_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<ICard>
        dataIndex="app_version"
        key="app_version"
        title={t('payment_requisites.fields.app_version')}
      />
      <Table.Column<ICard>
        dataIndex="max_approved_sum"
        key="max_approved_sum"
        title={t('payment_requisites.fields.max_approved_sum')}
      />
      <Table.Column<ICard>
        dataIndex="actions"
        title={t('cards.fields.actions')}
        render={(_, record) => (
          <Space>
            <EditButton
              resource="payment_requisites"
              hideText
              size="small"
              recordItemId={record.id}
            />
          </Space>
        )}
      />
    </Table>
  )
}

export default CardsTable
