import React from 'react'
import { IShift } from 'interfaces'
import dayjs from 'dayjs'

export const Timer: React.FC<{ shift: IShift }> = ({ shift }) => {
  return (
    <code style={{ marginLeft: '8px' }}>
      {dayjs().startOf('day').add(shift.active_duration, 'seconds').format('HH:mm')}
    </code>
  )
}
