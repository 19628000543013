import { useInvalidate, useList, useShow, useTranslate } from '@refinedev/core'
import { useForm } from '@refinedev/antd'
import { Select, Button, Modal, Form, Spin } from 'antd'
import { IMerchant, IPartner } from 'interfaces'
import { useState } from 'react'

const AddPartnerMerchantButton: React.FC<{ id: string, kind: string }> = ({ id, kind }) => {
  const kind_name = `${kind}_merchant_ids`
  const t = useTranslate()
  const invalidate = useInvalidate()
  const { queryResult, setShowId } = useShow<IPartner>()
  const { isLoading } = queryResult
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { formProps, saveButtonProps } = useForm<IPartner>({
    action: 'edit',
    resource: 'partners',
    id,
  })

  const showModal = () => {
    setShowId(id)
    invalidate({
      resource: 'partners',
      invalidates: ['detail'],
      id,
    })
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const { data: merchantsData } = useList<IMerchant>({
    resource: 'merchants',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
  })

  return (
    <>
      <Button
        type="dashed"
        size="small"
        onClick={() => {
          showModal()
        }}
      >
        {t('partners.titles.edit_merchants')}
      </Button>
      <Modal
        open={isModalOpen}
        title={t('partners.titles.edit_merchants')}
        onCancel={closeModal}
        footer={null}
      >
        {isLoading && (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Spin tip="Loading" size="large" />
          </div>
        )}
        {isModalOpen && !isLoading && merchantsData && (
          <>
            <Form layout="vertical" {...formProps}>
              <Form.Item
                label={t('partners.form.merchants.label')}
                name={kind_name}
                extra={t('partners.form.merchants.extra')}
              >
                <Select
                  options={merchantsData.data.map((merchant) => ({
                    disabled: merchant.currency !== queryResult.data?.data.currency,
                    label: `${merchant.name} (${merchant.currency})`,
                    value: merchant.id,
                  }))}
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder={t('partners.form.merchants.placeholder')}
                  filterOption={(input, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <Button {...saveButtonProps}>
                {t('partners.buttons.update_merchant')}
              </Button>
            </Form>
          </>
        )}
      </Modal>
    </>
  )
}

export default AddPartnerMerchantButton
