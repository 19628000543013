import { IResourceComponentsProps, useTranslate, useMany } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select, Space, SelectProps} from 'antd'

import { ICard, IGateway, IPartner } from 'interfaces'
import { GatewayType } from 'components/GatewayType'

export const CardCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<ICard>()

  const { data: gatewaysData } = useMany<IGateway>({
    resource: 'gateways',
    ids: [],  
  })
  
  const options: SelectProps['options'] = gatewaysData?.data.map((gateway) => ({
      label: (
        <Space>
          <GatewayType type={gateway.payment_type} />
          {gateway.currency}
          {gateway.name}
        </Space>
      ),
      value: gateway.id,
    }))
      
  const { selectProps: partnerSelectProps } = useSelect<IPartner>({
    resource: 'partners',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="gateways_ids"
          label={t('cards.fields.gateways')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            options={options}
          />
        </Form.Item>
        <Form.Item
          name="card_number"
          label={t('cards.fields.card_number')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item
          name="cardholder_name"
          label={t('cards.fields.cardholder_name')}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t('cards.fields.phone')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input showCount allowClear placeholder="+70000000000" />
        </Form.Item>
        <Form.Item label={t('cards.fields.bank_for_sbp')} name="bank_for_sbp">
          <Select
            options={[
              {
                label: 'Тиньков',
                value: 'Тиньков',
              },
              {
                label: 'Сбербанк',
                value: 'Сбербанк',
              },
              {
                label: 'Райффайзен',
                value: 'Райффайзен',
              },
            ]}
          />
        </Form.Item>
        <Form.Item label={t('cards.fields.color')} name="color">
          <Select
            options={[
              {
                label: 'Зелёная',
                value: 'green',
              },
              {
                label: 'Красная',
                value: 'red',
              },
            ]}
          />
        </Form.Item>
        <Form.Item label={t('cards.fields.sbp_holder_name')} name="sbp_holder_name">
          <Input />
        </Form.Item>
        <Form.Item label={t('cards.fields.account')} name="account">
          <Input showCount allowClear />
        </Form.Item>
        <Form.Item
          name="partner_id"
          label={t('cards.fields.partner')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...partnerSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  )
}
