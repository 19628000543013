export const arrayAverage = (array: number[]): number => {
  return array.length ? array.reduce((a, b) => a + b, 0) / array.length : 0
}

export const formattedCardNumber = (number: string, masked: boolean = true): string => {
  if (number === undefined || number === null) {
    return ''
  }
  if (masked) {
    return number.replace(/^(\d{0,4})(\d{0,4})(\d{0,4})(\d+)$/g, '$1 **** **** $4')
  } else {
    return number.replace(/^(\d{0,4})(\d{0,4})(\d{0,4})(\d+)$/g, '$1 $2 $3 $4')
  }
}

export const formattedAccountNumber = (number: string, masked: boolean = true): string => {
  if (number === undefined || number === null) {
    return ''
  }
  if (masked) {
    return number.replace(/^(\d{5})(\d+)(\d{5})$/g, '$1 * $3')
  } else {
    return number.replace(/^(\d{5})(\d+)(\d{5})$/g, '$1 $2 $3')
  }
}

export const formattedPhone = (number: string, masked: boolean = true): string => {
  if (number === undefined || number === null) {
    return ''
  }
  if (masked) {
    return number.replace(/(\+\d)(\d{3})(\d{3})(\d{4})$/g, '$1 $2 *** $4')
  } else {
    return number.replace(/(\+\d)(\d{3})(\d{3})(\d{2})(\d{2})$/g, '$1 $2 $3 $4 $5')
  }
}

export const formattedCurrencyAmount = (
  amount: string | number | undefined,
  maximumFractionDigits: number = 2,
  currency: string = 'RUB'
): string => {
  if (amount === undefined) {
    return '–'
  }

  const locale = 'ru-RU'
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits,
  }).format(Number(amount))
}

export const formattedPercent = (amount: string | number | undefined): string => {
  if (amount === undefined) {
    return '–'
  }

  const locale = 'ru-RU'
  return new Intl.NumberFormat(locale, {
    style: 'percent',
  }).format(Number(amount))
}

export const formattedCorrectedPercent = (
  amount: string | number | undefined
): string => {
  if (amount === undefined || amount === null || amount === '') {
    return '–'
  }

  const locale = 'ru-RU'
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(Number(amount) / 100.0)
}

export const formattedAmount = (amount: string | bigint | number | null): string => {
  return amount ? new Intl.NumberFormat('ru-RU').format(Number(amount)) : ''
}

export const compactArray = (array: (string | undefined)[]): string[] => {
  //@ts-ignore
  return array.filter(Boolean)
}

export const currencyOptions = [
  { label: 'RUB', value: 'RUB' },
  { label: 'KZT', value: 'KZT' },
  { label: 'AZN', value: 'AZN' },
  { label: 'UZS', value: 'UZS' },
  { label: 'KRW', value: 'KRW' },
]

export const timeZoneOptions = [
  ['Etc/GMT+12', 'Etc/GMT+12 (-12:00)'],
  ['Pacific/Midway', 'Pacific/Midway (-11:00)'],
  ['Pacific/Pago_Pago', 'Pacific/Pago_Pago (-11:00)'],
  ['Pacific/Honolulu', 'Pacific/Honolulu (-10:00)'],
  ['America/Juneau', 'America/Juneau (-08:00)'],
  ['America/Los_Angeles', 'America/Los_Angeles (-07:00)'],
  ['America/Tijuana', 'America/Tijuana (-07:00)'],
  ['America/Phoenix', 'America/Phoenix (-07:00)'],
  ['America/Mazatlan', 'America/Mazatlan (-07:00)'],
  ['America/Denver', 'America/Denver (-06:00)'],
  ['America/Chihuahua', 'America/Chihuahua (-06:00)'],
  ['America/Regina', 'America/Regina (-06:00)'],
  ['America/Mexico_City', 'America/Mexico_City (-06:00)'],
  ['America/Monterrey', 'America/Monterrey (-06:00)'],
  ['America/Guatemala', 'America/Guatemala (-06:00)'],
  ['America/Chicago', 'America/Chicago (-05:00)'],
  ['America/Bogota', 'America/Bogota (-05:00)'],
  ['America/Lima', 'America/Lima (-05:00)'],
  ['America/New_York', 'America/New_York (-04:00)'],
  ['America/Indiana/Indianapolis', 'America/Indiana/Indianapolis (-04:00)'],
  ['America/Caracas', 'America/Caracas (-04:00)'],
  ['America/La_Paz', 'America/La_Paz (-04:00)'],
  ['America/Santiago', 'America/Santiago (-04:00)'],
  ['America/Guyana', 'America/Guyana (-04:00)'],
  ['America/Puerto_Rico', 'America/Puerto_Rico (-04:00)'],
  ['America/Halifax', 'America/Halifax (-03:00)'],
  ['America/Sao_Paulo', 'America/Sao_Paulo (-03:00)'],
  ['America/Argentina/Buenos_Aires', 'America/Argentina/Buenos_Aires (-03:00)'],
  ['America/Montevideo', 'America/Montevideo (-03:00)'],
  ['America/St_Johns', 'America/St_Johns (-02:30)'],
  ['America/Godthab', 'America/Godthab (-02:00)'],
  ['Atlantic/South_Georgia', 'Atlantic/South_Georgia (-02:00)'],
  ['Atlantic/Cape_Verde', 'Atlantic/Cape_Verde (-01:00)'],
  ['Atlantic/Azores', 'Atlantic/Azores (+00:00)'],
  ['Africa/Casablanca', 'Africa/Casablanca (+00:00)'],
  ['Africa/Monrovia', 'Africa/Monrovia (+00:00)'],
  ['Etc/UTC', 'Etc/UTC (+00:00)'],
  ['Europe/Dublin', 'Europe/Dublin (+01:00)'],
  ['Europe/London', 'Europe/London (+01:00)'],
  ['Europe/Lisbon', 'Europe/Lisbon (+01:00)'],
  ['Africa/Algiers', 'Africa/Algiers (+01:00)'],
  ['Europe/Belgrade', 'Europe/Belgrade (+02:00)'],
  ['Europe/Bratislava', 'Europe/Bratislava (+02:00)'],
  ['Europe/Budapest', 'Europe/Budapest (+02:00)'],
  ['Europe/Ljubljana', 'Europe/Ljubljana (+02:00)'],
  ['Europe/Prague', 'Europe/Prague (+02:00)'],
  ['Europe/Sarajevo', 'Europe/Sarajevo (+02:00)'],
  ['Europe/Skopje', 'Europe/Skopje (+02:00)'],
  ['Europe/Warsaw', 'Europe/Warsaw (+02:00)'],
  ['Europe/Zagreb', 'Europe/Zagreb (+02:00)'],
  ['Europe/Brussels', 'Europe/Brussels (+02:00)'],
  ['Europe/Copenhagen', 'Europe/Copenhagen (+02:00)'],
  ['Europe/Madrid', 'Europe/Madrid (+02:00)'],
  ['Europe/Paris', 'Europe/Paris (+02:00)'],
  ['Europe/Amsterdam', 'Europe/Amsterdam (+02:00)'],
  ['Europe/Berlin', 'Europe/Berlin (+02:00)'],
  ['Europe/Zurich', 'Europe/Zurich (+02:00)'],
  ['Europe/Rome', 'Europe/Rome (+02:00)'],
  ['Europe/Stockholm', 'Europe/Stockholm (+02:00)'],
  ['Europe/Vienna', 'Europe/Vienna (+02:00)'],
  ['Africa/Cairo', 'Africa/Cairo (+02:00)'],
  ['Africa/Harare', 'Africa/Harare (+02:00)'],
  ['Africa/Johannesburg', 'Africa/Johannesburg (+02:00)'],
  ['Europe/Kaliningrad', 'Europe/Kaliningrad (+02:00)'],
  ['Europe/Bucharest', 'Europe/Bucharest (+03:00)'],
  ['Europe/Helsinki', 'Europe/Helsinki (+03:00)'],
  ['Europe/Kiev', 'Europe/Kiev (+03:00)'],
  ['Europe/Riga', 'Europe/Riga (+03:00)'],
  ['Europe/Sofia', 'Europe/Sofia (+03:00)'],
  ['Europe/Tallinn', 'Europe/Tallinn (+03:00)'],
  ['Europe/Vilnius', 'Europe/Vilnius (+03:00)'],
  ['Europe/Athens', 'Europe/Athens (+03:00)'],
  ['Europe/Istanbul', 'Europe/Istanbul (+03:00)'],
  ['Europe/Minsk', 'Europe/Minsk (+03:00)'],
  ['Asia/Jerusalem', 'Asia/Jerusalem (+03:00)'],
  ['Europe/Moscow', 'Europe/Moscow (+03:00)'],
  ['Europe/Volgograd', 'Europe/Volgograd (+03:00)'],
  ['Asia/Kuwait', 'Asia/Kuwait (+03:00)'],
  ['Asia/Riyadh', 'Asia/Riyadh (+03:00)'],
  ['Africa/Nairobi', 'Africa/Nairobi (+03:00)'],
  ['Asia/Baghdad', 'Asia/Baghdad (+03:00)'],
  ['Asia/Tehran', 'Asia/Tehran (+03:30)'],
  ['Europe/Samara', 'Europe/Samara (+04:00)'],
  ['Asia/Muscat', 'Asia/Muscat (+04:00)'],
  ['Asia/Baku', 'Asia/Baku (+04:00)'],
  ['Asia/Tbilisi', 'Asia/Tbilisi (+04:00)'],
  ['Asia/Yerevan', 'Asia/Yerevan (+04:00)'],
  ['Asia/Kabul', 'Asia/Kabul (+04:30)'],
  ['Asia/Yekaterinburg', 'Asia/Yekaterinburg (+05:00)'],
  ['Asia/Karachi', 'Asia/Karachi (+05:00)'],
  ['Asia/Tashkent', 'Asia/Tashkent (+05:00)'],
  ['Asia/Kolkata', 'Asia/Kolkata (+05:30)'],
  ['Asia/Colombo', 'Asia/Colombo (+05:30)'],
  ['Asia/Kathmandu', 'Asia/Kathmandu (+05:45)'],
  ['Asia/Dhaka', 'Asia/Dhaka (+06:00)'],
  ['Asia/Almaty', 'Asia/Almaty (+06:00)'],
  ['Asia/Urumqi', 'Asia/Urumqi (+06:00)'],
  ['Asia/Rangoon', 'Asia/Rangoon (+06:30)'],
  ['Asia/Novosibirsk', 'Asia/Novosibirsk (+07:00)'],
  ['Asia/Bangkok', 'Asia/Bangkok (+07:00)'],
  ['Asia/Jakarta', 'Asia/Jakarta (+07:00)'],
  ['Asia/Krasnoyarsk', 'Asia/Krasnoyarsk (+07:00)'],
  ['Asia/Shanghai', 'Asia/Shanghai (+08:00)'],
  ['Asia/Chongqing', 'Asia/Chongqing (+08:00)'],
  ['Asia/Hong_Kong', 'Asia/Hong_Kong (+08:00)'],
  ['Asia/Kuala_Lumpur', 'Asia/Kuala_Lumpur (+08:00)'],
  ['Asia/Singapore', 'Asia/Singapore (+08:00)'],
  ['Asia/Taipei', 'Asia/Taipei (+08:00)'],
  ['Australia/Perth', 'Australia/Perth (+08:00)'],
  ['Asia/Irkutsk', 'Asia/Irkutsk (+08:00)'],
  ['Asia/Ulaanbaatar', 'Asia/Ulaanbaatar (+08:00)'],
  ['Asia/Seoul', 'Asia/Seoul (+09:00)'],
  ['Asia/Tokyo', 'Asia/Tokyo (+09:00)'],
  ['Asia/Yakutsk', 'Asia/Yakutsk (+09:00)'],
  ['Australia/Darwin', 'Australia/Darwin (+09:30)'],
  ['Australia/Adelaide', 'Australia/Adelaide (+09:30)'],
  ['Australia/Melbourne', 'Australia/Melbourne (+10:00)'],
  ['Australia/Sydney', 'Australia/Sydney (+10:00)'],
  ['Australia/Brisbane', 'Australia/Brisbane (+10:00)'],
  ['Australia/Hobart', 'Australia/Hobart (+10:00)'],
  ['Asia/Vladivostok', 'Asia/Vladivostok (+10:00)'],
  ['Pacific/Guam', 'Pacific/Guam (+10:00)'],
  ['Pacific/Port_Moresby', 'Pacific/Port_Moresby (+10:00)'],
  ['Asia/Magadan', 'Asia/Magadan (+11:00)'],
  ['Asia/Srednekolymsk', 'Asia/Srednekolymsk (+11:00)'],
  ['Pacific/Guadalcanal', 'Pacific/Guadalcanal (+11:00)'],
  ['Pacific/Noumea', 'Pacific/Noumea (+11:00)'],
  ['Pacific/Fiji', 'Pacific/Fiji (+12:00)'],
  ['Asia/Kamchatka', 'Asia/Kamchatka (+12:00)'],
  ['Pacific/Majuro', 'Pacific/Majuro (+12:00)'],
  ['Pacific/Auckland', 'Pacific/Auckland (+12:00)'],
  ['Pacific/Chatham', 'Pacific/Chatham (+12:45)'],
  ['Pacific/Tongatapu', 'Pacific/Tongatapu (+13:00)'],
  ['Pacific/Fakaofo', 'Pacific/Fakaofo (+13:00)'],
  ['Pacific/Apia', 'Pacific/Apia (+13:00)'],
].map((item) => ({ value: item[0], label: item[1] }))
