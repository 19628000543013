import { useState } from 'react'

import {
  IResourceComponentsProps,
  useTranslate,
  CrudFilters,
  getDefaultFilter,
  useCan,
} from '@refinedev/core'

import { useTable, useSelect, CreateButton } from '@refinedev/antd'

import {
  Col,
  Row,
  Form,
  Select,
  SelectProps,
  Input,
  DatePicker,
  InputNumber,
  Button,
  Space,
  Checkbox,
} from 'antd'

import { CaretDownOutlined, CaretRightOutlined, SearchOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'

import { IMerchant, IOperation, IPartner } from 'interfaces'
import OperationsTable from './list_table'
import ExportButton from './export_button'
import { OperationStatusMap } from 'pages/cashin/OperationStatus'
import ScopesFilter from 'components/ScopesFilter'

import './styles.css'

const { RangePicker } = DatePicker

export const OperationsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const [filtersExpanded, setFiltersExpanded] = useState(false)

  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<IOperation>(
    {
      filters: {
        initial: [
          {
            field: 'created_by',
            operator: 'eq',
            value: 'all',
          },
          {
            field: 'payment_system',
            operator: 'eq',
            value: 'all',
          },
          {
            field: 'kind',
            operator: 'eq',
            value: 'all',
          },
        ],
      },
      onSearch: (params: any) => {
        const filters: CrudFilters = []
        const {
          statuses,
          kind,
          id,
          idempotency_key,
          card_number,
          rand,
          merchants_ids,
          created_at,
          approved_at,
          amount_gte,
          amount_lte,
          partners_ids,
          scope,
          created_by,
          amount_was_changed,
          client_id,
          scoring_client_id,
          payment_system,
        } = params

        filters.push({
          field: 'statuses',
          operator: 'in',
          value: statuses,
        })

        filters.push({
          field: 'kind',
          operator: 'eq',
          value: kind,
        })

        filters.push({
          field: 'created_by',
          operator: 'eq',
          value: created_by,
        })

        filters.push({
          field: 'amount_was_changed',
          operator: 'eq',
          value: amount_was_changed,
        })

        filters.push({
          field: 'id',
          operator: 'eq',
          value: id,
        })

        filters.push({
          field: 'idempotency_key',
          operator: 'eq',
          value: idempotency_key,
        })

        filters.push({
          field: 'card_number',
          operator: 'eq',
          value: card_number,
        })

        filters.push({
          field: 'client_id',
          operator: 'eq',
          value: client_id,
        })

        filters.push({
          field: 'scoring_client_id',
          operator: 'eq',
          value: scoring_client_id,
        })

        filters.push({
          field: 'payment_system',
          operator: 'eq',
          value: payment_system,
        })

        filters.push({
          field: 'rand',
          operator: 'eq',
          value: rand,
        })

        filters.push({
          field: 'merchants_ids',
          operator: 'in',
          value: merchants_ids,
        })

        filters.push({
          field: 'amount_gte',
          operator: 'eq',
          value: amount_gte,
        })

        filters.push({
          field: 'amount_lte',
          operator: 'eq',
          value: amount_lte,
        })

        filters.push({
          field: 'created_at_gte',
          operator: 'eq',
          value:
            created_at && created_at[0]
              ? dayjs(created_at[0]).tz(undefined, true).startOf('day').toISOString()
              : null,
        })

        filters.push({
          field: 'created_at_lte',
          operator: 'eq',
          value:
            created_at && created_at[1]
              ? dayjs.utc(created_at[1]).tz().endOf('day').toISOString()
              : null,
        })

        filters.push({
          field: 'approved_at_gte',
          operator: 'eq',
          value:
            approved_at && approved_at[0]
              ? dayjs(approved_at[0]).tz(undefined, true).startOf('day').toISOString()
              : null,
        })

        filters.push({
          field: 'approved_at_lte',
          operator: 'eq',
          value:
            approved_at && approved_at[1]
              ? dayjs.utc(approved_at[1]).tz().endOf('day').toISOString()
              : null,
        })

        filters.push({
          field: 'partners_ids',
          operator: 'in',
          value: partners_ids?.length > 0 ? partners_ids : undefined,
        })

        filters.push({
          field: 'scope',
          operator: 'eq',
          value: scope,
        })

        return filters
      },

      pagination: {
        pageSize: 20,
      },
    }
  )

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  const { data: canFilterByScope } = useCan({
    resource: 'operations',
    action: 'scope_filter',
  })

  const options: SelectProps['options'] = Object.keys(OperationStatusMap).map((key) => ({
    value: key,
    // @ts-ignore
    label: t(OperationStatusMap[key]),
  }))

  const { data: canViewPartners } = useCan({
    resource: 'partners',
    action: 'list',
  })

  const { data: canViewMerchants } = useCan({
    resource: 'merchants',
    action: 'filter',
  })

  const { data: canViewScoringClientId } = useCan({
    resource: 'scoring/clients',
    action: 'view_id',
  })

  const { data: canViewClientId } = useCan({
    resource: 'operations',
    action: 'view_client_id',
  })

  const { data: canViewPaymentSystem } = useCan({
    resource: 'operations',
    action: 'view_payment_system',
  })

  const { selectProps: partnersSelectProps } = useSelect<IPartner>({
    resource: 'partners',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
    queryOptions: {
      enabled: !!canViewPartners?.can,
    },
  })

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
    queryOptions: {
      enabled: !!canViewMerchants?.can,
    },
  })

  return (
    <>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          partners_ids: getDefaultFilter('partners_ids', filters, 'in'),
          statuses: getDefaultFilter('statuses', filters, 'in'),
          kind: getDefaultFilter('kind', filters),
          id: getDefaultFilter('id', filters),
          idempotency_key: getDefaultFilter('idempotency_key', filters),
          card_number: getDefaultFilter('card_number', filters),
          client_id: getDefaultFilter('client_id', filters),
          payment_system: getDefaultFilter('payment_system', filters),
          scoring_client_id: getDefaultFilter('scoring_client_id', filters),
          merchants_ids: getDefaultFilter('merchants_ids', filters),
          amount_lte: getDefaultFilter('amount_lte', filters),
          amount_gte: getDefaultFilter('amount_gte', filters),
          scope: getDefaultFilter('scope', filters),
          created_at: [
            getDefaultFilter('created_at_gte', filters) &&
              dayjs(getDefaultFilter('created_at_gte', filters)),
            getDefaultFilter('created_at_lte', filters) &&
              dayjs(getDefaultFilter('created_at_lte', filters)),
          ],
          approved_at: [
            getDefaultFilter('approved_at_gte', filters) &&
              dayjs(getDefaultFilter('approved_at_gte', filters)),
            getDefaultFilter('approved_at_lte', filters) &&
              dayjs(getDefaultFilter('approved_at_lte', filters)),
          ],
          created_by: getDefaultFilter('created_by', filters),
          amount_was_changed: getDefaultFilter('amount_was_changed', filters),
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('operations.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="idempotency_key"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('operations.filter.idempotency_key.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Button
            icon={filtersExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
            onClick={() => {
              filtersExpanded ? setFiltersExpanded(false) : setFiltersExpanded(true)
            }}
          >
            {t('operations.titles.filters')}
          </Button>
          <CreateButton
            style={{ marginLeft: 8 }}
            accessControl={{ hideIfUnauthorized: true }}
          />
          <ExportButton style={{ marginLeft: 8 }} />
        </div>
        <div
          style={{
            overflow: 'hidden',
            maxHeight: filtersExpanded ? 600 : 0,
            transition: 'max-height 1000ms',
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item style={{ marginBottom: 8 }}>
                <Form.Item name={'card_number'} style={{ marginBottom: 0 }}>
                  <Input
                    placeholder={t('operations.filter.card_number.placeholder')}
                    prefix={<SearchOutlined />}
                    allowClear
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            {canViewClientId?.can && (
              <Col span={11} offset={1}>
                <Form.Item style={{ marginBottom: 8 }}>
                  <Form.Item name={'client_id'} style={{ marginBottom: 0 }}>
                    <Input
                      placeholder={t('operations.filter.client_id.placeholder')}
                      prefix={<SearchOutlined />}
                      allowClear
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            {canViewScoringClientId?.can && (
              <Col span={12}>
                <Form.Item style={{ marginBottom: 8 }}>
                  <Form.Item name={'scoring_client_id'} style={{ marginBottom: 0 }}>
                    <Input
                      placeholder={t('operations.filter.scoring_client_id.placeholder')}
                      prefix={<SearchOutlined />}
                      allowClear
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item style={{ marginBottom: 8 }}>
            {canViewPartners?.can && (
              <Form.Item
                name="partners_ids"
                style={{
                  marginBottom: 0,
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                  marginRight: 16,
                }}
              >
                <Select
                  style={{ minWidth: 200 }}
                  allowClear
                  showSearch
                  placeholder={t('operations.filter.partner.placeholder')}
                  mode="multiple"
                  {...partnersSelectProps}
                />
              </Form.Item>
            )}
            {canViewMerchants?.can && (
              <Form.Item
                name="merchants_ids"
                style={{
                  marginBottom: 0,
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                }}
              >
                <Select
                  style={{ minWidth: 200 }}
                  allowClear
                  showSearch
                  placeholder={t('operations.filter.merchant.placeholder')}
                  mode="multiple"
                  {...merchantsSelectProps}
                  filterOption={(input, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Row>
            <Col span={7}>
              <Form.Item label={t('operations.filter.amount.label')} name={'amount'}>
                <Space.Compact>
                  <Form.Item noStyle name={'amount_gte'}>
                    <InputNumber
                      style={{ width: '50%' }}
                      placeholder={t('operations.filter.amount_gte.placeholder')}
                    />
                  </Form.Item>
                  <Form.Item noStyle name={'amount_lte'}>
                    <InputNumber
                      style={{ display: 'inline-block', width: '50%' }}
                      placeholder={t('operations.filter.amount_lte.placeholder')}
                    />
                  </Form.Item>
                </Space.Compact>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={t('operations.filter.status.label')} name="statuses">
                <Select
                  mode="multiple"
                  placeholder={t('operations.filter.status.placeholder')}
                  options={options}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label={t('operations.filter.created_at.label')}
                name="created_at"
              >
                <RangePicker
                  allowEmpty={[true, true]}
                  locale={locale}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item
                label={t('operations.filter.approved_at.label')}
                name="approved_at"
              >
                <RangePicker
                  allowEmpty={[true, true]}
                  locale={locale}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                name="created_by"
                label={t('operations.filter.created_by.label')}
              >
                <Select
                  options={[
                    { value: 'all', label: 'Все' },
                    { value: 'api', label: 'Только по АПИ' },
                    { value: 'manual', label: 'Только оператором' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                valuePropName="checked"
                name="amount_was_changed"
                label={t('operations.filter.amount_was_changed.label')}
              >
                <Checkbox>{t('operations.filter.amount_was_changed.label')}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item
                name="kind"
                label={t('operations.filter.kind.label')}
              >
                <Select
                  options={[
                    { value: 'all', label: 'Все' },
                    { value: 'income', label: 'Только пополнения' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                name="payment_system"
                label={t('operations.filter.payment_system.label')}
              >
                <Select
                  options={[
                    { value: 'all', label: 'Все' },
                    { value: 'spacepayments', label: 'spacepayments' },
                    { value: 'auris', label: 'auris' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        {canFilterByScope?.can &&
          // @ts-ignore
          tableQueryResult.data?.scopes && (
            <Form.Item name="scope">
              <ScopesFilter
                // @ts-ignore
                scopes={tableQueryResult.data?.scopes}
                resource={'operations'}
              />
            </Form.Item>
          )}
      </Form>
      <OperationsTable tableProps={tableProps} canViewMerchants={canViewMerchants} />
    </>
  )
}
