import React from 'react'
import { cardStatus } from 'interfaces'
import { Tag } from "antd";
import { useTranslate } from "@refinedev/core";

export const CardStatusMap = {
  created: "cards.statuses.created",
  active: "cards.statuses.active",
  suspended: "cards.statuses.suspended",
  blocked: "cards.statuses.blocked",
  overflowed: "cards.statuses.overflowed",
}

const CardStatus: React.FC<{status: cardStatus}> = ({status}) => {
  const t = useTranslate()

  const CardStatusColorMap = {
    created: 'blue',
    active: 'green',
    suspended: 'orange',
    blocked: 'red',
    overflowed: 'gray',
  }

  return(
    <Tag
      color={ CardStatusColorMap[status] }
      style={{ fontSize: 10 }}
    >
      { t(CardStatusMap[status]) }
    </Tag>
  )
}

export default CardStatus
