import { UAParser } from 'ua-parser-js'
import { IResourceComponentsProps, useTranslate } from '@refinedev/core'

import { Table, TableProps } from 'antd'
import { IAccessToken } from 'interfaces'
import { DeleteButton } from '@refinedev/antd'
import DateField from 'components/DateField'

export const AccessTokensTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IAccessToken>
    showActions: boolean
  }
> = ({ tableProps, showActions }) => {
  const t = useTranslate()

  return (
    <Table {...tableProps} rowKey="id" pagination={false}>
      <Table.Column<IAccessToken>
        dataIndex="created_at"
        ellipsis
        key="created_at"
        title={t('access_tokens.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IAccessToken>
        dataIndex="remote_ip"
        ellipsis
        key="remote_ip"
        title={t('access_tokens.fields.remote_ip')}
      />
      <Table.Column<IAccessToken>
        dataIndex="device"
        ellipsis
        key="device"
        title={t('access_tokens.fields.device')}
        render={(_, record) => {
          return `${UAParser(record.user_agent).device.vendor} ${
            UAParser(record.user_agent).device.model
          }`
        }}
      />
      <Table.Column<IAccessToken>
        dataIndex="os"
        ellipsis
        key="os"
        title={t('access_tokens.fields.os')}
        render={(_, record) => {
          return `${UAParser(record.user_agent).os.name} (${
            UAParser(record.user_agent).os.version
          })`
        }}
      />
      <Table.Column<IAccessToken>
        dataIndex="browser"
        ellipsis
        key="browser"
        title={t('access_tokens.fields.browser')}
        render={(_, record) => {
          return `${UAParser(record.user_agent).browser.name}`
        }}
      />
      {showActions && (
        <Table.Column<IAccessToken>
          dataIndex="actions"
          ellipsis
          key="actions"
          render={(_, record) => (
            <DeleteButton
              size="middle"
              recordItemId={record.id}
              resource="access_tokens"
            />
          )}
        />
      )}
    </Table>
  )
}
