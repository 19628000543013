import { IResourceComponentsProps, useTranslate } from '@refinedev/core'

import { Table, TableProps } from 'antd'
import { IPaymentRequisiteBlock } from 'interfaces'

import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import { formattedAmount, formattedCardNumber } from 'utils'
import { OperationStatus } from 'pages/cashin/OperationStatus'

const PaymentRequisiteBlocksTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IPaymentRequisiteBlock>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 100,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('payment_requisite_blocks.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['operation', 'merchant', 'name']}
        key="merchant"
        title={t('payment_requisite_blocks.fields.merchant')}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex="block_from"
        key="block_from"
        title={t('payment_requisite_blocks.fields.block_from')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex="block_to"
        key="block_to"
        title={t('payment_requisite_blocks.fields.block_to')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IPaymentRequisiteBlock>
        align="right"
        dataIndex="amount"
        key="amount"
        title={t('payment_requisite_blocks.fields.amount')}
        render={formattedAmount}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['gateway', 'name']}
        key="gateway"
        title={t('payment_requisite_blocks.fields.gateway')}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['payment_requisite', 'card_number']}
        key="payment_requisite"
        ellipsis
        title={t('payment_requisite_blocks.fields.payment_requisite')}
        render={(value) => formattedCardNumber(value)}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['operation', 'id']}
        key="operation_id"
        ellipsis
        title={t('payment_requisite_blocks.fields.operation_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['operation', 'client_id']}
        key="operation_client_id"
        ellipsis
        title={t('payment_requisite_blocks.fields.client_id')}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['operation', 'status']}
        key="operation_status"
        title={t('payment_requisite_blocks.fields.operation_status')}
        render={(value) => <OperationStatus status={value} />}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['scoring_client', 'color']}
        key="scoring_client_color"
        title={t('payment_requisite_blocks.fields.scoring_client_color')}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['scoring_client', 'count']}
        key="scoring_client_count"
        title={t('payment_requisite_blocks.fields.scoring_client_count')}
      />
      <Table.Column<IPaymentRequisiteBlock>
        dataIndex={['scoring_client', 'id']}
        key="scoring_client_id"
        title={t('payment_requisite_blocks.fields.scoring_client_id')}
      />
    </Table>
  )
}

export default PaymentRequisiteBlocksTable
