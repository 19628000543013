import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'

import { IUser } from 'interfaces'

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IUser>()

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label={t('users.fields.telegram_id')} name="telegram_id">
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
