import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { List, useTable } from '@refinedev/antd'
import { IPaymentRequisiteBlock } from 'interfaces'
import PaymentRequisiteBlocksTable from './list_table'

export const PaymentRequisiteBlocksList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IPaymentRequisiteBlock>({
    pagination: {
      pageSize: 100,
    },
  })

  return (
    <List>
      <PaymentRequisiteBlocksTable tableProps={tableProps}></PaymentRequisiteBlocksTable>
    </List>
  )
}
