import { useApiUrl, useCustomMutation, useInvalidate, useUpdate } from '@refinedev/core'
import { IOperation } from 'interfaces'
import { OperationForm } from './OperationForm'

interface MutateOperationResponse {
  success: boolean
}

export const OperationControls: React.FC<{ record: IOperation }> = ({ record }) => {
  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<MutateOperationResponse>()
  const { mutate } = useUpdate<MutateOperationResponse>()
  const invalidate = useInvalidate()

  const onComment = ({ comment }: { comment: string }) => {
    mutate(
      {
        resource: 'operations',
        id: record.id,
        values: {
          comment,
        },
      },
      {
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
          invalidate({
            resource: 'operations',
            invalidates: ['detail'],
            id: record.id,
          })
        },
      }
    )
  }

  const onPaymentRequisiteChange = (payment_requisite_id: string) => {
    customMutate(
      {
        url: `${apiUrl}/operations/${record.id}/update_payment_requisite`,
        method: 'post',
        values: {
          payment_requisite_id,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'operations',
            invalidates: ['detail'],
            id: record.id,
          })
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const onProposeAmount = (proposed_amount: number) => {
    customMutate(
      {
        url: `${apiUrl}/operations/${record.id}/set_proposed_amount`,
        method: 'post',
        values: {
          proposed_amount,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'operations',
            invalidates: ['detail'],
            id: record.id,
          })
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const onOverrideApprovedAmount = (approved_amount: number) => {
    customMutate(
      {
        url: `${apiUrl}/operations/${record.id}/override_approved_amount`,
        method: 'post',
        values: {
          approved_amount,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'operations',
            invalidates: ['detail'],
            id: record.id,
          })
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const onApprove = ({
    comment,
    approvedAmount,
  }: {
    comment: string
    approvedAmount: number
  }) => {
    customMutate(
      {
        url: `${apiUrl}/operations/${record.id}/manually_approve`,
        method: 'post',
        values: {
          comment,
          approved_amount: approvedAmount,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'operations',
            invalidates: ['detail'],
            id: record.id,
          })
          invalidate({
            resource: 'operations',
            invalidates: ['list'],
          })
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const onReject = ({ comment }: { comment: string }) => {
    customMutate(
      {
        url: `${apiUrl}/operations/${record.id}/reject`,
        method: 'post',
        values: {
          comment,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'operations',
            invalidates: ['detail'],
            id: record.id,
          })
          invalidate({
            resource: 'operations',
            invalidates: ['list'],
          })
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  const onRejectApproved = ({ comment }: { comment: string }) => {
    customMutate(
      {
        url: `${apiUrl}/operations/${record.id}/reject_approved`,
        method: 'post',
        values: {
          comment,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: 'operations',
            invalidates: ['detail'],
            id: record.id,
          })
          invalidate({
            resource: 'operations',
            invalidates: ['list'],
          })
          invalidate({
            resource: 'events',
            invalidates: ['list'],
          })
        },
      }
    )
  }

  return (
    <OperationForm
      onComment={onComment}
      onApprove={onApprove}
      onReject={onReject}
      onRejectApproved={onRejectApproved}
      onPaymentRequisiteChange={onPaymentRequisiteChange}
      onProposeAmount={onProposeAmount}
      onOverrideApprovedAmount={onOverrideApprovedAmount}
      record={record}
    />
  )
}
