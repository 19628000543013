import React from 'react'
import { Typography, Row, Col } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons'

import { IOperation } from 'interfaces'
import { ActionsButtons } from './ActionsButtons'

import "./styles.css"

const { Text } = Typography

export const CancelledOperation: React.FC<IOperation> = ({ id, actions }) => (
  <Row align='stretch' justify='space-around'>
    <Col span={4} offset={2}>
      <CloseCircleOutlined style={{ fontSize: 24 }}/>
    </Col>
    <Col span={18}>
      <Text>Оплата была отменена</Text>
    </Col>
    <ActionsButtons id={id} actions={actions} />
  </Row>
)
