import { Button, Space, Statistic, Typography } from "antd"
import { useTranslate } from "@refinedev/core"

const { Text } = Typography
const { Countdown } = Statistic

type TimerProps = {
  expiresAt: any
  canRenew: boolean
  onExpire: () => void
  onRenew: () => void
  renewButtonIsLoading: boolean
}

export const Timer: React.FC<TimerProps> = ({
  expiresAt,
  onExpire,
  canRenew,
  onRenew,
  renewButtonIsLoading = false,
}) => {
  const t = useTranslate()
  const value = new Date(expiresAt).getTime()

  return (
    <Space>
      <Text>{t('components.timer.text')}</Text>
      <Countdown
        format="mm:ss"
        valueStyle={{ fontSize: 14 }}
        value={value}
        onFinish={onExpire}
      />
      {canRenew && (
        <Button type="link" onClick={onRenew} loading={renewButtonIsLoading}>
          {t('components.timer.renew')}
        </Button>
      )}
    </Space>
  )
}
