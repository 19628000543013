import { useTranslate } from '@refinedev/core'
import { Modal } from 'antd'
import { NewItem } from './new_item'

export const NewItemModal: React.FC<{
  open: boolean
  onCancel: () => void
  onSuccess: () => void
  feed_id: string
  position: number | undefined
}> = ({ open, onCancel, onSuccess, feed_id, position }) => {
  const t = useTranslate()

  return (
    <Modal
      destroyOnClose={true}
      title={t('feed_items.titles.create')}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <NewItem feed_id={feed_id} onSuccess={onSuccess} position={position} />
    </Modal>
  )
}
