import React from 'react'
import { IShift } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'

export const Stats: React.FC<{ shift: IShift }> = ({ shift }) => {
  return (
    <div style={{ lineHeight: '24px', fontSize: '14px', paddingLeft: '10px' }}>
      <span>
        Пополнений{' '}
        {formattedCurrencyAmount(
          shift.approved_operations_amount,
          0,
          shift.partner.currency
        )}{' '}
        ({shift.approved_operations_count})
      </span>
      {/* {shift.approved_payouts_count > 0 && ( */}
      <>
        <span> / </span>
        <span>
          Пейаутов{' '}
          {formattedCurrencyAmount(
            shift.approved_payouts_amount,
            0,
            shift.partner.currency
          )}{' '}
          ({shift.approved_payouts_count})
        </span>
      </>
      {/* )} */}
      { shift.partner.deposit_balance && (
      <>
        <span> / </span>
        <span>
          Баланс депозита{' '}
          {formattedCurrencyAmount(
            shift.partner.deposit_balance,
            0,
            shift.partner.currency
          )}{' '}
        </span>
      </>
      )}
    </div>
  )
}
