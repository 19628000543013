import {
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useTranslate,
} from '@refinedev/core'

import { Button, Table, TableProps, Typography } from 'antd'
import { GenericResponse, IWebhook } from 'interfaces'

import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'

const ResendButton: React.FC<{ record: IWebhook }> = ({ record }) => {
  const t = useTranslate()
  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<GenericResponse>()

  const onResend = () => {
    customMutate(
      {
        url: `${apiUrl}/webhooks/${record.id}/resend`,
        method: 'post',
        values: {},
        successNotification: (data, values) => {
          return {
            message: t('webhooks.notifications.resent.message'),
            description: t('webhooks.notifications.resent.description'),
            type: 'success',
          }
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {},
      }
    )
  }

  return (
    <Button size="small" onClick={() => onResend()}>
      {t('webhooks.actions.resend')}
    </Button>
  )
}

const WebhooksTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IWebhook>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IWebhook>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('webhooks.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IWebhook>
        dataIndex="created_at"
        key="created_at"
        title={t('webhooks.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IWebhook>
        dataIndex="delivered"
        key="delivered"
        title={t('webhooks.fields.delivered')}
        render={(value) => <BooleanTag value={value} />}
      />
      <Table.Column<IWebhook>
        ellipsis
        dataIndex={['merchant', 'name']}
        key="merchant_name"
        title={t('webhooks.fields.merchant_name')}
      />
      <Table.Column<IWebhook>
        ellipsis
        dataIndex={'url'}
        key="url"
        title={t('webhooks.fields.url')}
      />
      <Table.Column<IWebhook>
        dataIndex="event"
        key="event"
        title={t('webhooks.fields.event')}
        render={(value) => (
          <Typography.Text code ellipsis>
            {value}
          </Typography.Text>
        )}
      />
      <Table.Column<IWebhook>
        dataIndex="payload"
        key="payload"
        title={t('webhooks.fields.payload')}
        render={(value) => <pre>{JSON.stringify(value, null, 2)}</pre>}
      />
      <Table.Column<IWebhook>
        dataIndex="response_code"
        key="response_code"
        title={t('webhooks.fields.response_code')}
      />
      <Table.Column<IWebhook>
        title="Actions"
        fixed="right"
        dataIndex="actions"
        render={(_, record) => <ResendButton record={record} />}
      />
    </Table>
  )
}

export default WebhooksTable
