import { EditButton, ShowButton, useTable } from '@refinedev/antd'
import { Table, Tag } from 'antd'
import {
  useApiUrl,
  useCan,
  useCustomMutation,
  useInvalidate,
  useTranslate,
} from '@refinedev/core'
import { IFeed, IShift } from 'interfaces'
import { useEffect, useState } from 'react'
import { formattedCardNumber, formattedAccountNumber, formattedCurrencyAmount } from 'utils'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'
import { MenuOutlined } from '@ant-design/icons'

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

interface MutateResponse {
  success: boolean
}

const DragTable: React.FC<{
  shift: IShift
}> = ({ shift }) => {
  const t = useTranslate()
  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<MutateResponse>()
  const invalidate = useInvalidate()

  const { data: canChangePosition } = useCan({
    resource: 'feeds',
    action: 'change_position',
  })

  const [data, setData] = useState<IFeed[]>([])

  const { tableProps, tableQueryResult } = useTable<IFeed>({
    resource: 'feeds',
    syncWithLocation: false,

    pagination: {
      pageSize: 10000,
    },

    filters: {
      permanent: [
        {
          field: 'shift_id',
          operator: 'eq',
          value: shift.id,
        },
      ],
    },
  })

  const remoteData = tableQueryResult.data?.data

  useEffect(() => {
    //@ts-ignore
    remoteData && setData(remoteData)
  }, [remoteData])

  useEffect(() => {
    const timer = setInterval(() => {
      shift &&
        shift.status !== 'finished' &&
        invalidate({
          resource: 'feeds',
          invalidates: ['list'],
        })
    }, 5000)
    return () => clearInterval(timer)
  }, [shift, invalidate])

  const updatePosition = (id: string, position: number) => {
    customMutate(
      {
        url: `${apiUrl}/feeds/${id}`,
        method: 'patch',
        values: {
          position: position,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {},
      }
    )
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over) return
    if (active.id !== over.id) {
      setData((previous: IFeed[]) => {
        const activeIndex = data.findIndex((item) => item.id === active.id)
        const overIndex = data.findIndex((item) => item.id === over?.id)
        return arrayMove(previous, activeIndex, overIndex)
      })
      const activeItem = data.find((item) => item.id === active.id)
      const overItem = data.find((item) => item.id === over.id)
      updatePosition(activeItem!.id, overItem!.position)
    }
  }

  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    }
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === 'sort') {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: 'none', cursor: 'move' }}
                  {...listeners}
                />
              ),
            })
          }
          return child
        })}
      </tr>
    )
  }

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={data.map((item: any) => item.id) || []}
        strategy={verticalListSortingStrategy}
      >
        <Table
          {...tableProps}
          rowKey="id"
          pagination={false}
          size="large"
          dataSource={data}
          components={{
            body: {
              row: Row,
            },
          }}
        >
          {canChangePosition?.can && <Table.Column<IFeed> dataIndex="sort" key="sort" />}
          <Table.Column<IFeed>
            dataIndex={['payment_requisite', 'bank_code']}
            key="bank_code"
            render={(value) => (
              <div
                style={{
                  width: 16,
                  height: 9,
                  borderRadius: 2,
                  backgroundColor: value === 'sber' ? 'rgb(33, 160, 56)' : 'black',
                }}
              ></div>
            )}
          />
          <Table.Column<IFeed>
            dataIndex={['payment_requisite', 'card_number']}
            key="card_number"
            ellipsis
            title={t('feeds.fields.card_number')}
            render={(value) => <code>{formattedCardNumber(value)}</code>}
          />
          <Table.Column<IFeed>
            dataIndex={['payment_requisite', 'account']}
            key="account"
            ellipsis
            title={t('feeds.fields.account')}
            render={(value) => <code>{formattedAccountNumber(value)}</code>}
          />
          <Table.Column<IFeed>
            align="right"
            dataIndex="items_count"
            key="items_count"
            title={t('feeds.fields.items_count')}
          />
          <Table.ColumnGroup<IFeed>
            dataIndex="balances"
            key="balances"
            title={t('feeds.fields.balances')}
          >
            <Table.Column<IFeed>
              align="right"
              dataIndex="balance"
              key="balance"
              title={
                <div>
                  {t('feeds.fields.balance')}
                  <br />
                  {tableQueryResult.data?.stats?.balance !== '0.0'
                    ? formattedCurrencyAmount(
                        tableQueryResult.data?.stats?.balance,
                        2,
                        shift.partner.currency
                      )
                    : '–'}
                </div>
              }
              render={(value) =>
                value && formattedCurrencyAmount(value, 2, shift.partner.currency)
              }
            />
            <Table.Column<IFeed>
              align="right"
              dataIndex="start_balance"
              key="start_balance"
              title={
                <div>
                  {t('feeds.fields.start_balance')}
                  <br />
                  {tableQueryResult.data?.stats?.start_balance !== '0.0'
                    ? formattedCurrencyAmount(
                        tableQueryResult.data?.stats?.start_balance,
                        2,
                        shift.partner.currency
                      )
                    : '–'}
                </div>
              }
              render={(value) =>
                value && formattedCurrencyAmount(value, 2, shift.partner.currency)
              }
            />
            <Table.Column<IFeed>
              align="right"
              dataIndex="end_balance"
              key="end_balance"
              title={
                <div>
                  {t('feeds.fields.end_balance')}
                  <br />
                  {tableQueryResult.data?.stats?.end_balance !== '0.0'
                    ? formattedCurrencyAmount(
                        tableQueryResult.data?.stats?.end_balance,
                        2,
                        shift.partner.currency
                      )
                    : '–'}
                </div>
              }
              render={(value) =>
                value && formattedCurrencyAmount(value, 2, shift.partner.currency)
              }
            />
          </Table.ColumnGroup>
          <Table.Column<IFeed>
            align="right"
            dataIndex="problems_count"
            key="problems_count"
            title={t('feeds.fields.problems_count')}
            render={(value) => <Tag color={value === 0 ? 'green' : 'red'}>{value}</Tag>}
          />
          <Table.Column<IFeed>
            align="right"
            dataIndex="waiting_for_receipt_count"
            key="waiting_for_receipt_count"
            title={t('feeds.fields.waiting_for_receipt_count')}
            render={(value) => (
              <Tag color={value === 0 ? 'green' : 'yellow'}>{value}</Tag>
            )}
          />
          <Table.Column<IFeed>
            dataIndex="show"
            render={(_, record) => (
              <ShowButton resource="feeds" size="small" recordItemId={record.id} />
            )}
          />
          <Table.Column<IFeed>
            dataIndex="edit"
            render={(_, record) => (
              <EditButton resource="feeds" size="small" recordItemId={record.id} />
            )}
          />
        </Table>
      </SortableContext>
    </DndContext>
  )
}

export const FeedsList: React.FC<{ shift: IShift }> = ({ shift }) => {
  return <DragTable shift={shift} />
}
