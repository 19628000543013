import React from 'react'
import { Typography, Row, Col } from "antd";
import { CheckCircleOutlined } from '@ant-design/icons'

import { ActionsButtons } from './ActionsButtons'

import "./styles.css"
import { IOperation } from 'interfaces'

const { Text } = Typography

export const ExecutedOperation: React.FC<IOperation> = ({id, actions}) => (
  <>
    <Row align='stretch' justify='space-around'>
      <Col span={4} offset={2}>
        <CheckCircleOutlined style={{ fontSize: 24 }}/>
      </Col>
      <Col span={18}>
        <Text>Оплата подтверждена</Text>
      </Col>
    </Row>
    <ActionsButtons id={id} actions={actions} />
  </>
)
