import React from 'react'
import { IResourceComponentsProps } from '@refinedev/core'
import { List, useTable } from '@refinedev/antd'
import { IExport } from 'interfaces'
import ExportsTable from './list_table'

export const ExportsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IExport>()

  return (
    <List>
      <ExportsTable tableProps={tableProps}></ExportsTable>
    </List>
  )
}
