import { CheckCircleTwoTone } from '@ant-design/icons'
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useGetIdentity,
  useTranslate,
} from '@refinedev/core'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Card, Input, QRCode, Space, Typography } from 'antd'
import { USER_KEY } from '../../constants'
import { ICurrentUser } from 'interfaces'
import { useState } from 'react'

interface OtpSecretResponse {
  secret: string
  provisioning_uri: string
}

export const TwoFactor: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity<ICurrentUser>()
  const t = useTranslate()
  const queryClient = useQueryClient()
  const apiUrl = useApiUrl()
  const { mutate } = useCustomMutation<OtpSecretResponse>()
  const [otpSecret, setOtpSecret] = useState<OtpSecretResponse>({
    secret: '',
    provisioning_uri: '',
  })
  const [code, setCode] = useState('')

  const generate_otp_secret = () => {
    mutate(
      {
        url: `${apiUrl}/profile/generate_otp_secret`,
        method: 'post',
        values: {},
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          setOtpSecret(data.data)
        },
      }
    )
  }

  const enable_two_factor = () => {
    mutate(
      {
        url: `${apiUrl}/profile/enable_two_factor`,
        method: 'post',
        values: {
          code,
          secret: otpSecret.secret,
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
          let userInfo = localStorage.getItem(USER_KEY)
          if (userInfo) {
            localStorage.setItem(
              USER_KEY,
              JSON.stringify(
                Object.assign(JSON.parse(userInfo), {
                  two_factor_verification_enabled: true,
                })
              )
            )
          }

          queryClient.invalidateQueries(['getUserIdentity'])
        },
      }
    )
  }

  return (
    <Card style={{ marginTop: 12 }} title={t('profile.titles.two_factor')}>
      {user?.two_factor_verification_enabled ? (
        <Space>
          <CheckCircleTwoTone style={{ fontSize: 24 }} />
          Второй фактор подключен
        </Space>
      ) : (
        <>
          <Typography.Text>
            Настройте двухфакторную авторизацию для большей безопасности
          </Typography.Text>
          <br />
          {otpSecret.secret.length ? (
            <Space style={{ marginTop: 12 }} direction={'vertical'}>
              <QRCode value={otpSecret.provisioning_uri} size={200} />
              <Input placeholder="000000" onChange={(e) => setCode(e.target.value)} />
              <Button
                type="primary"
                style={{ marginTop: 12 }}
                block
                disabled={code?.length !== 6}
                onClick={enable_two_factor}
              >
                Сохранить
              </Button>
            </Space>
          ) : (
            <Button
              type="default"
              style={{ marginTop: 12 }}
              onClick={generate_otp_secret}
            >
              Настроить
            </Button>
          )}
        </>
      )}
    </Card>
  )
}
