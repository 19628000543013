import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Edit, useForm } from '@refinedev/antd'
import { Form, InputNumber } from 'antd'

import { IFeed } from 'interfaces'

export const FeedEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { formProps, saveButtonProps } = useForm<IFeed>({
    action: 'edit',
    redirect: 'show',
  })

  return (
    <Edit saveButtonProps={saveButtonProps} headerButtons={<></>}>
      <Form
        {...formProps}
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item label={t('feeds.fields.start_balance')} name="start_balance">
          <InputNumber
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
          />
        </Form.Item>
        <Form.Item label={t('feeds.fields.end_balance')} name="end_balance">
          <InputNumber
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
          />
        </Form.Item>
      </Form>
    </Edit>
  )
}
