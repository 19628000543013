import React, { useState } from 'react'
import { GenericResponse } from 'interfaces'
import { Button, Form, InputNumber } from 'antd'
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core'

export const Dev: React.FC<{ merchantId: string }> = ({ merchantId }) => {
  const { mutate: updateResource } = useCustomMutation<GenericResponse>()
  const apiUrl = useApiUrl()
  const t = useTranslate()
  const [amount, setAmount] = useState<number | null>(0)

  const payout = () => {
    updateResource(
      {
        url: `${apiUrl}/dev/payouts`,
        method: 'post',
        values: {
          amount,
          merchant_id: merchantId,
        },
        successNotification: (data, values) => {
          return {
            message: t('test'),
            description: JSON.stringify(data?.data),
            type: 'success',
          }
        },
      },
      {
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {},
      }
    )
  }

  return (
    <>
      <Form layout="vertical">
        <Form.Item name="amount">
          <InputNumber
            controls={false}
            min={1}
            style={{ width: '100%' }}
            value={amount}
            placeholder="Сумма для вывода"
            onChange={(value) => setAmount(value)}
          />
        </Form.Item>

        <Button htmlType="submit" onClick={() => payout()} type="primary">
          {t('test')}
        </Button>
      </Form>
    </>
  )
}
