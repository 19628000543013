import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Switch, Typography } from 'antd'
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core'
import { GenericResponse, IShift } from 'interfaces'

export const PayinControl: React.FC<{ shift: IShift; style: React.CSSProperties }> = ({
  shift,
  style,
}) => {
  const t = useTranslate()
  const queryClient = useQueryClient()
  const { mutate: updateResource } = useCustomMutation<GenericResponse>()
  const apiUrl = useApiUrl()
  const [enabled, setEnabled] = useState<boolean>(shift.payins_status === 'started')
  const [loading, setLoading] = useState(false)

  const toggleEnabled = (mode: boolean) => {
    setLoading(true)
    setEnabled(mode)

    const action = mode ? 'resume' : 'pause'

    setTimeout(() => {
      updateResource(
        {
          url: `${apiUrl}/shifts/${shift.id}/${action}`,
          method: 'post',
          values: {
            mode: 'payins',
          },
          successNotification: (data, values) => {
            return {
              message: t(`shifts.notifications.payins_enabled.${mode}.message`),
              description: t(`shifts.notifications.payins_enabled.${mode}.description`),
              type: 'success',
            }
          },
        },
        {
          onError: (error, variables, context) => {},
          onSuccess: (data, variables, context) => {
            setLoading(false)
            queryClient.invalidateQueries(['shifts', 'current'])
          },
        }
      )
    }, 300)
  }

  return (
    <span style={style}>
      <Switch
        loading={loading}
        size="small"
        onChange={() => {
          enabled ? toggleEnabled(false) : toggleEnabled(true)
        }}
        defaultChecked={enabled}
      />
      <Typography.Text style={{ marginLeft: 8 }}>
        {t('shifts.form.toggle_payins_enabled')}
      </Typography.Text>
    </span>
  )
}
