import React from 'react'
import { Show, useTable } from '@refinedev/antd'

import { IOperation } from 'interfaces'
import OperationsTable from 'pages/operations/list_table'
import { useCan, useTranslate } from '@refinedev/core'

export const OperationsList: React.FC<any> = ({ merchantId, scoringClientId }) => {
  const t = useTranslate()
  const { tableProps } = useTable<IOperation>({
    resource: 'operations',

    filters: {
      permanent: [
        {
          field: 'merchant_id',
          operator: 'eq',
          value: merchantId,
        },
        {
          field: 'scoring_client_id',
          operator: 'eq',
          value: scoringClientId,
        },
      ],
    },
  })

  const { data: canViewMerchants } = useCan({
    resource: 'merchants',
    action: 'filter',
  })

  return (
    <Show
      title={`${t('scoring/clients.titles.operations')} (${
        tableProps.pagination && tableProps.pagination.total
      })`}
      headerButtons={<></>}
      canEdit={false}
      goBack={false}
      breadcrumb={false}
      wrapperProps={{ style: { marginTop: 20 } }}
    >
      <OperationsTable tableProps={tableProps} canViewMerchants={canViewMerchants} />
    </Show>
  )
}
