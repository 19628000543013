import React from 'react'
import { shiftStatus } from 'interfaces'
import { Tag } from "antd";
import { useTranslate } from "@refinedev/core";

export const ShiftStatusMap = {
  started: "shifts.statuses.started",
  paused: "shifts.statuses.paused",
  finished: "shifts.statuses.finished"
}

const ShiftStatus: React.FC<{status: shiftStatus}> = ({status}) => {
  const t = useTranslate()

  const ShiftStatusColorMap = {
    started: 'green',
    paused: 'orange',
    finished: 'gray'
  }

  return(
    <Tag
      color={ ShiftStatusColorMap[status] }
      style={{ fontSize: 12 }}
    >
      { t(ShiftStatusMap[status]) }
    </Tag>
  )
}

export default ShiftStatus
