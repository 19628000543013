import React from 'react'
import { cardColor } from 'interfaces'
import { Tag } from "antd";
import { useTranslate } from "@refinedev/core";

export const CardColorMap = {
  green: "cards.colors.green",
  red: "cards.colors.red",
}

const CardColor: React.FC<{color: cardColor}> = ({color}) => {
  const t = useTranslate()

  const CardColorMap = {
    green: 'green',
    red: 'red',
  }

  return(
    <Tag
      color={ CardColorMap[color] }
      style={{ fontSize: 10 }}
    >
      { t(CardColorMap[color]) }
    </Tag>
  )
}

export default CardColor
