import { useGetIdentity, useTranslate } from '@refinedev/core'
import {
  Button,  
  Form,
  FormProps,
  Modal,
  ModalProps,
  Radio, 
  Select,   
  Spin,
  Typography,
} from 'antd'
import { ICurrentUser } from 'interfaces'
import { Link } from 'react-router-dom'

const ExportForm: React.FC<{ createFormProps: FormProps<{}> }> = ({
  createFormProps,
}) => {
  const t = useTranslate()

  const { data: user } = useGetIdentity<ICurrentUser>()

  const initialValues = {
    operations_kind: 'incomes',
    period: 'last_15_minutes'
  }

  return user ? (
    <>
      <Form {...createFormProps} layout="vertical" initialValues={initialValues}>
        <Form.Item name={'operations_kind'}>
          <Radio.Group buttonStyle="solid" size="middle" value={'incomes'}>
            <Radio.Button style={{ width: 150 }} value="incomes">
              {t('partners_dashboard.form.operations_kind.incomes.label')}
            </Radio.Button>
            <Radio.Button style={{ width: 150 }} value="payouts">
              {t('partners_dashboard.form.operations_kind.payouts.label')}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          style={{ width: 300 }}
          name="period"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t('partners_dashboard.form.period.placeholder')}
            options={[
              {
                label: t('partners_dashboard.form.period.last_15_minutes.label'),
                value: 'last_15_minutes',
              },
              {
                label: t('partners_dashboard.form.period.last_hour.label'),
                value: 'last_hour',
              },
              {
                label: t('partners_dashboard.form.period.last_day.label'),
                value: 'last_day',
              },
              {
                label: t('partners_dashboard.form.period.from_the_beginning.label'),
                value: 'from_the_beginning',
              },
            ]}
          />          
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" loading={false} block>
            {t('partners_dashboard.titles.export')}
          </Button>
        </Form.Item>
      </Form>      
    </>
  ) : (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <Spin tip="Loading" size="large" />
    </div>
  )
}

const ExportModal: React.FC<{
  ready: boolean
  createModalProps: ModalProps
  createFormProps: FormProps<{}>
  onCancel: () => void
}> = ({ ready, createModalProps, createFormProps, onCancel }) => {
  const t = useTranslate()

  return (
    <Modal
      {...createModalProps}
      title={t('partners_dashboard.titles.export')}
      width={'fit-content'}
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      {createModalProps.open ? (
        ready ? (
          <Typography.Text>
            {t('partners_dashboard.titles.export_ready')}{' '}
            <Link to={'/exports'}>{t('exports.exports')}</Link>
          </Typography.Text>
        ) : (
          <ExportForm createFormProps={createFormProps} />
        )
      ) : (
        <></>
      )}
    </Modal>
  )
}

export default ExportModal