import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
  GetManyResponse,
  useCan,
  CrudFilters,
  useList,
  useApiUrl,
  getDefaultFilter,
} from '@refinedev/core'

import { EditButton, List, ShowButton, useTable, useSelect } from '@refinedev/antd'

import DateField from 'components/DateField'
import ScopesFilter from 'components/ScopesFilter'

import { Table, Space, Typography, Skeleton, Form, Input, Select } from 'antd'
import { IPaymentCascade } from 'interfaces'

import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'
import {
  formattedCorrectedPercent,
  formattedCurrencyAmount,
  formattedPercent,
} from 'utils'
import { SearchOutlined } from '@ant-design/icons'

import PropTypes from 'prop-types'
import ListSort from '../../components/ListSort/list_sort'
import '../../components/ListSort/list_sort.css'

import { TOKEN_KEY } from '../../constants'

const { Text } = Typography

export const PaymentCascadesList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const apiUrl = useApiUrl()

  const { tableProps, searchFormProps, tableQueryResult, filters } = useTable<IPaymentCascade>({
    onSearch: (params) => {
      // @ts-ignore
      const { scope } = params
      const cardsFilters: CrudFilters = []

      cardsFilters.push({
        field: 'scope',
        operator: 'eq',
        value: scope,
      })

      return cardsFilters
    },
    pagination: {
      pageSize: 1000,
    },
    filters: {
      initial: [
        {
          field: 'scope',
          operator: 'eq',
          value: 'income',
        },
      ],
    },
  })


  const { data: canCreatePaymentCascade } = useCan({
    resource: 'payment_cascades',
    action: 'create',
  })


  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  if (tableQueryResult?.isLoading) {
    return <div>Loading...</div>;
  }


  const childrenToRender = tableQueryResult?.data?.data.map((item, i) => {
      const {
        id, payment_system, kind, traffic_percentage, all_merchants, all_gateways, merchant, gateway
      } = item;
      return (
        <div id={id} key={i} className="list-sort-demo-list">

          <div className="list-sort-demo-text">
            <h1>{payment_system}</h1>
            <p>
              {traffic_percentage}%
              &nbsp; | &nbsp; 
              {t('payment_cascades.fields.all_merchants')}: {all_merchants === true ? 'Да' : 'Нет'}
              &nbsp; | &nbsp; 
              {t('payment_cascades.fields.all_gateways')}: {all_gateways === true ? 'Да' : 'Нет'}
              &nbsp; | &nbsp; 
              {t('payment_cascades.fields.merchant')}: {merchant.name}
              &nbsp; | &nbsp; 
              {t('payment_cascades.fields.gateway')}: {gateway.name}
            </p>
          </div>
          <Space>
            <ShowButton size="small" recordItemId={id} />
          </Space>
        </div>
      );
    });

  const onListUpdate = (value: any) => {
    if (!(value instanceof MouseEvent)) {
      return
    }
    if (!(value.type === "mouseup")) {
      return
    }

    const listItems = Array.from(document.querySelectorAll('.list-sort-demo-list'));

    interface CascadeOrder {
        [key: string]: number;
    }
    const dataToSend: CascadeOrder = {}
    listItems.forEach((item, index) => {
      const id = item.getAttribute('id');
      if (id) {
        dataToSend[id] = index + 1
      }
    });
    const jsonData = JSON.stringify({priority: dataToSend});
    fetch(`${apiUrl}/payment_cascades/update_priority`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
    },
    body: jsonData
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
  })
  .catch(error => {
    console.error('There was a problem saving the data:', error);
  });
  }

  return (
    <>
    <List>
      <Form
        {...searchFormProps}
        layout="horizontal"
        onValuesChange={() => {
          searchFormProps.form?.submit()
        }}
        initialValues={{
          scope: getDefaultFilter('scope', filters, 'eq'),
        }}
      >
        {
          // @ts-ignore
          tableQueryResult.data?.scopes && (
            <Form.Item name="scope">
              <ScopesFilter
                // @ts-ignore
                scopes={tableQueryResult.data?.scopes}
                resource={'payment_cascades'}
              />
            </Form.Item>
          )
        }
      </Form>
    </List>

    <div className="list-sort-demo-wrapper">
        <div className="list-sort-demo">
          <ListSort
            dragClassName="list-drag-selected"
            onListUpdate={onListUpdate}
            appearAnim={{ animConfig: { marginTop: [5, 30], opacity: [1, 0] } }}
          >
            {childrenToRender}
          </ListSort>
        </div>
      </div>
    </>
  )
}
