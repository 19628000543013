import { useEffect } from 'react'
import { IResourceComponentsProps, useGetIdentity, useTranslate } from '@refinedev/core'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, InputNumber, Select } from 'antd'
import { nanoid } from 'nanoid'

import { ICurrentUser, IMerchant, IOperation, IPaymentRequisite } from 'interfaces'
import { formattedCardNumber } from 'utils'

const { TextArea } = Input

export const OperationCreate: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity<ICurrentUser>()
  const t = useTranslate()
  const { form, formProps, saveButtonProps } = useForm<IOperation>()
  const merchant_id = Form.useWatch('merchant_id', form)
  const payment_requisite_id = Form.useWatch('payment_requisite_id', form)

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  const { queryResult: paymentRequisitesQueryResult } = useSelect<IPaymentRequisite>({
    resource: 'payment_requisites',
    optionLabel: 'card_number',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
      {
        field: 'status',
        operator: 'eq',
        value: ['active', 'blocked', 'suspended', 'overflowed'],
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
    queryOptions: {
      enabled: merchant_id !== undefined,
    },
  })

  const paymentRequisiteOptions = paymentRequisitesQueryResult.data?.data.map((item) => {
    const gatewaysNames = item.gateways.map((gateway) => gateway.name).join(', ')
    const status = item.status !== 'active' ? ` (${item.status}) ` : ''
    return {
      label: `${formattedCardNumber(item.card_number)} (${gatewaysNames})${status}`,
      value: item.id,
    }
  })

  useEffect(() => {
    const selectedPaymentRequisite = paymentRequisitesQueryResult.data?.data.find(
      (item) => item.id === payment_requisite_id
    )
    form.setFieldsValue({ gateway_id: selectedPaymentRequisite?.gateways_ids[0] })
  }, [payment_requisite_id, form, paymentRequisitesQueryResult])

  useEffect(() => {
    user && form.setFieldsValue({ client_id: user.email })
  }, [user, form])

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item name="client_id" initialValue={user?.email} hidden>
          <Input />
        </Form.Item>
        <Form.Item name="manual" initialValue={true} hidden>
          <Input />
        </Form.Item>
        <Form.Item name="idempotency_key" initialValue={`manual_${nanoid(4)}`} hidden>
          <Input />
        </Form.Item>
        <Form.Item name="gateway_id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="kind" initialValue={'income'} hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('operations.form.merchant.label')}
          name="merchant_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...merchantsSelectProps}
            allowClear
            showSearch
            placeholder={t('operations.form.merchant.placeholder')}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={t('operations.form.payment_requisite.label')}
          name="payment_requisite_id"
          dependencies={['merchant_id']}
          shouldUpdate
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={merchant_id === undefined}
            options={paymentRequisiteOptions}
            allowClear
            showSearch
            placeholder={t('operations.form.payment_requisite.placeholder')}
            filterOption={(input, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={t('operations.form.amount.label')}
          name="amount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={1} prefix="₽" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label={t('operations.fields.comment')} name="comment">
          <TextArea rows={4} allowClear />
        </Form.Item>
      </Form>
    </Create>
  )
}
