import React from 'react'
import { useTable } from '@refinedev/antd'
import { IAgreement } from 'interfaces'
import AgreementsTable from '../agreements/list_table'
import AddAgreementButton from 'components/AddAgreementButton'

export const AgreementsList: React.FC<{ parntnerId: string }> = ({ parntnerId }) => {
  const { tableProps } = useTable<IAgreement>({
    resource: 'billing/agreements',
    filters: {
      permanent: [
        {
          field: 'partner_id',
          operator: 'eq',
          value: parntnerId,
        },
      ],
    },
  })

  return (
    <>
      <AddAgreementButton initialValues={{ type: 'partner', partner_id: parntnerId }} style={{ marginBottom: 16 }} />
      <AgreementsTable tableProps={tableProps} hideMerchant={true} hideAgent={true} />      
    </>
  )
}
