import dayjs from 'dayjs'
import { DateField as AntdDateField } from '@refinedev/antd'
import { Typography } from 'antd'

const { Text } = Typography

const DateField: React.FC<{
  value: string | null
  format?: string
  ellipsis?: boolean
  style?: React.CSSProperties
}> = ({ value, format = 'DD.MM.YYYY, HH:mm', ellipsis, style = {} }) => {
  if (!value) {
    return <Text style={Object.assign({ verticalAlign: 'bottom' }, style)}>…</Text>
  }

  const dayjsValue = dayjs.utc(value).tz()
  let offset
  if (dayjsValue.utcOffset() === 180) {
    offset = 'МСК'
  } else if (dayjsValue.utcOffset() === 0) {
    offset = 'UTC'
  } else {
    offset = `${dayjsValue.utcOffset() > 0 ? '+' : '–'}${dayjsValue.utcOffset() / 60}`
  }

  format = `${format} (${offset})`

  return (
    <AntdDateField
      value={dayjsValue}
      locales={dayjs.locale()}
      format={format}
      ellipsis
      style={style}
    />
  )
}

export default DateField
