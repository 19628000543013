import {
  IResourceComponentsProps,
  useGetLocale,
  useSetLocale,
  useTranslate,
} from '@refinedev/core'
import { useForm, Edit } from '@refinedev/antd'
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Select,
  Space,
  Switch,
  Tabs,
  TabsProps,
  Typography,
} from 'antd'
import { IUser } from 'interfaces'
import { ColorModeContext } from 'contexts/color-mode'
import { useContext, useEffect, useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { timeZoneOptions } from 'utils'

import './styles.css'
import { Security } from './security'
import { useSearchParams } from 'react-router-dom'

export const ProfileShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { mode, setMode } = useContext(ColorModeContext)
  const { i18n } = useTranslation()
  const locale = useGetLocale()
  const changeLanguage = useSetLocale()
  const currentLocale = locale()
  const [currentTab, setCurrentTab] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (currentTab === '') return

    setSearchParams((prevValue) => {
      prevValue.delete('tab')

      const newParams: { [key: string]: string } = {}

      newParams['tab'] = currentTab

      return newParams
    })
  }, [setSearchParams, currentTab])

  const menuItems: MenuProps['items'] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label: 'RU',
    }))

  const { formProps, saveButtonProps } = useForm<IUser>({
    action: 'edit',
    resource: 'users',
    id: 'current',
    redirect: false,
  })

  const onTabChange = (key: string) => {
    setCurrentTab(key)
  }

  let tabsItems: TabsProps['items'] = []

  tabsItems.push({
    key: '1',
    label: t('profile.titles.common'),
    children: (
      <Edit
        saveButtonProps={saveButtonProps}
        headerButtons={<></>}
        title={<></>}
        wrapperProps={{ className: 'sexy' }}
      >
        <Form layout="vertical" {...formProps}>
          <Form.Item name="email" label={t('profile.fields.email')}>
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('profile.fields.password')}
            help={t('hints.password')}
          >
            <Input />
          </Form.Item>
          <Form.Item name="timezone" label={t('profile.fields.timezone')}>
            <Select
              showSearch
              //@ts-ignore
              options={timeZoneOptions}
            />
          </Form.Item>
          <Form.Item
            name="theme"
            label={t('profile.fields.theme')}
            style={{ marginTop: '48px' }}
          >
            <Switch
              checkedChildren="🌛"
              unCheckedChildren="🔆"
              onChange={() => setMode(mode === 'light' ? 'dark' : 'light')}
              defaultChecked={mode === 'light'}
            />
          </Form.Item>
          <Form.Item name="lang" label={t('profile.fields.lang')}>
            <Dropdown
              menu={{
                items: menuItems,
                selectedKeys: currentLocale ? [currentLocale] : [],
              }}
            >
              <Button type="text">
                <Space>
                  <Avatar size={16} src={`/images/flags/ru.svg`} />
                  {'RU'}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Form.Item>
        </Form>
      </Edit>
    ),
  })
  tabsItems.push({
    key: '2',
    label: t('profile.titles.security'),
    children: <Security />,
  })

  return (
    <>
      <Typography.Title level={3}>{t('profile.titles.edit')}</Typography.Title>
      <Tabs
        defaultActiveKey={searchParams.get('tab') || '1'}
        items={tabsItems}
        onChange={onTabChange}
      />
    </>
  )
}
