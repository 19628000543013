import React, { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { IShift } from 'interfaces'
import { Controls } from './controls'
import { PayoutControl } from './payout_control'
import { PayinControl } from './payin_control'
import { Stats } from './stats'
import { Timer } from './timer'

export const Current: React.FC<{ shift: IShift }> = ({ shift }) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const timer = setInterval(() => {
      shift &&
        shift.status === 'started' &&
        queryClient.invalidateQueries(['shifts', 'current'])
    }, 30000)
    return () => clearInterval(timer)
  }, [shift, queryClient])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Stats shift={shift} />
      <div>
        <Timer shift={shift} />
        <Controls shift={shift} />
        <PayinControl shift={shift} style={{ marginRight: 8 }} />
        <PayoutControl shift={shift} />
      </div>
    </div>
  )
}
