import React from 'react'
import { Show, useTable } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { ICard } from 'interfaces'

import CardsTable from 'pages/cards/list_table'

export const CardsList: React.FC<any> = ({ merchantId, scoringClientId }) => {
  const t = useTranslate()
  const { tableProps } = useTable<ICard>({
    resource: 'payment_requisites',

    filters: {
      permanent: [
        {
          field: 'merchant_id',
          operator: 'eq',
          value: merchantId,
        },
        {
          field: 'scoring_client_id',
          operator: 'eq',
          value: scoringClientId,
        },
      ]
    }
  })

  return (
    <Show
      title={`${t('scoring/clients.titles.cards')} (${
        tableProps.pagination && tableProps.pagination.total
      })`}
      headerButtons={<></>}
      canEdit={false}
      goBack={false}
      breadcrumb={false}
      wrapperProps={{ style: { marginTop: 20 } }}
    >
      <CardsTable tableProps={tableProps} />
    </Show>
  )
}
