import {
  IResourceComponentsProps,
  useTranslate,
  useMany,
  GetManyResponse,
  useCan,
  CrudFilters,
  getDefaultFilter,
} from '@refinedev/core'

import { EditButton, List, ShowButton, useTable, useSelect } from '@refinedev/antd'

import DateField from 'components/DateField'

import { Table, Space, Typography, Skeleton, Form, Input, Select } from 'antd'
import { ITeam, ITeamDeposit } from 'interfaces'

import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'
import {
  formattedCorrectedPercent,
  formattedCurrencyAmount,
  formattedPercent,
} from 'utils'
import { SearchOutlined } from '@ant-design/icons'

const { Text } = Typography

export const TeamDepositsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, filters } = useTable<ITeamDeposit>({
    onSearch: (params: any) => {
      const { teams_ids } = params
      const filters: CrudFilters = []

      filters.push({
        field: 'teams_ids',
        operator: 'in',
        value: teams_ids?.length > 0 ? teams_ids : undefined,
      })

      return filters
    },
    pagination: {
      pageSize: 20,
    },
  })


  const { data: canCreateTeamDeposit } = useCan({
    resource: 'team_deposits',
    action: 'create',
  })


  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  const { selectProps: teamsSelectProps } = useSelect<ITeam>({
    resource: 'teams',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    <List canCreate={canCreateTeamDeposit?.can}>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          teams_ids: getDefaultFilter('teams_ids', filters, 'in'),
        }}
      >
        <Form.Item
          name="teams_ids"
          style={{ marginBottom: 0, display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Select
            style={{ minWidth: 200 }}
            {...teamsSelectProps}
            onSearch={() => {}}
            allowClear
            placeholder={t('team_deposits.filter.search.placeholder')}
            mode="multiple"
            filterOption={(input, option: any) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </Form.Item>
      </Form>
      <Table
        scroll={{ x: '100%' }}
        {...tableProps}
        rowKey="id"
        pagination={
          tableProps.pagination && (tableProps.pagination?.total ?? 0) > 20
            ? {
                ...tableProps.pagination,
                pageSize: 20,
                position: ['bottomLeft'],
                size: 'small',
              }
            : false
        }
      >
        <Table.Column<ITeamDeposit>
          ellipsis
          dataIndex="id"
          key="id"
          title={t('team_deposits.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column<ITeamDeposit>
          dataIndex="amount"
          key="amount"
          title={t('team_deposits.fields.amount')}
        />
        <Table.Column<ITeamDeposit>
          ellipsis
          dataIndex="kind"
          key="kind"
          title={t('team_deposits.fields.kind')}
          render={(value) => (value == 'outcome' ? t('team_deposits.filter.kind.outcome') : (value == 'income' ? t('team_deposits.filter.kind.income') : '') )}
        />
        <Table.Column<ITeamDeposit>
          ellipsis
          dataIndex={['team', 'name']}
          key="team"
          title={t('team_deposits.fields.team')}
        />
        <Table.Column<ITeamDeposit>
          dataIndex="comment"
          key="comment"
          title={t('team_deposits.fields.comment')}
        />
        <Table.Column
          dataIndex="created_at"
          ellipsis={true}
          width={10}
          key="created_at"
          title={t('team_deposits.fields.created_at')}
          render={(value) => <DateField value={value} />}
        />
      </Table>
    </List>
  )
}
