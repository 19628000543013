import { FileImageOutlined } from '@ant-design/icons'
import { TOKEN_KEY } from '../../constants'
import { Button, Space } from 'antd'

import { useApiUrl } from '@refinedev/core'

const downloadFile = (blob: any, disposition: any) => {
  let filename = 'unknown'

  if (disposition) {
    try {
      filename = disposition.split('filename=')[1].split(';')[0].replaceAll('"', '')
    } catch (e) {
      console.log(e)
    }
  }

  let a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = filename
  a.click()
}

export const OperationAttachments: React.FC<{
  attachments_ids: string[]
  title?: string | undefined
}> = ({ attachments_ids, title = undefined }) => {
  const apiUrl = useApiUrl()

  const download = async (id: string) => {
    const req = fetch(`${apiUrl}/attachments/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    })

    const response = await req
    const blob = await response.blob()
    const disposition = response.headers.get('Content-Disposition')
    downloadFile(blob, disposition)
  }

  return (
    <Space direction="horizontal">
      {attachments_ids.map((id) => (
        <a href={`${apiUrl}/attachments/${id}`} target="_blank" rel="noreferrer">
          <FileImageOutlined />
        </a>
        // <Button
        //   icon={<FileImageOutlined />}
        //   size="small"
        //   onClick={() => {
        //     download(id)
        //   }}
        // >
        //   {title}
        // </Button>
      ))}
    </Space>
  )
}
