import React from 'react'
import { IResourceComponentsProps, useShow, useTranslate } from '@refinedev/core'
import { Show } from '@refinedev/antd'
import { Descriptions, Tabs, TabsProps } from 'antd'

import { IAgent } from 'interfaces'
import { AgreementsList } from './agreements_list'

export const AgentShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IAgent>()
  const { data, isLoading } = queryResult
  const record = data?.data

  let tabsItems: TabsProps['items'] = []
  tabsItems.push({
    key: '1',
    label: t('agents.titles.agreements'),
    children: record && !isLoading ? <AgreementsList agentId={record.id} /> : null,
  })

  return (
    <>
      <Show isLoading={isLoading} title={record?.name || ''} headerButtons={<></>}>
        <Descriptions column={1}>
          <Descriptions.Item label={t('agents.fields.name')}>
            {record?.name}
          </Descriptions.Item>
        </Descriptions>
      </Show>
      <Tabs defaultActiveKey="1" items={tabsItems} />
    </>
  )
}
