import React, { useState } from 'react'
import { BorderOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip } from 'antd'
import { useTranslate } from '@refinedev/core'
import { IShift } from 'interfaces'
import { FinishShift } from './finish'

const FinishButton: React.FC<{ shift: IShift }> = ({ shift }) => {
  const t = useTranslate()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Tooltip title={t('shifts.titles.finish')}>
        <Button
          icon={<BorderOutlined />}
          onClick={showModal}
          size="large"
          danger
          type="link"
        />
      </Tooltip>
      <Modal
        destroyOnClose
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        title={t('shifts.titles.finish')}
      >
        <FinishShift id={shift.id} onFinish={closeModal} />
      </Modal>
    </>
  )
}

export const Controls: React.FC<{ shift: IShift }> = ({ shift }) => {
  return <FinishButton shift={shift} />
}
