import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { Card, Form, DatePicker, FormProps, Table, Typography } from 'antd'
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { IBillingDashboard } from 'interfaces'
import { useForm } from '@refinedev/antd'
import { useSearchParams } from 'react-router-dom'
import { formattedCurrencyAmount, formattedPercent } from 'utils'

const { RangePicker } = DatePicker

const FilterForm: React.FC<{
  formProps: FormProps
  period: any[]
}> = ({ formProps, period }) => {
  const t = useTranslate()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setSearchParams((prevValue) => {
      prevValue.delete('start')
      prevValue.delete('end')
      const newParams: { [key: string]: string } = {}

      period && period[0] && (newParams['start'] = period[0].format('YYYY-MM-DD'))
      period && period[1] && (newParams['end'] = period[1].format('YYYY-MM-DD'))

      return newParams
    })
  }, [setSearchParams, period])

  return (
    <Form
      {...formProps}
      layout="inline"
      // @ts-ignore
      initialValues={{
        period: [
          searchParams.get('start') ? dayjs(searchParams.get('start')) : dayjs(),
          searchParams.get('end') ? dayjs(searchParams.get('end')) : dayjs(),
        ],
        merchant_id: searchParams.get('merchant_id'),
        partner_id: searchParams.get('partner_id'),
      }}
    >
      <Form.Item style={{ marginBottom: 0 }} name="period">
        <RangePicker
          presets={[
            {
              label: t('dashboard.form.period.presets.today.label'),
              value: [dayjs(), dayjs()],
            },
            {
              label: t('dashboard.form.period.presets.week.label'),
              value: [dayjs().add(-1, 'week'), dayjs()],
            },
            {
              label: t('dashboard.form.period.presets.month.label'),
              value: [dayjs().add(-1, 'month'), dayjs()],
            },
          ]}
          allowEmpty={[false, false]}
          locale={locale}
        />
      </Form.Item>
    </Form>
  )
}

export const BillingPage: React.FC = () => {
  const apiUrl = useApiUrl()

  const { form, formProps } = useForm({ warnWhenUnsavedChanges: false })
  const period = Form.useWatch('period', form)

  const { data, isLoading } = useCustom<IBillingDashboard[]>({
    url: `${apiUrl}/billing/dashboard`,
    method: 'get',
    queryOptions: {
      enabled: !!(period && period[0] && period[1]),
    },
    config: {
      query: {
        start: period && period[0]?.format('YYYY-MM-DD'),
        end: period && period[1]?.format('YYYY-MM-DD'),
      },
    },
  })

  const dataSource = data?.data
  const payoutsDataSource = data?.data
  // const dataSource = [
  //   {
  //     merchant: { id: 'b1758796-0077-4948-8827-5f506276de49', name: 'Space@enovate' },
  //     volume: '1162395.0',
  //     profit: '15111.135',
  //     revenue: '46495.8',
  //     cost: '31384.665',
  //     agents: {
  //       'af658349-00d0-445d-960b-ccd593a632bf': {
  //         name: 'Агент Т',
  //         id: 'af658349-00d0-445d-960b-ccd593a632bf',
  //         cost: 0,
  //       },
  //       '471180fa-100f-46bd-b4a7-db2b07430939': {
  //         name: 'Агент А',
  //         id: '471180fa-100f-46bd-b4a7-db2b07430939',
  //         cost: 0,
  //       },
  //     },
  //     'af658349-00d0-445d-960b-ccd593a632bf': { cost: '5811.975' },
  //     '471180fa-100f-46bd-b4a7-db2b07430939': { cost: '1162.395' },
  //     partners: {
  //       '756b6a8c-eb46-4941-9031-7d4463954834': {
  //         name: '2Offcomp',
  //         id: '756b6a8c-eb46-4941-9031-7d4463954834',
  //         cost: 0,
  //       },
  //       '575ade83-717c-44e6-8d3b-237ded8cc723': {
  //         name: '3Offcomp',
  //         id: '575ade83-717c-44e6-8d3b-237ded8cc723',
  //         cost: 0,
  //       },
  //     },
  //     '756b6a8c-eb46-4941-9031-7d4463954834': { cost: '4161.801' },
  //     '575ade83-717c-44e6-8d3b-237ded8cc723': { cost: '20248.494' },
  //   },
  //   {
  //     merchant: { id: 'c840812a-39cf-4ba1-8d40-37225f3111d3', name: 'zeonleon' },
  //     volume: '1277551.14',
  //     profit: '10220.4086',
  //     revenue: '44714.2903',
  //     cost: '34493.8817',
  //     agents: {
  //       '471180fa-100f-46bd-b4a7-db2b07430939': {
  //         name: 'Агент А',
  //         id: '471180fa-100f-46bd-b4a7-db2b07430939',
  //         cost: 0,
  //       },
  //       '701d108c-754b-40df-ab69-26ab154401ae': {
  //         name: 'Reel',
  //         id: '701d108c-754b-40df-ab69-26ab154401ae',
  //         cost: 0,
  //       },
  //     },
  //     '471180fa-100f-46bd-b4a7-db2b07430939': { cost: '1277.5514' },
  //     partners: {
  //       '251ce35b-33a0-4bef-baff-8e35fc631a26': {
  //         name: '4Offcomp',
  //         id: '251ce35b-33a0-4bef-baff-8e35fc631a26',
  //         cost: 0,
  //       },
  //       '756b6a8c-eb46-4941-9031-7d4463954834': {
  //         name: '2Offcomp',
  //         id: '756b6a8c-eb46-4941-9031-7d4463954834',
  //         cost: 0,
  //       },
  //     },
  //     '251ce35b-33a0-4bef-baff-8e35fc631a26': { cost: '24214.1134' },
  //     '701d108c-754b-40df-ab69-26ab154401ae': { cost: '6387.7561' },
  //     '756b6a8c-eb46-4941-9031-7d4463954834': { cost: '2614.4608' },
  //   },
  //   {
  //     merchant: { id: '26e60d5e-78eb-49bf-ad19-8c14f5aaa0ee', name: 'BR' },
  //     volume: '165995.0',
  //     profit: '3153.905',
  //     revenue: '6639.8',
  //     cost: '3485.895',
  //     partners: {
  //       '756b6a8c-eb46-4941-9031-7d4463954834': {
  //         name: '2Offcomp',
  //         id: '756b6a8c-eb46-4941-9031-7d4463954834',
  //         cost: 0,
  //       },
  //     },
  //     '756b6a8c-eb46-4941-9031-7d4463954834': { cost: '3485.895' },
  //   },
  // ]

  let columns: any[] = []
  columns.push({
    title: 'Merchant',
    dataIndex: ['merchant', 'name'],
    key: 'merchant',
  })

  columns.push({
    title: 'Пополнения',
    dataIndex: 'volume',
    key: 'volume',
    align: 'right',
    render: (value: string) => (
      <Typography.Text ellipsis>{formattedCurrencyAmount(value, 0)}</Typography.Text>
    ),
  })

  columns.push({
    title: 'Выручка',
    dataIndex: 'revenue',
    key: 'revenue',
    align: 'right',
    render: (value: string) => (
      <Typography.Text ellipsis>{formattedCurrencyAmount(value, 0)}</Typography.Text>
    ),
  })

  columns.push({
    title: 'Профит',
    dataIndex: 'profit',
    key: 'profit',
    align: 'right',
    render: (value: string) => (
      <Typography.Text ellipsis>{formattedCurrencyAmount(value, 0)}</Typography.Text>
    ),
  })

  columns.push({
    title: '🦭 Моржа',
    dataIndex: 'margin',
    key: 'margin',
    align: 'right',
    render: (value: string, record: IBillingDashboard) =>
      Number(record.revenue) > 0
        ? formattedPercent((Number(record.profit) / Number(record.revenue)).toFixed(2))
        : '–',
  })

  columns.push({
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
    align: 'right',
    render: (value: string) => formattedCurrencyAmount(value, 0),
  })

  dataSource?.forEach((item) => {
    item.agents &&
      Object.keys(item.agents).forEach((agentId) => {
        if (!columns.find((item: any) => item.key === agentId)) {
          columns.push({
            key: agentId,
            dataIndex: [agentId, 'cost'],
            // @ts-ignore
            title: `Агент ${item.agents[agentId].name}`,
            align: 'right',
            render: (value: string) => formattedCurrencyAmount(value, 0),
          })
        }
      })
  })

  dataSource?.forEach((item) => {
    item.partners &&
      Object.keys(item.partners).forEach((partnerId) => {
        if (!columns.find((item: any) => item.key === partnerId)) {
          columns.push({
            key: partnerId,
            dataIndex: [partnerId, 'cost'],
            // @ts-ignore
            title: `Партнёр ${item.partners[partnerId].name}`,
            align: 'right',
            render: (value: string) => formattedCurrencyAmount(value, 0),
          })
        }
      })
  })

  let payoutsColumns: any[] = []
  payoutsColumns.push({
    title: 'Merchant',
    dataIndex: ['merchant', 'name'],
    key: 'merchant',
  })

  payoutsColumns.push({
    title: 'Пейауты',
    dataIndex: ['payouts', 'volume'],
    key: 'volume',
    align: 'right',
    render: (value: string) => (
      <Typography.Text ellipsis>{formattedCurrencyAmount(value, 0)}</Typography.Text>
    ),
  })

  payoutsColumns.push({
    title: 'Выручка',
    dataIndex: ['payouts', 'revenue'],
    key: 'revenue',
    align: 'right',
    render: (value: string) => (
      <Typography.Text ellipsis>{formattedCurrencyAmount(value, 0)}</Typography.Text>
    ),
  })

  return (
    <>
      <Card>
        <FilterForm formProps={formProps} period={period} />
      </Card>
      <Table
        pagination={false}
        loading={isLoading}
        style={{ marginTop: 16 }}
        scroll={{ x: '100%' }}
        columns={columns}
        dataSource={dataSource}
        summary={(pageData) => {
          let totalVolume = 0
          let totalRevenue = 0
          let totalProfit = 0

          pageData.forEach(({ profit, volume, revenue }) => {
            totalVolume += Number(volume)
            totalProfit += Number(profit)
            totalRevenue += Number(revenue)
          })

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Typography.Text strong>Total</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align={'right'}>
                  <Typography.Text strong ellipsis>
                    {formattedCurrencyAmount(totalVolume, 0)}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align={'right'}>
                  <Typography.Text strong ellipsis>
                    {formattedCurrencyAmount(totalRevenue, 0)}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align={'right'}>
                  <Typography.Text strong ellipsis>
                    {formattedCurrencyAmount(totalProfit, 0)}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align={'right'}>
                  <Typography.Text strong ellipsis>
                    {Number(totalRevenue) > 0
                      ? formattedPercent(
                          (Number(totalProfit) / Number(totalRevenue)).toFixed(2)
                        )
                      : '–'}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
      <Table
        pagination={false}
        loading={isLoading}
        style={{ marginTop: 16 }}
        scroll={{ x: '100%' }}
        columns={payoutsColumns}
        dataSource={payoutsDataSource}
        summary={(pageData) => {
          let totalVolume = 0
          let totalRevenue = 0

          pageData.forEach(({ payouts }) => {
            totalVolume += Number(payouts.volume)
            totalRevenue += Number(payouts.revenue)
          })

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Typography.Text strong>Total</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align={'right'}>
                  <Typography.Text strong ellipsis>
                    {formattedCurrencyAmount(totalVolume, 0)}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align={'right'}>
                  <Typography.Text strong ellipsis>
                    {formattedCurrencyAmount(totalRevenue, 0)}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
    </>
  )
}
