import React, { useState } from 'react'
import { useModalForm } from '@refinedev/antd'
import { useCan, useTranslate } from '@refinedev/core'
import { Button } from 'antd'
import ExportModal from './partners_export_modal'
import { DownloadOutlined } from '@ant-design/icons'

interface IDashboardPartnersExport {
  id: string
}

const ExportButton: React.FC<any> = ({ style }) => {
  const t = useTranslate()
  const [ready, setReady] = useState(false)

  const { data: canCreateExport } = useCan({
    resource: 'exports',
    action: 'create',
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: showModal,
    close: closeModal,
  } = useModalForm<IDashboardPartnersExport>({
    resource: 'dashboard/partners/export',
    action: 'create',
    redirect: false,
    autoSubmitClose: false,
    onMutationSuccess: () => {
      setReady(true)
    },
    warnWhenUnsavedChanges: false,
  })

  return canCreateExport?.can ? (
    <>
      <Button
        style={style}
        type="primary"
        onClick={() => showModal()}
        icon={<DownloadOutlined />}
      >
        {t('partners_dashboard.titles.export')}
      </Button>
      <ExportModal
        ready={ready}
        createModalProps={createModalProps}
        createFormProps={createFormProps}
        onCancel={() => {
          closeModal()
          setTimeout(() => setReady(false), 500)
        }}
      />
    </>
  ) : null
}

export default ExportButton