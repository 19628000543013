import React, { useState } from 'react'
import { useCustomMutation, useApiUrl, useInvalidate, useTranslate } from "@refinedev/core"
import { IOperationAction, GenericResponse, IOperationClientActionCode } from 'interfaces'
import { Row, Button, ButtonProps } from "antd"

interface ActionButtonProps {
  operationId: string
  action: IOperationClientActionCode
}

const ActionButton: React.FC<ButtonProps & ActionButtonProps> = ({
  operationId,
  action,
  children,
  ...props
}) => {
  const apiUrl = useApiUrl()
  const invalidate = useInvalidate()
  const { mutate } = useCustomMutation<GenericResponse>()
  const [isLoading, setIsLoading] = useState(false)

  const onClick = () => {
    setIsLoading(true)

    mutate(
      {
        url: `${apiUrl}/cashins/${operationId}/${action}`,
        method: 'post',
        values: {},
      },
      {
        onError: (error, variables, context) => {
          setIsLoading(false)
        },
        onSuccess: (data, variables, context) => {
          setIsLoading(false)
          invalidate({
            resource: 'cashins',
            invalidates: ['detail'],
            id: operationId,
          })
        },
      }
    )
  }

  return (
    <Button {...props} onClick={onClick} loading={isLoading}>
      {children}
    </Button>
  )
}

interface ActionButtonsProps {
  id: string
  actions: IOperationAction[]
}

export const ActionsButtons: React.FC<ActionButtonsProps> = ({ id, actions }) => {
  const t = useTranslate()
  const buttons = actions.map(({ code, url }) => {
    let button = <></>

    switch (code) {
      case 'pay':
        button = (
          <ActionButton operationId={id} action={'pay'} block type="primary">
            {t('components.operation_actions.buttons.confirm')}
          </ActionButton>
        )
        break
      case 'cancel':
        button = (
          <ActionButton
            operationId={id}
            action={'cancel'}
            block
            danger
            style={{ marginTop: 10 }}
          >
            {t('components.operation_actions.buttons.cancel')}
          </ActionButton>
        )
        break
      case 'back':
        button = (
          <Button type="link" block style={{ marginTop: 20 }} href={url}>
            Вернуться в магазин
          </Button>
        )
        break
    }

    return button
  })

  return (
    <Row align="stretch" justify="space-around">
      {buttons}
    </Row>
  )
}
