import { useInvalidate, useTranslate } from '@refinedev/core'
import { useSelect, useModalForm } from '@refinedev/antd'
import { Select, Button, Modal, Form, Input, InputNumber } from 'antd'
import { nanoid } from 'nanoid'
import { IGateway, IMerchant, IOperation } from 'interfaces'

const CreateOutcomeOperationButton: React.FC<{ merchant: IMerchant }> = ({
  merchant,
}) => {
  const t = useTranslate()
  const invalidate = useInvalidate()

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: showModal,
  } = useModalForm<IOperation>({
    resource: 'operations',
    action: 'create',
    redirect: false,
    onMutationSuccess: () => {
      invalidate({
        resource: 'operations',
        invalidates: ['list'],
      })
    },
  })

  const { selectProps: gatewaySelectProps } = useSelect<IGateway>({
    resource: 'gateways',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'payment_type',
        operator: 'eq',
        value: 'outcome',
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    <>
      <Button
        disabled={Number(merchant.balance) <= 0}
        type="link"
        onClick={() => {
          showModal()
        }}
      >
        {t('merchants.titles.create_outcome_operation')}
      </Button>
      <Modal
        {...createModalProps}
        title={t('merchants.titles.create_outcome_operation')}
        width={400}
      >
        <Form {...createFormProps} layout="vertical">
          <Form.Item name="idempotency_key" initialValue={`sp_${nanoid(4)}`} hidden>
            <Input />
          </Form.Item>
          <Form.Item name="kind" initialValue={'outcome'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name="merchant_id" initialValue={merchant.id} hidden>
            <Input />
          </Form.Item>
          <Form.Item label={t('operations.form.gateway.label')} name="gateway_id">
            <Select
              {...gatewaySelectProps}
              allowClear
              showSearch
              placeholder={t('operations.form.gateway.placeholder')}
              filterOption={(input, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item label={t('operations.form.amount.label')} name="amount">
            <InputNumber min={1} prefix="₽" style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateOutcomeOperationButton
