import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

import { ITeam } from 'interfaces'
import { currencyOptions } from 'utils'

export const TeamCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<ITeam>()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t('teams.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="name" />
        </Form.Item>
        <Form.Item
          label={t('partners.fields.currency')}
          name="currency"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={currencyOptions} />
        </Form.Item>
        <Form.Item label={t('teams.fields.telegram_chat_id')} name="telegram_chat_id">
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
